import { Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  BusinessCenter as BuildingIcon,
  Receipt as ReceiptIcon,
  SimCard as SimCardIcon,
  DeviceHub as Device,
  Dashboard as DashboardIcon
} from '@mui/icons-material';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Link, useLocation, useParams } from 'react-router-dom';
import { isAclUserThePayer } from 'features/SimBilling/Utils/SimBillingUtils';
import SideBar from '../layouts/SideBar';
import { useAclAuth, useReduxAclUser, useReduxSites } from 'features/SimBilling/Hooks';

const SimBillingSideBar = (props: any) => {
  const theme = useTheme();
  const location = useLocation();
  const { publicId } = useParams();
  const { aclToken } = useAclAuth();
  const { user } = useReduxAclUser();
  const { selectedSite } = useReduxSites();

  if (aclToken == null) {
    return <SideBar />;
  } else {
    return (
      <Sidebar
        style={styles.sidebar}
        backgroundColor={theme.palette.neutral.light}
        collapsed={props.collapsed}
        toggled={props.toggled}
        collapsedWidth={useMediaQuery(() => theme.breakpoints.up('sm')) ? '80px' : '0px'}
      >
        <Menu
          menuItemStyles={{
            button: ({ active }) => {
              return {
                backgroundColor: active ? '#c9e7ff' : undefined,
                color: active ? '#0071CE' : undefined,
                '&:hover': {
                  backgroundColor: active ? '#c9e7ff' : '#e2f2ff'
                }
              };
            }
          }}
        >
          <MenuItem
            active={location.pathname === `/simbilling/sites/${publicId}/site`}
            component={<Link to={`/simbilling/sites/${publicId}/site`} />}
            icon={<BuildingIcon />}
          >
            <Typography variant="body2">Site Information</Typography>
          </MenuItem>
          <MenuItem
            active={location.pathname === `/simbilling/sites/${publicId}/gwdevices`}
            component={<Link to={`/simbilling/sites/${publicId}/gwdevices`} />}
            icon={<Device />}
          >
            <Typography variant="body2">GW Devices</Typography>
          </MenuItem>
          {user && selectedSite && isAclUserThePayer(user, selectedSite) && (
            <MenuItem
              active={location.pathname === `/simbilling/sites/${publicId}/billing`}
              component={<Link to={`/simbilling/sites/${publicId}/billing`} />}
              icon={<ReceiptIcon />}
            >
              <Typography variant="body2">Billing</Typography>
            </MenuItem>
          )}
          <MenuItem
            active={location.pathname === `/simbilling`}
            component={<Link to={`/simbilling`} />}
            icon={<BuildingIcon />}
          >
            <Typography variant="body2">All Sites</Typography>
          </MenuItem>
        </Menu>
        <Menu
          menuItemStyles={{
            button: ({ active }) => {
              return {
                backgroundColor: active ? theme.palette.neutral.medium : undefined
              };
            }
          }}
          rootStyles={styles.secondMenu}
        >
          <Divider />
          <MenuItem active={location.pathname === '/'} component={<Link to="/" />} icon={<DashboardIcon />}>
            <Typography style={styles.overflowHidden} variant="body2">
              Home
            </Typography>
          </MenuItem>
          <MenuItem
            active={location.pathname === '/simbilling'}
            component={<Link to="/simbilling" />}
            icon={<SimCardIcon />}
          >
            <Typography variant="body2">SIM Billing</Typography>
          </MenuItem>
        </Menu>
      </Sidebar>
    );
  }
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  sidebar: {
    height: 'calc(100%-64px)',
    position: 'sticky',
    top: '0',
    left: '0',
    zIndex: 1
  },
  overflowHidden: {
    overflow: 'hidden'
  },
  secondMenu: {
    position: 'absolute',
    bottom: '0',
    width: '100%',
    '@media (max-height: 700px)': {
      position: 'relative',
      zIndex: 1
    }
  }
};

export default SimBillingSideBar;
