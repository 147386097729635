/**
 * Shared component used to make a tab.
 */
import React, { useState, ReactNode } from 'react';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { DeviceTabType, DeviceTabs } from 'features/RemoteManagement/DeviceDashboard/DeviceTabs';

/**@type {import('@mui/icons-material'.SxProps)} */
const TabStyles = {
  tabContainer: {
    position: 'relative',
    height: '100%'
  },
  tabLabelBox: {
    borderBottom: 1,
    borderColor: 'divider'
  },
  tabList: {
    '& button.Mui-selected': { color: '#003366' },
    '.MuiTabs-flexContainer': {
      justifyContent: 'space-evenly'
    }
  },
  tabIndicator: {
    backgroundColor: '#003366',
    height: '2px',
    borderRadius: '1px'
  },
  tabContent: {
    paddingBottom: '0px',
    height: 'calc(100% - 41px)'
  }
};

export class TabItem {
  label: ReactNode;
  content: ReactNode;

  constructor(labelItem: ReactNode, contentItem: ReactNode) {
    this.label = labelItem;
    this.content = contentItem;
  }
}

interface TabsContainerProps {
  tabTypes: DeviceTabType[];
}

const TabsContainer = (props: TabsContainerProps) => {
  const [value, setValue] = useState('1');

  // This gets the proper tabs to render based on the index
  const deviceTabs: DeviceTabs = new DeviceTabs();
  const tabs = deviceTabs.getTabs(props.tabTypes);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <Box sx={TabStyles.tabContainer}>
      <TabContext value={value}>
        <Box sx={TabStyles.tabLabelBox}>
          <TabList onChange={handleChange} sx={TabStyles.tabList} TabIndicatorProps={{ sx: TabStyles.tabIndicator }}>
            {tabs.map((newTab: TabItem, index: number) => (
              <Tab label={newTab.label} value={(index + 1).toString()} key={(index + 1).toString()} />
            ))}
          </TabList>
        </Box>
        {tabs.map((newTab: TabItem, index: number) => (
          <TabPanel value={(index + 1).toString()} key={(index + 1).toString()} sx={TabStyles.tabContent}>
            {newTab.content}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};

export default TabsContainer;
