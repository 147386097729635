/**
 * Lift Control has 2 tabs (IXG Support Tool, Relay Output)
 * user can navigate through each tab setting for each device
 */
import TabsContainer from 'shared/components/TabsContainer';
import { DeviceTabType } from 'features/RemoteManagement/DeviceDashboard/DeviceTabs';

const LiftControl = () => {
  const liftControlTabs: DeviceTabType[] = [
    // TODO: Find the real name for this tab and replace it with the proper named tab
    DeviceTabType.LiftControl_IXGSupportTool,
    DeviceTabType.LiftControl_RelayOutput
  ];

  return <TabsContainer tabTypes={liftControlTabs} />;
};

export default LiftControl;
