import { Box, MenuItem, Tooltip, Select, TextField, CircularProgress, Card, Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedDevice, updateSelectedDevice } from 'store/slices/devicesSlice';
import * as Yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useUpdateDeviceMutation, useHandleGatewayCommandMutation } from 'services/aiphoneCloud';
import containerStyle from 'shared/styles/advancedSettingContainerStyle';
import { RegexIpV4, RegexIpV6, RegexSubnetMask } from 'features/RemoteManagement/Types';
import stringUtils from 'shared/utils/StringUtils';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import { useEffect, useMemo, useState } from 'react';
import { EnumList, fetchEnumList } from 'shared/utils/EnumUtils';
import { fetchGatewayCommand } from 'shared/rmGateway/gwCommandProcessor';
import { gwCommand } from 'shared/rmGateway/gwCommand';
import { getSite } from 'store/slices/siteSlice';
import { RootState } from 'store';
import { getDeviceModelNumberFromModelType } from 'shared/utils/helperFunctions';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { RegexDomainName } from 'features/RemoteManagement/Types';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';

export const IPAddressLabel = () => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('AdvancedSettings_Tab_Tooltip_IpAddress')}>
      <span>{t('AdvancedSettings_Tab_IpAddress')}</span>
    </Tooltip>
  );
};

const IPAddress = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [updateDevice, { isLoading: isUpdating }] = useUpdateDeviceMutation();
  const selectedDevice = useSelector(getSelectedDevice);

  const site = useSelector(getSite);
  const sitePublicId = site?.siteInfo?.publicId;
  const gateway = useSelector(
    (state: RootState) => state.devices.DeviceList[site?.siteInfo?.registeredGatewayPublicId]
  );
  const deviceList = useSelector((state: RootState) => state.devices.DeviceList);

  const selectedDeviceBasicInfo = useMemo(
    () => deviceList[selectedDevice.publicId]?.basicInfo,
    [deviceList, selectedDevice.publicId]
  );
  const deviceModel = useMemo(
    () => getDeviceModelNumberFromModelType(selectedDeviceBasicInfo.deviceModel, selectedDeviceBasicInfo.deviceType),
    [selectedDeviceBasicInfo.deviceModel, selectedDeviceBasicInfo.deviceType]
  );

  // Check if the user has edit permission
  const { isAllowedTo } = usePermission();
  const hasEditPermission = isAllowedTo('site:edit', sitePublicId, PermissionsContextType.SITE);

  const isDeviceIxgTgw = useMemo(() => deviceModel === 'IXGW-TGW', [deviceModel]);

  const [handleGatewayCommand] = useHandleGatewayCommandMutation();
  const [enumList, setEnumList] = useState<EnumList>({ country: {}, state: {} });
  const [formikSchema, setFormikSchema] = useState<Yup.ObjectSchema<any> | null>(null);
  const [fetchingEnums, setFetchingEnums] = useState(true);

  const [needsAssociation, setNeedsAssociation] = useState(false);
  const [isAssociateDialogOpen, setIsAssociateDialogOpen] = useState(false);
  const [associateDialogTitle, setAssociateDialogTitle] = useState('');
  const [associateDialogContent, setAssociateDialogContent] = useState('');

  const handleClose = () => {
    setIsAssociateDialogOpen(false);
  };

  const buttonAssociate = t('Gateway_Associate');
  const associationTooltip = t('Association_TooltipDescription');
  const subnetMaskErrorInvalid = t('SubnetMask_Error_Invalid');

  const fieldErrorMaxLen = t('Field_Error_MaxLen');
  const hostNameTitle = t('AdvancedSettings_HostName_Title');
  const hostNameDesc = t('AdvancedSettings_HostName_Desc');
  const ipVersionTitle = t('AdvancedSettings_IpVersion_Title');
  const ipVersionDesc = t('AdvancedSettings_IpVersion_Desc');
  const ipAddressMethodTitle = t('AdvancedSettings_IpAddressMethod_Title');
  const ipAddressMethodDesc = t('AdvancedSettings_IpAddressMethod_Desc');
  const internetConnectionTitle = t('AdvancedSettings_InternetConnection_Title');
  const internetConnectionDesc = t('AdvancedSettings_InternetConnection_Desc');
  const subnetMaskTitle = t('AdvancedSettings_SubnetMask_Title');
  const subnetMaskDesc = t('AdvancedSettings_SubnetMask_Desc');
  const ipV4DefaultGatewayTitle = t('AdvancedSettings_IpV4DefaultGateway_Title');
  const ipV4DefaultGatewayDesc = t('AdvancedSettings_IpV4DefaultGateway_Desc');
  const ipV4PrimaryDnsTitle = t('AdvancedSettings_IpV4PrimaryDns_Title');
  const ipV4PrimaryDnsDesc = t('AdvancedSettings_IpV4PrimaryDns_Desc');
  const ipV4SecondaryDnsTitle = t('AdvancedSettings_IpV4SecondaryDns_Title');
  const ipV4SecondaryDnsDesc = t('AdvancedSettings_IpV4SecondaryDns_Desc');
  const ipV4AddressTitle = t('AdvancedSettings_IpV4Address_Title');
  const ipV4AddressDesc = t('AdvancedSettings_IpV4Address_Desc');
  const ipV4AddressErrorInvalid = t('IpV4Address_Error_Invalid');
  const ipV4MulticastAddressTitle = t('AdvancedSettings_IpV4MulticastAddress_Title');
  const ipV4MulticastAddressDesc = t('AdvancedSettings_IpV4MulticastAddress_Desc');
  const ipV6AddressErrorInvalid = t('IpV6Address_Error_Invalid');
  const ipV6AddressTitle = t('AdvancedSettings_IpV6Address_Title');
  const ipV6AddressDesc = t('AdvancedSettings_IpV6Address_Desc');
  const ipV6DefaultGatewayTitle = t('AdvancedSettings_IpV6DefaultGateway_Title');
  const ipV6DefaultGatewayDesc = t('AdvancedSettings_IpV6DefaultGateway_Desc');
  const ipV6PrimaryDnsTitle = t('AdvancedSettings_IpV6PrimaryDns_Title');
  const ipV6PrimaryDnsDesc = t('AdvancedSettings_IpV6PrimaryDns_Desc');
  const ipV6SecondaryDnsTitle = t('AdvancedSettings_IpV6SecondaryDns_Title');
  const ipV6SecondaryDnsDesc = t('AdvancedSettings_IpV6SecondaryDns_Desc');
  const ipV6MulticastAddressTitle = t('AdvancedSettings_IpV6MulticastAddress_Title');
  const ipV6MulticastAddressDesc = t('AdvancedSettings_IpV6MulticastAddress_Desc');
  const errorUpdateDevice = t('AdvancedSettings_Error_UpdateDevice');
  const unauthorizedUser = t('AdvancedSettings_Unauthorized_User');
  const successUpdateDevice = t('AdvancedSettings_Success_UpdateDevice');
  const buttonSaveChanges = t('Button_SaveChanges');
  const buttonReset = t('Button_Reset');
  const hostNameErrorInvalid = t('HostName_Error_Invalid');

  const formDevice = {
    networkSettings: {
      hostName: selectedDevice.networkSettings?.hostName,
      subnetMask: selectedDevice.networkSettings?.subnetMask,
      ipVersion: selectedDevice.networkSettings?.ipVersion,
      ipAddressMethod: selectedDevice.networkSettings?.ipAddressMethod,
      ipV4Address: selectedDevice.networkSettings?.ipV4Address,
      ipV4DefaultGateway: selectedDevice.networkSettings?.ipV4DefaultGateway,
      ipV6Address: selectedDevice.networkSettings?.ipV6Address,
      ipV6DefaultGateway: selectedDevice.networkSettings?.ipV6DefaultGateway,
      ipV4PrimaryDns: selectedDevice.networkSettings?.ipV4PrimaryDns,
      ipV4SecondaryDns: selectedDevice.networkSettings?.ipV4SecondaryDns,
      ipV6PrimaryDns: selectedDevice.networkSettings?.ipV6PrimaryDns,
      ipV6SecondaryDns: selectedDevice.networkSettings?.ipV6SecondaryDns,
      ipV4MulticastAddress: selectedDevice.networkSettings?.ipV4MulticastAddress,
      ipV6MulticastAddress: selectedDevice.networkSettings?.ipV6MulticastAddress
    },
    gatewaySettings: {
      communicationMethod: selectedDevice.gatewaySettings?.communicationMethod || 1 // Default to "ethernet only" if not defined
    }
  };

  useEffect(() => {
    fetchEnumList().then((data) => {
      setEnumList(data);
      setFetchingEnums(false);
    });
  }, []);

  const getValidationSchema = (currValues: any): Yup.ObjectSchema<any> => {
    const networkSchema: any = {};

    if (formDevice.networkSettings.hostName !== undefined) {
      const hostNameMaxLen = 64;
      networkSchema.hostName = Yup.string()
        .matches(new RegExp(`^${RegexDomainName}$`), hostNameErrorInvalid)
        .max(hostNameMaxLen, stringUtils.format(fieldErrorMaxLen, hostNameTitle, hostNameMaxLen));
    }

    if (
      currValues.networkSettings?.ipVersion &&
      enumList.ipVersion[currValues.networkSettings?.ipVersion].value === 'IPV4'
    ) {
      if (formDevice.networkSettings.ipV4Address !== undefined) {
        networkSchema.ipV4Address = Yup.string().matches(new RegExp(`^${RegexIpV4}$`), ipV4AddressErrorInvalid);
      }

      if (formDevice.networkSettings.subnetMask !== undefined) {
        networkSchema.subnetMask = Yup.string().when('ipV4Address', {
          is: (ipV4Address: string) => ipV4Address && ipV4Address.length > 0,
          then: (schema: any) => schema.required().matches(new RegExp(`^${RegexSubnetMask}$`), subnetMaskErrorInvalid),
          otherwise: (schema: any) => schema.matches(new RegExp(`^${RegexSubnetMask}$`), subnetMaskErrorInvalid)
        });
      }

      if (formDevice.networkSettings.ipV4DefaultGateway !== undefined) {
        networkSchema.ipV4DefaultGateway = Yup.string().matches(new RegExp(`^${RegexIpV4}$`), ipV4AddressErrorInvalid);
      }

      if (formDevice.networkSettings.ipV4PrimaryDns !== undefined) {
        networkSchema.ipV4PrimaryDns = Yup.string().matches(new RegExp(`^${RegexIpV4}$`), ipV4AddressErrorInvalid);
      }

      if (formDevice.networkSettings.ipV4SecondaryDns !== undefined) {
        networkSchema.ipV4SecondaryDns = Yup.string().matches(new RegExp(`^${RegexIpV4}$`), ipV4AddressErrorInvalid);
      }

      // This is not valid. Multicast addresses must be in the range of 224.0.0.0 to 239.255.255.255
      /*if (formDevice.networkSettings.ipV4MulticastAddress !== undefined) {
        networkSchema.ipV4MulticastAddress = Yup.string().matches(
          new RegExp(`^${RegexIpV4}$`),
          ipV4AddressErrorInvalid
        );
      }*/
    } else if (
      currValues.networkSettings?.ipVersion &&
      enumList.ipVersion[currValues.networkSettings?.ipVersion].value === 'IPV6'
    ) {
      if (formDevice.networkSettings.ipV6Address !== undefined) {
        networkSchema.ipV6Address = Yup.string().matches(new RegExp(`^${RegexIpV6}$`), ipV6AddressErrorInvalid);
      }

      if (
        formDevice.networkSettings.ipV6DefaultGateway !== null &&
        formDevice.networkSettings.ipV6DefaultGateway !== undefined
      ) {
        networkSchema.ipV6DefaultGateway = Yup.string().matches(new RegExp(`^${RegexIpV6}$`), ipV6AddressErrorInvalid);
      }

      if (
        formDevice.networkSettings.ipV6PrimaryDns !== null &&
        formDevice.networkSettings.ipV6PrimaryDns !== undefined
      ) {
        networkSchema.ipV6PrimaryDns = Yup.string().matches(new RegExp(`^${RegexIpV6}$`), ipV6AddressErrorInvalid);
      }

      if (
        formDevice.networkSettings.ipV6SecondaryDns !== null &&
        formDevice.networkSettings.ipV6SecondaryDns !== undefined
      ) {
        networkSchema.ipV6SecondaryDns = Yup.string().matches(new RegExp(`^${RegexIpV6}$`), ipV6AddressErrorInvalid);
      }

      if (
        formDevice.networkSettings.ipV6MulticastAddress !== null &&
        formDevice.networkSettings.ipV6MulticastAddress !== undefined
      ) {
        networkSchema.ipV6MulticastAddress = Yup.string().matches(
          new RegExp(`^${RegexIpV6}$`),
          ipV6AddressErrorInvalid
        );
      }
    }

    return Yup.object({
      networkSettings: Yup.object(networkSchema)
    });
  };

  const staticAssociation = async (values: any, device: any) => {
    if (!gateway) {
      setErrorMessage(t('Gateway_Information_Not_Found'));
      return;
    }
    if (!hasEditPermission) {
      setErrorMessage(t('View_only_Permission'));
      return;
    }
    const newDevice = JSON.parse(JSON.stringify(selectedDevice));

    newDevice.networkSettings = {
      ...newDevice.networkSettings,
      ...values.networkSettings
    };
    if (isDeviceIxgTgw) {
      newDevice.gatewaySettings = {
        ...newDevice.gatewaySettings,
        ...values.gatewaySettings
      };
    }
    if (
      newDevice.networkSettings.ipVersion === 'IPV4' &&
      (!newDevice?.networkSettings?.ipV4Address || newDevice.networkSettings.ipV4Address === '0.0.0.0')
    ) {
      setErrorMessage(t('IpV4Address_Error_Invalid'));
      return;
    }
    if (newDevice.networkSettings.ipVersion === 'IPV6') {
      // if ipVersion is IPV6 we need to rethink all the Gateway commands
      setErrorMessage(t('IpV6Address_Error_Invalid'));
      return;
    }

    const dialogTitle = t('Gateway_Associate_Title');
    const dialogContent = t('Gateway_Associate_Content');
    setAssociateDialogTitle(dialogTitle);
    setAssociateDialogContent(dialogContent);
    setIsAssociateDialogOpen(true);
    const isGatewayFirstSync = gateway?.lastSyncedOn === null;
    const systemId = site?.siteInfo?.systemId;
    const systemPassword = site?.siteInfo?.systemPassword;
    const deviceName = device?.basicInfo?.stationName ? device?.basicInfo?.stationName : 'Station'; // if there is no station name, we need a temporary name

    const gatewayInfo = {
      awsPropertyId: site?.siteInfo?.awsPropertyId,
      gwMacAddress: gateway?.basicInfo?.macAddress,
      gwId: systemId ? systemId : isGatewayFirstSync ? 'admin' : gateway?.basicInfo.adminId,
      gwPassword: systemPassword ? systemPassword : isGatewayFirstSync ? 'admin' : gateway?.basicInfo.adminPass,
      gwIpAddress: gateway?.networkSettings?.ipV4Address
    };
    const deviceInfo = {
      deviceMacAddress: device.basicInfo.macAddress,
      deviceIpV4Address: device.networkSettings.ipV4Address,
      deviceSubnetMask: device.networkSettings.subnetMask,
      deviceIpV4DefaultGateway: device.networkSettings.ipV4DefaultGateway,
      deviceName
    };
    try {
      const ioTPayload = fetchGatewayCommand('sendCommand', gwCommand.ASSOCIATE, gatewayInfo, deviceInfo, 'static');
      if (ioTPayload?.payload === 'Missing information') {
        setErrorMessage('Missing information');
        throw new Error('Missing information');
      }
      await handleGatewayCommand(ioTPayload);
      // wait 12 seconds for the device to associate
      await new Promise((resolve) => setTimeout(resolve, 12000));
      const fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.ASSOCIATE, gatewayInfo, deviceInfo, 'static');
      const fetchResponse = await handleGatewayCommand(fetchPayload);
      if (fetchResponse.error || fetchResponse.data?.payload[0]?.statusCode.slice(0, 3) !== '200') {
        throw new Error('Error associating device: ' + fetchResponse.data?.payload[0]?.statusCode);
      } else {
        setNeedsAssociation(false);
      }
    } catch (error: any) {
      setErrorMessage(error);
    }
    setIsAssociateDialogOpen(false);
  };

  const onSubmit = async (values: any, actions: any) => {
    const params: any = {
      device: {
        publicId: selectedDevice.publicId,
        networkSettings: {
          hostName: values.networkSettings.hostName,
          subnetMask: values.networkSettings.subnetMask,
          ipVersion: values.networkSettings.ipVersion,
          ipAddressMethod: values.networkSettings.ipAddressMethod,
          ipV4Address: null,
          ipV4DefaultGateway: null,
          ipV6Address: null,
          ipV6DefaultGateway: null,
          ipV4PrimaryDns: null,
          ipV4SecondaryDns: null,
          ipV6PrimaryDns: null,
          ipV6SecondaryDns: null,
          ipV4MulticastAddress: null,
          ipV6MulticastAddress: null
        }
      }
    };

    // Add communicationMethod only if the device is TGW
    if (isDeviceIxgTgw) {
      params.device.gatewaySettings = {
        communicationMethod: values.gatewaySettings.communicationMethod
      };
    }

    if (enumList.ipVersion[values.networkSettings.ipVersion].value === 'IPV4') {
      params.device.networkSettings.ipV4Address = values.networkSettings.ipV4Address;
      params.device.networkSettings.ipV4DefaultGateway = values.networkSettings.ipV4DefaultGateway;
      params.device.networkSettings.ipV4PrimaryDns = values.networkSettings.ipV4PrimaryDns;
      params.device.networkSettings.ipV4SecondaryDns = values.networkSettings.ipV4SecondaryDns;
      params.device.networkSettings.ipV4MulticastAddress = values.networkSettings.ipV4MulticastAddress;
    } else if (
      formDevice.networkSettings.ipVersion &&
      enumList.ipVersion[formDevice.networkSettings.ipVersion].value === 'IPV6'
    ) {
      params.device.networkSettings.ipV6Address = values.networkSettings.ipV6Address;
      params.device.networkSettings.ipV6DefaultGateway = values.networkSettings.ipV6DefaultGateway;
      params.device.networkSettings.ipV6PrimaryDns = values.networkSettings.ipV6PrimaryDns;
      params.device.networkSettings.ipV6SecondaryDns = values.networkSettings.ipV6SecondaryDns;
      params.device.networkSettings.ipV6MulticastAddress = values.networkSettings.ipV6MulticastAddress;
    }

    const newDevice = JSON.parse(JSON.stringify(selectedDevice));

    newDevice.networkSettings = {
      ...newDevice.networkSettings,
      ...values.networkSettings
    };
    if (isDeviceIxgTgw) {
      newDevice.gatewaySettings = {
        ...newDevice.gatewaySettings,
        ...values.gatewaySettings
      };
    }

    updateDevice(params)
      .then((response) => {
        if ('error' in response) {
          throw response.error;
        } else {
          dispatch(updateSelectedDevice({ device: newDevice }));
          setShowAlert(true);
          actions.resetForm({
            values: values
          });
          setNeedsAssociation(true);
        }
      })
      .catch((error: any) => {
        const err = JSON.parse(error.data);
        if (err.errorDetails.includes('Unauthorized user Id')) {
          setErrorMessage(unauthorizedUser);
        } else {
          setErrorMessage(errorUpdateDevice);
        }
      });
  };

  if (!fetchingEnums && !formikSchema) {
    setFormikSchema(getValidationSchema(formDevice));
  }

  return (
    <>
      {isAssociateDialogOpen && (
        <Dialog
          open={isAssociateDialogOpen}
          onClose={handleClose}
          style={styles.associateModal}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle style={{ display: 'flex', justifyContent: 'center' }}>{associateDialogTitle}</DialogTitle>
          <Box sx={styles.associateModal}>
            <CircularProgress sx={styles.associateModal} size="40px" color="info" />
          </Box>
          <Typography variant="caption" textAlign="center">
            {associateDialogContent}
          </Typography>
        </Dialog>
      )}
      <Box sx={containerStyle.mainWrapper}>
        <SnackbarAlert
          type="error"
          time={10000}
          text={`${errorMessage}`}
          isOpen={!!errorMessage}
          onClose={() => setErrorMessage(null)}
        />
        <SnackbarAlert
          type="success"
          time={3000}
          text={successUpdateDevice}
          isOpen={showAlert}
          onClose={() => setShowAlert(false)}
        />
        {fetchingEnums ? (
          <CircularProgress />
        ) : (
          <Formik initialValues={formDevice} onSubmit={onSubmit} validationSchema={formikSchema}>
            {({ values, dirty, touched, errors, isSubmitting, handleChange }) => (
              <Form style={containerStyle.form}>
                <Box sx={containerStyle.controlPanelWrapper}>
                  <Tooltip title={associationTooltip} style={{ cursor: needsAssociation ? 'pointer' : 'default' }}>
                    {needsAssociation ? (
                      <Alert sx={{ p: 0 }} severity="warning" onClick={() => staticAssociation(values, selectedDevice)}>
                        {buttonAssociate}
                      </Alert>
                    ) : (
                      <Alert sx={{ p: 0 }} severity="success">
                        {buttonAssociate}
                      </Alert>
                    )}
                  </Tooltip>
                  <LoadingButton variant="outlined" type="reset" disabled={!dirty || isSubmitting || isUpdating}>
                    {buttonReset}
                  </LoadingButton>
                  <LoadingButton
                    variant="outlined"
                    loading={isSubmitting}
                    type="submit"
                    disabled={!dirty || isSubmitting || isUpdating}
                  >
                    {buttonSaveChanges}
                  </LoadingButton>
                </Box>
                <Card sx={containerStyle.settingsWrapper}>
                  {!hasEditPermission && <Box sx={styles.viewPermission}>{t('View_only_Permission')}</Box>}

                  <Box sx={containerStyle.gridContainer}>
                    {isDeviceIxgTgw &&
                    formDevice.gatewaySettings.communicationMethod !== null &&
                    formDevice.gatewaySettings.communicationMethod !== undefined ? (
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        style={containerStyle.itemContainer}
                      >
                        <Grid item xs={12} lg={5}>
                          <Box sx={containerStyle.itemTitle}>{internetConnectionTitle}</Box>
                          <Box>{internetConnectionDesc}</Box>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                          <Box sx={containerStyle.fieldContainer}>
                            <Field
                              as={Select}
                              label={internetConnectionTitle}
                              name="gatewaySettings.communicationMethod"
                              style={containerStyle.selectField}
                              fullWidth
                              helperText={
                                touched.gatewaySettings?.communicationMethod &&
                                errors.gatewaySettings?.communicationMethod
                              }
                              error={
                                touched.gatewaySettings?.communicationMethod &&
                                errors.gatewaySettings?.communicationMethod
                              }
                            >
                              {Object.keys(enumList.communicationMethod).map((key) => {
                                return (
                                  <MenuItem key={key} value={key}>
                                    {enumList.communicationMethod[key].value}
                                  </MenuItem>
                                );
                              })}
                            </Field>
                          </Box>
                        </Grid>
                      </Grid>
                    ) : null}

                    {formDevice.networkSettings.hostName !== null &&
                    formDevice.networkSettings.hostName !== undefined ? (
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        style={containerStyle.itemContainer}
                      >
                        <Grid item xs={12} lg={5}>
                          <Box sx={containerStyle.itemTitle}>{hostNameTitle}</Box>
                          <Box>{hostNameDesc}</Box>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                          <Box sx={containerStyle.fieldContainer}>
                            <Field
                              as={TextField}
                              label={hostNameTitle}
                              name="networkSettings.hostName"
                              size="small"
                              style={containerStyle.textField}
                              helperText={touched.networkSettings?.hostName && errors.networkSettings?.hostName}
                              error={touched.networkSettings?.hostName && errors.networkSettings?.hostName}
                              disabled={!hasEditPermission}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    ) : null}
                    {formDevice.networkSettings.ipAddressMethod !== null &&
                    formDevice.networkSettings.ipAddressMethod !== undefined ? (
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        style={containerStyle.itemContainer}
                      >
                        <Grid item xs={12} lg={5}>
                          <Box sx={containerStyle.itemTitle}>{ipAddressMethodTitle}</Box>
                          <Box>{ipAddressMethodDesc}</Box>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                          <Box sx={containerStyle.fieldContainer}>
                            <Field
                              as={Select}
                              label={ipAddressMethodTitle}
                              name="networkSettings.ipAddressMethod"
                              style={containerStyle.selectField}
                              fullWidth // Ensures the dropdown field takes the full width
                              helperText={
                                touched.networkSettings?.ipAddressMethod && errors.networkSettings?.ipAddressMethod
                              }
                              error={
                                touched.networkSettings?.ipAddressMethod && errors.networkSettings?.ipAddressMethod
                              }
                              disabled={!hasEditPermission}
                            >
                              {Object.keys(enumList.ipAddressMethod).map((key) => {
                                return (
                                  <MenuItem key={key} value={key}>
                                    {enumList.ipAddressMethod[key].value}
                                  </MenuItem>
                                );
                              })}
                            </Field>
                          </Box>
                        </Grid>
                      </Grid>
                    ) : null}
                    {formDevice.networkSettings.ipVersion !== null &&
                    formDevice.networkSettings.ipVersion !== undefined ? (
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        style={containerStyle.itemContainer}
                      >
                        <Grid item xs={12} lg={5}>
                          <Box sx={containerStyle.itemTitle}>{ipVersionTitle}</Box>
                          <Box>{ipVersionDesc}</Box>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                          <Box sx={containerStyle.fieldContainer}>
                            <Field
                              as={Select}
                              label={ipVersionTitle}
                              name="networkSettings.ipVersion"
                              style={containerStyle.selectField}
                              helperText={touched.networkSettings?.ipVersion && errors.networkSettings?.ipVersion}
                              error={touched.networkSettings?.ipVersion && errors.networkSettings?.ipVersion}
                              disabled={!hasEditPermission}
                              onChange={(e: any) => {
                                const newVals = {
                                  ...values,
                                  networkSettings: {
                                    ...values.networkSettings,
                                    ipVersion: e.target.value
                                  }
                                };
                                handleChange(e);
                                setFormikSchema(getValidationSchema(newVals));
                              }}
                            >
                              {Object.keys(enumList.ipVersion).map((key) => {
                                return (
                                  <MenuItem key={key} value={key}>
                                    {enumList.ipVersion[key].value}
                                  </MenuItem>
                                );
                              })}
                            </Field>
                          </Box>
                        </Grid>
                      </Grid>
                    ) : null}
                    {values.networkSettings.ipVersion &&
                    enumList.ipVersion[values.networkSettings.ipVersion].value === 'IPV4' ? (
                      <>
                        {formDevice.networkSettings.ipV4Address !== null &&
                        formDevice.networkSettings.ipV4Address !== undefined ? (
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            style={containerStyle.itemContainer}
                          >
                            <Grid item xs={12} lg={5}>
                              <Box sx={containerStyle.itemTitle}>{ipV4AddressTitle}</Box>
                              <Box>{ipV4AddressDesc}</Box>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                              <Box sx={containerStyle.fieldContainer}>
                                <Field
                                  as={TextField}
                                  label={ipV4AddressTitle}
                                  name="networkSettings.ipV4Address"
                                  size="small"
                                  style={containerStyle.textField}
                                  helperText={
                                    touched.networkSettings?.ipV4Address && errors.networkSettings?.ipV4Address
                                  }
                                  error={touched.networkSettings?.ipV4Address && errors.networkSettings?.ipV4Address}
                                  disabled={!hasEditPermission}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        ) : null}
                        {formDevice.networkSettings.subnetMask !== null &&
                        formDevice.networkSettings.subnetMask !== undefined ? (
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            style={containerStyle.itemContainer}
                          >
                            <Grid item xs={12} lg={5}>
                              <Box sx={containerStyle.itemTitle}>{subnetMaskTitle}</Box>
                              <Box>{subnetMaskDesc}</Box>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                              <Box sx={containerStyle.fieldContainer}>
                                <Field
                                  as={TextField}
                                  label={subnetMaskTitle}
                                  name="networkSettings.subnetMask"
                                  size="small"
                                  style={containerStyle.textField}
                                  helperText={touched.networkSettings?.subnetMask && errors.networkSettings?.subnetMask}
                                  error={touched.networkSettings?.subnetMask && errors.networkSettings?.subnetMask}
                                  disabled={!hasEditPermission}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        ) : null}
                        {formDevice.networkSettings.ipV4DefaultGateway !== null &&
                        formDevice.networkSettings.ipV4DefaultGateway !== undefined ? (
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            style={containerStyle.itemContainer}
                          >
                            <Grid item xs={12} lg={5}>
                              <Box sx={containerStyle.itemTitle}>{ipV4DefaultGatewayTitle}</Box>
                              <Box>{ipV4DefaultGatewayDesc}</Box>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                              <Box sx={containerStyle.fieldContainer}>
                                <Field
                                  as={TextField}
                                  label={ipV4DefaultGatewayTitle}
                                  name="networkSettings.ipV4DefaultGateway"
                                  size="small"
                                  style={containerStyle.textField}
                                  helperText={
                                    touched.networkSettings?.ipV4DefaultGateway &&
                                    errors.networkSettings?.ipV4DefaultGateway
                                  }
                                  error={
                                    touched.networkSettings?.ipV4DefaultGateway &&
                                    errors.networkSettings?.ipV4DefaultGateway
                                  }
                                  disabled={!hasEditPermission}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        ) : null}
                        {formDevice.networkSettings.ipV4PrimaryDns !== null &&
                        formDevice.networkSettings.ipV4PrimaryDns !== undefined ? (
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            style={containerStyle.itemContainer}
                          >
                            <Grid item xs={12} lg={5}>
                              <Box sx={containerStyle.itemTitle}>{ipV4PrimaryDnsTitle}</Box>
                              <Box>{ipV4PrimaryDnsDesc}</Box>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                              <Box sx={containerStyle.fieldContainer}>
                                <Field
                                  as={TextField}
                                  label={ipV4PrimaryDnsTitle}
                                  name="networkSettings.ipV4PrimaryDns"
                                  size="small"
                                  style={containerStyle.textField}
                                  helperText={
                                    touched.networkSettings?.ipV4PrimaryDns && errors.networkSettings?.ipV4PrimaryDns
                                  }
                                  error={
                                    touched.networkSettings?.ipV4PrimaryDns && errors.networkSettings?.ipV4PrimaryDns
                                  }
                                  disabled={!hasEditPermission}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        ) : null}
                        {formDevice.networkSettings?.ipV4SecondaryDns !== null &&
                        formDevice.networkSettings.ipV4SecondaryDns !== undefined ? (
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            style={containerStyle.itemContainer}
                          >
                            <Grid item xs={12} lg={5}>
                              <Box sx={containerStyle.itemTitle}>{ipV4SecondaryDnsTitle}</Box>
                              <Box>{ipV4SecondaryDnsDesc}</Box>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                              <Box sx={containerStyle.fieldContainer}>
                                <Field
                                  as={TextField}
                                  label={ipV4SecondaryDnsTitle}
                                  name="networkSettings.ipV4SecondaryDns"
                                  size="small"
                                  style={containerStyle.textField}
                                  helperText={
                                    touched.networkSettings?.ipV4SecondaryDns &&
                                    errors.networkSettings?.ipV4SecondaryDns
                                  }
                                  error={
                                    touched.networkSettings?.ipV4SecondaryDns &&
                                    errors.networkSettings?.ipV4SecondaryDns
                                  }
                                  disabled={!hasEditPermission}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        ) : null}
                        {formDevice.networkSettings.ipV4MulticastAddress !== null &&
                        formDevice.networkSettings.ipV4MulticastAddress !== undefined ? (
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            style={containerStyle.itemContainer}
                          >
                            <Grid item xs={12} lg={5}>
                              <Box sx={containerStyle.itemTitle}>{ipV4MulticastAddressTitle}</Box>
                              <Box>{ipV4MulticastAddressDesc}</Box>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                              <Box sx={containerStyle.fieldContainer}>
                                <Field
                                  as={TextField}
                                  label={ipV4MulticastAddressTitle}
                                  name="networkSettings.ipV4MulticastAddress"
                                  size="small"
                                  style={containerStyle.textField}
                                  helperText={
                                    touched.networkSettings?.ipV4MulticastAddress &&
                                    errors.networkSettings?.ipV4MulticastAddress
                                  }
                                  error={
                                    touched.networkSettings?.ipV4MulticastAddress &&
                                    errors.networkSettings?.ipV4MulticastAddress
                                  }
                                  disabled={!hasEditPermission}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        ) : null}
                      </>
                    ) : values.networkSettings.ipVersion &&
                      enumList.ipVersion[values.networkSettings.ipVersion].value === 'IPV6' ? (
                      <>
                        {formDevice.networkSettings.ipV6Address !== null &&
                        formDevice.networkSettings.ipV6Address !== undefined ? (
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            style={containerStyle.itemContainer}
                          >
                            <Grid item xs={12} lg={5}>
                              <Box sx={containerStyle.itemTitle}>{ipV6AddressTitle}</Box>
                              <Box>{ipV6AddressDesc}</Box>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                              <Box sx={containerStyle.fieldContainer}>
                                <Field
                                  as={TextField}
                                  label={ipV6AddressTitle}
                                  name="networkSettings.ipV6Address"
                                  size="small"
                                  style={containerStyle.textField}
                                  helperText={
                                    touched.networkSettings?.ipV6Address && errors.networkSettings?.ipV6Address
                                  }
                                  error={touched.networkSettings?.ipV6Address && errors.networkSettings?.ipV6Address}
                                  disabled={!hasEditPermission}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        ) : null}
                        {formDevice.networkSettings.ipV6DefaultGateway !== null &&
                        formDevice.networkSettings.ipV6DefaultGateway !== undefined ? (
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            style={containerStyle.itemContainer}
                          >
                            <Grid item xs={12} lg={5}>
                              <Box sx={containerStyle.itemTitle}>{ipV6DefaultGatewayTitle}</Box>
                              <Box>{ipV6DefaultGatewayDesc}</Box>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                              <Box sx={containerStyle.fieldContainer}>
                                <Field
                                  as={TextField}
                                  label={ipV6DefaultGatewayTitle}
                                  name="networkSettings.ipV6DefaultGateway"
                                  size="small"
                                  style={containerStyle.textField}
                                  helperText={
                                    touched.networkSettings?.ipV6DefaultGateway &&
                                    errors.networkSettings?.ipV6DefaultGateway
                                  }
                                  error={
                                    touched.networkSettings?.ipV6DefaultGateway &&
                                    errors.networkSettings?.ipV6DefaultGateway
                                  }
                                  disabled={!hasEditPermission}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        ) : null}
                        {formDevice.networkSettings.ipV6PrimaryDns !== null &&
                        formDevice.networkSettings.ipV6PrimaryDns !== undefined ? (
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            style={containerStyle.itemContainer}
                          >
                            <Grid item xs={12} lg={5}>
                              <Box sx={containerStyle.itemTitle}>{ipV6PrimaryDnsTitle}</Box>
                              <Box>{ipV6PrimaryDnsDesc}</Box>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                              <Box sx={containerStyle.fieldContainer}>
                                <Field
                                  as={TextField}
                                  label={ipV6PrimaryDnsTitle}
                                  name="networkSettings.ipV6PrimaryDns"
                                  size="small"
                                  style={containerStyle.textField}
                                  helperText={
                                    touched.networkSettings?.ipV6PrimaryDns && errors.networkSettings?.ipV6PrimaryDns
                                  }
                                  error={
                                    touched.networkSettings?.ipV6PrimaryDns && errors.networkSettings?.ipV6PrimaryDns
                                  }
                                  disabled={!hasEditPermission}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        ) : null}
                        {formDevice.networkSettings.ipV6SecondaryDns !== null &&
                        formDevice.networkSettings.ipV6SecondaryDns !== undefined ? (
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            style={containerStyle.itemContainer}
                          >
                            <Grid item xs={12} lg={5}>
                              <Box sx={containerStyle.itemTitle}>{ipV6SecondaryDnsTitle}</Box>
                              <Box>{ipV6SecondaryDnsDesc}</Box>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                              <Box sx={containerStyle.fieldContainer}>
                                <Field
                                  as={TextField}
                                  label={ipV6SecondaryDnsTitle}
                                  name="networkSettings.ipV6SecondaryDns"
                                  size="small"
                                  style={containerStyle.textField}
                                  helperText={
                                    touched.networkSettings?.ipV6SecondaryDns &&
                                    errors.networkSettings?.ipV6SecondaryDns
                                  }
                                  error={
                                    touched.networkSettings?.ipV6SecondaryDns &&
                                    errors.networkSettings?.ipV6SecondaryDns
                                  }
                                  disabled={!hasEditPermission}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        ) : null}
                        {formDevice.networkSettings.ipV6MulticastAddress !== null &&
                        formDevice.networkSettings.ipV6MulticastAddress !== undefined ? (
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            style={containerStyle.itemContainer}
                          >
                            <Grid item xs={12} lg={5}>
                              <Box sx={containerStyle.itemTitle}>{ipV6MulticastAddressTitle}</Box>
                              <Box>{ipV6MulticastAddressDesc}</Box>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                              <Box sx={containerStyle.fieldContainer}>
                                <Field
                                  as={TextField}
                                  label={ipV6MulticastAddressTitle}
                                  name="networkSettings.ipV6MulticastAddress"
                                  size="small"
                                  style={containerStyle.textField}
                                  helperText={
                                    touched.networkSettings?.ipV6MulticastAddress &&
                                    errors.networkSettings?.ipV6MulticastAddress
                                  }
                                  error={
                                    touched.networkSettings?.ipV6MulticastAddress &&
                                    errors.networkSettings?.ipV6MulticastAddress
                                  }
                                  disabled={!hasEditPermission}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        ) : null}
                      </>
                    ) : null}
                  </Box>
                </Card>
              </Form>
            )}
          </Formik>
        )}
      </Box>
    </>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  associateModal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    AlignHorizontalCenter: 'center',
    margin: '10px'
  },
  viewPermission: {
    display: 'flex',
    fontWeight: 'bold',
    padding: '5px',
    margin: '10px',
    backgroundColor: '#f5f5f5'
  }
};

export default IPAddress;
