import { Box, Checkbox, ListItem, ListItemButton, ListItemIcon, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import theme from 'data/theme';
import { getDeviceTypeStringFromInt } from 'shared/utils/helperFunctions';
import { AnsweringStationIcon, DoorStationIcon, EntranceStationIcon, TenantStationIcon } from 'shared/icons/icons';

interface IAvailableDeviceItemProps {
  devicePublicId: string;
  displaySettings: string[];
  isSelected: boolean;
  handleAddToSelectedDevices: (id: string) => void;
  handleRemoveFromSelectedDevices: (id: string) => void;
}

const AvailableDeviceItem = ({
  devicePublicId,
  displaySettings,
  isSelected,
  handleAddToSelectedDevices,
  handleRemoveFromSelectedDevices
}: IAvailableDeviceItemProps) => {
  const deviceList = useSelector((state: RootState) => state.devices.DeviceList);
  const device = deviceList[devicePublicId];
  const deviceType = getDeviceTypeStringFromInt(device.basicInfo.deviceType);

  const getDeviceIcon = (deviceType: number) => {
    if (!deviceType) {
      console.error('Device Type has no icon');
      return null; // or handle this case as appropriate
    }
    if (deviceType === 4 || deviceType === 16 || deviceType === 5) {
      return <AnsweringStationIcon viewBox="0 0 25 25" />;
    }

    if (deviceType === 14) {
      return <TenantStationIcon viewBox="0 0 25 25" />;
    }

    if (
      deviceType === 8 ||
      deviceType === 9 ||
      deviceType === 10 ||
      deviceType === 11 ||
      deviceType === 12 ||
      deviceType === 20
    ) {
      return <DoorStationIcon viewBox="0 0 25 25" />;
    }

    if (deviceType === 15) {
      return <EntranceStationIcon viewBox="0 0 25 25" />;
    }

    return null; // Handle the case when devicePublicId doesn't match any device type
  };

  const handleDeviceClick = () => {
    if (isSelected) {
      handleRemoveFromSelectedDevices(devicePublicId);
      return;
    }
    handleAddToSelectedDevices(devicePublicId);
  };

  if (!displaySettings) {
    return null;
  }

  if (device.basicInfo.deviceType === 18 || device.basicInfo.deviceType === 17 || device.basicInfo.deviceType === 6) {
    return null;
  }

  return (
    <>
      <ListItem key={devicePublicId} disablePadding>
        <ListItemButton sx={styles.menuItem} onClick={handleDeviceClick} selected={isSelected}>
          <Box sx={styles.iconWrapper}>
            <ListItemIcon>
              {isSelected ? (
                <Checkbox edge="start" checked={isSelected} tabIndex={-1} disableRipple />
              ) : (
                getDeviceIcon(device.basicInfo.deviceType)
              )}
            </ListItemIcon>
          </Box>
          <Box sx={styles.deviceDetailsWrapper}>
            {displaySettings.includes('stationName') && (
              <Typography variant="body2" sx={styles.deviceDetailsItem}>
                <Typography variant="h7" sx={styles.itemTitle}>
                  Station Name:
                </Typography>
                {device.basicInfo.stationName}
              </Typography>
            )}
            {displaySettings.includes('stationNumber') && (
              <Typography variant="body2" sx={styles.deviceDetailsItem}>
                <Typography variant="h7" sx={styles.itemTitle}>
                  Station Number:
                </Typography>
                {device.basicInfo.stationNumber}
              </Typography>
            )}
            {displaySettings.includes('macAddress') && (
              <Typography variant="body2" sx={styles.deviceDetailsItem}>
                <Typography variant="h7" sx={styles.itemTitle}>
                  Mac Address:
                </Typography>
                {device.basicInfo.macAddress}
              </Typography>
            )}
            {displaySettings.includes('deviceType') && (
              <Typography variant="body2" sx={styles.deviceDetailsItem}>
                <Typography variant="h7" sx={styles.itemTitle}>
                  Device:
                </Typography>
                {deviceType}
              </Typography>
            )}
          </Box>
        </ListItemButton>
      </ListItem>
    </>
  );
};

const styles = {
  menuItem: {
    backgroundColor: theme.palette.neutral.light
  },
  iconWrapper: {
    width: '15%',
    cursor: 'pointer'
  },
  deviceDetailsWrapper: {},
  deviceDetailsItem: {},
  itemTitle: {
    marginRight: '5px',
    fontWeight: 'bold'
  }
};

export default AvailableDeviceItem;
