import { Box, Card, Typography } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridRenderCellParams, GridRowId, GridRowParams } from '@mui/x-data-grid';
import { UnitTypeNames } from 'features/RemoteManagement/Types';
import { ReactElement, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDeleteUnitMutation, useLazyGetUnitListWithSitePublicIdQuery } from 'services/aiphoneCloud';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import { AnsweringStationIcon, DoorStationIcon, EntranceStationIcon, TenantStationIcon } from 'shared/icons/icons';
import { RootState } from 'store';
import AddDeviceToUnitDialog from './AddDeviceToUnit/AddDeviceToUnitDialog';
import AddAppToUnitDialog from './AddAppToUnit/AddAppToUnitDialog';
import { IUnit } from 'store/slices/unitsSlice';
import { BuildingList } from 'store/slices/buildingsSlice';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';

interface IUnitsDataGridProps {
  isFetching: boolean;
}

const UnitsDataGrid = ({ isFetching }: IUnitsDataGridProps) => {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAddDeviceDialogOpen, setIsAddDeviceDialogOpen] = useState<boolean>(false);
  const [isAddAppDialogOpen, setIsAddAppDialogOpen] = useState<boolean>(false);
  const [selectedUnit, setSelectedUnit] = useState<string | null>(null);

  const unitsList = useSelector((state: RootState) => state.units.UnitList);
  const buildingList = useSelector((state: RootState) => state.buildings.BuildingList);
  const deviceListByType = useSelector((state: RootState) => state.devices.DeviceListByType);
  const tenantList = useSelector((state: RootState) => state.tenants.TenantList);
  const appGroups = useSelector((state: RootState) => state.apps.AppGroup);
  const navigate = useNavigate();
  const location = useLocation();
  const sitePublicId = useParams().id;
  const [deleteUnit] = useDeleteUnitMutation();
  const [fetchUnits] = useLazyGetUnitListWithSitePublicIdQuery();

  const { isAllowedTo } = usePermission();
  const canUnitView = isAllowedTo('unit:view', sitePublicId, PermissionsContextType.SITE);
  const canUnitDelete = isAllowedTo('unit:delete', sitePublicId, PermissionsContextType.SITE);
  const canUnitEdit = isAllowedTo('unit:edit', sitePublicId, PermissionsContextType.SITE);
  const canDeviceCreate = isAllowedTo('device:create', sitePublicId, PermissionsContextType.SITE);
  const canAppCreate = isAllowedTo('app:create', sitePublicId, PermissionsContextType.SITE);

  const getBuildingNumber = (unit: IUnit, buildingList: BuildingList) => {
    const building = buildingList[unit.buildingPublicId];
    return building?.buildingNumber ?? '';
  };

  const generateRows = () => {
    return Object.entries(unitsList).map(([key, unit]) => {
      const buildingNumber = getBuildingNumber(unit, buildingList);
      const devices = (unit.devicePublicIds ?? [])
        .map((devicePublicId) => {
          if (Object.keys(deviceListByType).length === 0) {
            console.error('Device list by type is empty');
            return null;
          }
          if (
            deviceListByType['MasterStation'].includes(devicePublicId) ||
            deviceListByType['GuardStation'].includes(devicePublicId) ||
            deviceListByType['SubMasterStation'].includes(devicePublicId)
          ) {
            return <AnsweringStationIcon viewBox="0 0 25 25" />;
          }

          if (deviceListByType['TenantStation'].includes(devicePublicId)) {
            return <TenantStationIcon viewBox="0 0 25 25" />;
          }

          if (
            deviceListByType['VideoDoorStation'].includes(devicePublicId) ||
            deviceListByType['AudioDoorStation'].includes(devicePublicId) ||
            deviceListByType['EmergencyStation'].includes(devicePublicId)
          ) {
            return <DoorStationIcon viewBox="0 0 25 25" />;
          }

          if (deviceListByType['EntranceStation'].includes(devicePublicId)) {
            return <EntranceStationIcon viewBox="0 0 25 25" />;
          }

          return null;
        })
        .filter(Boolean);

      const tenants = Object.values(tenantList).filter((tenant) => tenant.unitPublicId === key);
      const numberOfApps = appGroups[key]?.length ?? 0;

      return {
        id: key,
        buildingNumber: buildingNumber,
        unitNumber: unit.unitNumber,
        unitName: unit.unitName,
        unitType: UnitTypeNames[unit.unitType as 1 | 2 | 3 | 4 | 5 | 6].name,
        devices: devices,
        tenants: tenants.length,
        apps: numberOfApps
      };
    });
  };

  const handleViewUnit = useCallback(
    (id: GridRowId) => () => {
      navigate(`../units/${id}/unitinformation`, { relative: 'path' });
    },
    []
  );

  const handleViewTenants = useCallback(
    (unitNumber: string) => () => {
      navigate(`../tenants?unitNumber=${unitNumber}`, { relative: 'path' });
    },
    []
  );

  const handleAddDevices = (id: GridRowId) => {
    setIsAddDeviceDialogOpen(true);
    setSelectedUnit(id as string);
  };

  const handleAddMobileApps = (id: GridRowId) => {
    setIsAddAppDialogOpen(true);
    setSelectedUnit(id as string);
  };

  const handleMoveOutTenants = () => {
    console.log('Move Out Tenants');
  };

  const handleDeleteUnit = useCallback(
    (id: GridRowId) => async () => {
      setLoading(true);
      try {
        await deleteUnit(id as string);
        setSuccess('Unit deleted successfully');
        fetchUnits({
          sitePublicId: sitePublicId || '',
          qty: 500,
          page: 0
        });
      } catch (error) {
        setError('Failed to delete unit');
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const allowedUnitTypesForApps = ['Guard', 'Commercial', 'Residential'];

  const generateActions = (params: GridRowParams) => {
    const isAllowedToAddApps = allowedUnitTypesForApps.includes(params.row.unitType);

    if (location.pathname.includes('wizard')) {
      return [
        canDeviceCreate && (
          <GridActionsCellItem label="Add Devices" onClick={() => handleAddDevices(params.id)} showInMenu={true} />
        ),
        canAppCreate && (
          <GridActionsCellItem
            label="Add Mobile Apps"
            onClick={() => handleAddMobileApps(params.id)}
            disabled={!isAllowedToAddApps}
            showInMenu={true}
          />
        ),
        canUnitDelete && (
          <GridActionsCellItem label="Delete Unit" onClick={handleDeleteUnit(params.id)} showInMenu={true} />
        )
      ].filter(Boolean);
    } else {
      return [
        canUnitView && <GridActionsCellItem label="View Unit" onClick={handleViewUnit(params.id)} showInMenu={true} />,
        canUnitView && (
          <GridActionsCellItem
            label="View Tenants"
            onClick={handleViewTenants(params.row.unitNumber)}
            showInMenu={true}
          />
        ),
        canDeviceCreate && (
          <GridActionsCellItem label="Add Devices" onClick={() => handleAddDevices(params.id)} showInMenu={true} />
        ),
        canAppCreate && (
          <GridActionsCellItem
            label="Add Mobile Apps"
            onClick={() => handleAddMobileApps(params.id)}
            disabled={!isAllowedToAddApps} // Disable if unit type is not allowed
            showInMenu={true}
          />
        ),
        canUnitEdit && (
          <GridActionsCellItem label="Move Out Tenants" onClick={handleMoveOutTenants} showInMenu={true} disabled />
        ),
        canUnitDelete && (
          <GridActionsCellItem label="Delete Unit" onClick={handleDeleteUnit(params.id)} showInMenu={true} />
        )
      ].filter(Boolean);
    }
  };

  const rows = generateRows();

  const columns = [
    { field: 'buildingNumber', headerName: 'Building Number' },
    { field: 'unitNumber', headerName: 'Unit Number' },
    { field: 'unitName', headerName: 'Unit Name' },
    { field: 'unitType', headerName: 'Unit Type' },
    {
      field: 'devices',
      headerName: 'Devices',
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {params.value.map((icon: ReactElement, index: number) => (
            <Box sx={styles.deviceIcon} key={index}>
              {icon}
            </Box>
          ))}
        </>
      ),
      sortable: false
    },
    {
      field: 'tenants',
      headerName: 'Tenants'
    },
    {
      field: 'apps',
      headerName: 'Apps'
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: GridRowParams) => generateActions(params)
    }
  ];

  return (
    <>
      <Card sx={styles.unitsDataGridWrapper}>
        <Box sx={{ height: '100%', width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            loading={loading || isFetching}
            initialState={{
              sorting: {
                sortModel: [{ field: 'unitNumber', sort: 'asc' }]
              }
            }}
            slots={{
              noRowsOverlay: () => {
                return (
                  <Box sx={styles.noUnits}>
                    <Typography variant="h6">No units have been added yet.</Typography>
                    <Typography variant="body1">
                      Click the <strong>Add a Unit</strong> button to start adding stations.
                    </Typography>
                  </Box>
                );
              }
            }}
          />
        </Box>
      </Card>
      {canDeviceCreate && (
        <AddDeviceToUnitDialog
          selectedUnit={selectedUnit}
          open={isAddDeviceDialogOpen}
          setIsOpen={setIsAddDeviceDialogOpen}
        />
      )}
      {canAppCreate && (
        <AddAppToUnitDialog selectedUnit={selectedUnit} open={isAddAppDialogOpen} setIsOpen={setIsAddAppDialogOpen} />
      )}
      <SnackbarAlert
        type={error ? 'error' : 'success'}
        time={5000}
        text={error || success || ''}
        isOpen={!!error || !!success}
        onClose={() => {
          setError(null);
          setSuccess(null);
        }}
      />
    </>
  );
};

const styles = {
  unitsDataGridWrapper: {
    display: 'flex',
    height: '90%',
    width: '100%'
  },
  deviceIcon: {
    display: 'inline-block',
    marginRight: '5px'
  },
  noUnits: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%'
  }
};

export default UnitsDataGrid;
