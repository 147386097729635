import { FC } from 'react';
import containerStyle from '../../../../../../shared/styles/advancedSettingContainerStyle';
import { Grid } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { CustomSlider } from '../CustomSlider';
import { IVolumeProps } from '../../Volume';
import { t } from 'i18next';

export const VolumeOutgoingSpeaker: FC<IVolumeProps> = ({ defaultValue, isDevice2C7 }) => {
  const { setFieldValue } = useFormikContext();

  const volumeType = 'volumeOutgoingSpeaker';

  const transmitVolumeSpeakerTitle = t(
    isDevice2C7 ? 'AdvancedSettings_TransmitVolumeSpeaker_2C7_Title' : 'AdvancedSettings_TransmitVolumeSpeaker_Title'
  );
  const transmitVolumeSpeakerDesc = t(
    isDevice2C7 ? 'AdvancedSettings_TransmitVolumeSpeaker_2C7_Desc' : 'AdvancedSettings_TransmitVolumeSpeaker_Desc'
  );

  /* Validation */
  const [, volumeOutgoingSpeakerMeta] = useField(volumeType);

  /* Handlers */
  const handleChange = async (value: number): Promise<void> => {
    await setFieldValue(volumeType, value);
  };

  /* If the device does not support this feature, return null */
  if (defaultValue === undefined) {
    return null;
  }

  return (
    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
      <Grid item xs={6} lg={7}>
        <div style={containerStyle.itemTitle}>{transmitVolumeSpeakerTitle}</div>
        <div style={containerStyle.itemDesc}>{transmitVolumeSpeakerDesc}</div>
      </Grid>
      <Grid item xs={6} lg={4} justifyContent="flex-end">
        <CustomSlider
          label={transmitVolumeSpeakerTitle}
          name={volumeType}
          min={1}
          max={10}
          step={1}
          onChange={handleChange}
          helperText={!!(volumeOutgoingSpeakerMeta.touched && volumeOutgoingSpeakerMeta.error)}
          defaultValue={defaultValue}
          styles={containerStyle.sliderBar}
        />
      </Grid>
    </Grid>
  );
};
