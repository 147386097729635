import {
  Grid,
  TextField,
  Typography,
  Box,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  FormControlLabel,
  FormGroup,
  Switch
} from '@mui/material';
import { UserInfo } from 'shared/utils/UserUtils';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import ChangePasswordDialog from 'shared/components/ChangePasswordDialog';
import { useUpdateUserMutation } from 'services/aiphoneCloud';
import {
  CloudUser,
  getCurrentUser,
  //IBranchPermission,  // Not used, commented out to remove the warning
  //IBranchPermissions, // Not used, commented out to remove the warning
  IUserPermissions,
  setUserProfile
} from 'store/slices/usersSlice';
import { LoadingButton } from '@mui/lab';
import { RootState } from 'store';

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  marketingOptIn: boolean;
}

// Not used, commented out to remove the warning
//const convertPermissionsBranchToArray = (branchPermissions: IBranchPermissions): IBranchPermission[] => {
//  return Object.values(branchPermissions);
//};

const UserProfile = () => {
  const userObj = useSelector(getCurrentUser);
  const [userInfo, setUserInfo] = useState(new UserInfo(userObj));
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isChangePasswordDialogOpen, setIsChangePasswordDialogOpen] = useState(false);
  const [updateUser] = useUpdateUserMutation();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const currentUser = useSelector(getCurrentUser);
  const permissions: IUserPermissions | undefined = currentUser?.permissions;
  const registeredUser = currentUser ? !permissions || (!permissions.global && !permissions.branch) : true;
  const hasGlobalRole =
    permissions && permissions.global?.roleList !== null && permissions.global?.roleList !== undefined;

  // Not used, commented out to remove the warning
  //const branches: IBranchPermission[] = permissions?.branch ? convertPermissionsBranchToArray(permissions.branch) : [];
  let branchName = '';
  let companyName = '';
  let branchRole = '';
  const branchData = useSelector((state: RootState) => state.branches);
  if (branchData.currentBranch && currentUser?.permissions.branch) {
    branchName = branchData.currentBranch?.branchName;
    companyName = branchData.currentBranch?.company.name;
    branchRole = currentUser.permissions.branch[branchData.currentBranch?.publicId]?.roleList[0]?.roleName;
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phoneNumber: Yup.string()
      .required('Phone Number is required')
      .matches(/^\+?[1-9]\d{1,14}$/, 'Phone number must be in the format "+15555555555"')
  });

  const handleUpdateUser = (values: FormValues) => {
    const formattedEmail = values.email.toLowerCase();
    const updateUserRequest = {
      userData: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: formattedEmail,
        phoneNumber: values.phoneNumber,
        phoneExtension: userInfo.phoneExtension,
        countryId: userInfo.countryId,
        language: userInfo.language,
        marketingOptIn: values.marketingOptIn
      }
    };
    setIsLoading(true);
    setUserInfo(new UserInfo(values));
    updateUser(updateUserRequest)
      .unwrap()
      .then(() => {
        setSuccessMessage('User updated successfully');
        setIsSuccess(true);
        setIsLoading(false);
        const updatedUser = new UserInfo(values);
        updatedUser.saveUser();

        // Store updated user information to user slice
        // This will keep the user slice up to date until reloading
        const newUserProfile: CloudUser = {
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phoneNumber,
          marketingOptIn: values.marketingOptIn
        };
        dispatch(setUserProfile(newUserProfile));
      })
      .catch(() => {
        setErrorMessage('Failed to update user profile');
        setIsError(true);
        setIsLoading(false);
      });
  };

  const ViewUser = () => {
    return (
      <>
        <Box sx={styles.userContainer}>
          <Typography variant="h5" color="initial">
            User Profile
          </Typography>
          <Formik
            initialValues={
              {
                firstName: userInfo.firstName,
                lastName: userInfo.lastName,
                email: userInfo.email,
                phoneNumber: userInfo.phoneNumber,
                marketingOptIn: userInfo.marketingOptIn
              } as FormValues
            }
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleUpdateUser(values);
            }}
            enableReinitialize
          >
            {({ errors, touched, values, handleChange, dirty }) => {
              return (
                <Form>
                  <Box sx={styles.formContainer}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Field
                          as={TextField}
                          label="First Name"
                          name="firstName"
                          size="small"
                          fullWidth={true}
                          helperText={touched.firstName && errors.firstName ? errors.firstName : ''}
                          error={touched.firstName && !!errors.firstName}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          as={TextField}
                          label="Last Name"
                          name="lastName"
                          size="small"
                          fullWidth={true}
                          helperText={touched.lastName && errors.lastName ? errors.lastName : ''}
                          error={touched.lastName && !!errors.lastName}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field as={TextField} label="Email" name="email" size="small" fullWidth={true} disabled />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          as={TextField}
                          label="Phone Number"
                          name="phoneNumber"
                          size="small"
                          fullWidth={true}
                          helperText={touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : ''}
                          error={touched.phoneNumber && !!errors.phoneNumber}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="h6" color="initial">
                          Notifications
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormGroup>
                          <FormControlLabel
                            control={<Field as={Switch} name="marketingOptIn" checked={values.marketingOptIn} />}
                            label="Marketing Promotions"
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={6}>
                            <Button variant="contained" onClick={() => setIsChangePasswordDialogOpen(true)}>
                              Change Password
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <LoadingButton
                              loading={isLoading}
                              type="submit"
                              variant="contained"
                              disabled={!dirty}
                              sx={styles.floatRight}
                            >
                              Save Changes
                            </LoadingButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
        <Divider sx={{ marginY: 4 }} />
        <Box sx={styles.rolesContainer}>
          <Typography variant="h5" color="initial">
            My Roles
          </Typography>
          <TableContainer component={Paper}>
            <Table aria-label="roles table">
              <TableHead>
                <TableRow>
                  <TableCell>Company</TableCell>
                  <TableCell>Branch</TableCell>
                  <TableCell>Role</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {hasGlobalRole ? (
                  <TableRow key="global">
                    <TableCell>Aiphone</TableCell>
                    <TableCell></TableCell>
                    <TableCell>{permissions?.global?.roleList[0]?.roleName}</TableCell>
                  </TableRow>
                ) : (
                  <></>
                )}
                {!registeredUser ? (
                  <TableRow key="branch">
                    <TableCell>{companyName}</TableCell>
                    <TableCell>{branchName}</TableCell>
                    <TableCell>{branchRole}</TableCell>
                  </TableRow>
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <SnackbarAlert
          type="error"
          time={6000}
          text={errorMessage}
          isOpen={isError}
          onClose={() => setIsError(false)}
        />
        <SnackbarAlert
          type="success"
          time={6000}
          text={successMessage}
          isOpen={isSuccess}
          onClose={() => {
            setIsSuccess(false);
          }}
        />
        <ChangePasswordDialog
          isChangePasswordDialogOpen={isChangePasswordDialogOpen}
          setIsChangePasswordDialogOpen={setIsChangePasswordDialogOpen}
          setErrorMessage={setErrorMessage}
          setIsError={setIsError}
          setSuccessMessage={setSuccessMessage}
          setIsSuccess={setIsSuccess}
        />
      </>
    );
  };

  // NOTE: Incase we want to add a feature to edit user profile, we can add a button to toggle the isEditing state and show the form fields for editing the user profile.
  return <ViewUser />;
};

const styles = {
  formContainer: {
    mt: 2
  },
  userContainer: {
    mt: 2
  },
  rolesContainer: {
    mt: 2
  },
  floatRight: {
    float: 'right'
  }
};

export default UserProfile;
