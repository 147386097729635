import { createTheme } from '@mui/material';
import { green, grey } from '@mui/material/colors';

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
    green: Palette['primary'];
    red: Palette['primary'];
    tertiary: Palette['primary'];
    white: Palette['primary'];
    aiphoneOrange: Palette['primary'];
  }

  interface PaletteOptions {
    tertiary: PaletteOptions['primary'];
    neutral: PaletteOptions['primary'];
    green: PaletteOptions['primary'];
    red: PaletteOptions['primary'];
    white: PaletteOptions['primary'];
    aiphoneOrange: PaletteOptions['primary'];
  }

  interface PaletteColor {
    normal?: string;
    medium?: string;
  }

  interface SimplePaletteColorOptions {
    normal?: string;
    medium?: string;
  }
}

declare module '@mui/material/CircularProgress' {
  interface CircularProgressPropsColorOverrides {
    white: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    cardTitle: true;
    sectionHeader: true;
    h6: true;
    h7: true;
    h8: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    aiphoneOrange: true;
  }
}

let theme = createTheme({
  palette: {
    primary: {
      main: '#003366'
    },
    secondary: {
      main: '#0071CE'
    },
    tertiary: {
      main: '#1CACFE'
    },
    aiphoneOrange: {
      main: '#D14800'
    },
    neutral: {
      light: grey[50],
      medium: grey[200],
      normal: grey[500],
      main: grey[900]
    },
    green: {
      main: green[800]
    },
    red: {
      main: '#E0004D'
    },
    white: {
      main: '#FFFFFF'
    }
  }
});

theme = createTheme(theme, {
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'aiphoneOrange' },
          style: {
            textTransform: 'none',
            color: '#FFFFFF',
            backgroundColor: theme.palette.aiphoneOrange.main,
            '&:hover': {
              backgroundColor: '#FF7500'
            }
          }
        }
      ],
      styleOverrides: {
        root: {
          textTransform: 'none'
        },
        contained: {
          textTransform: 'none',
          '&&:hover': {
            backgroundColor: theme.palette.secondary.main
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: theme.palette.secondary.main,
          '&.Mui-checked': {
            color: theme.palette.secondary.main
          }
        }
      }
    }
  }
});

//customize theme based on initial theme created above

theme = createTheme(theme, {
  typography: {
    link: {
      fontSize: '0.8rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '0.9rem'
      },
      fontWeight: 500,
      color: theme.palette.primary.normal,
      display: 'block',
      cursor: 'pointer'
    },
    cardTitle: {
      fontSize: '1.2rem',
      display: 'block',
      fontWeight: 500
    },
    sectionHeader: {
      fontSize: '1.8rem',
      fontWeight: 500,
      color: theme.palette.secondary.main,
      display: 'block'
    },
    h6: {
      fontSize: '1rem'
    },
    h7: {
      fontSize: '0.8rem'
    },
    h8: {
      fontSize: '0.7rem'
    }
  }
});

export default theme;
