/**
 * This page manages tabs across the Devices pages.
 * If you want to add a new tab to any devices page perform the following:
 * 1. Create a tab content file and then import it into this file like the below imports.
 * 2. Add an enum for your new tab in the DeviceTabType enum below.
 * 3. Add a new entry to the tabsContainer map below, referencing the enum as a key and
 *      the tab content exports for the TabInfo object.
 *
 * Once these steps are complete you can add the DeviceTabType enum to any array being
 *   passed into the TabsContainer. See any station tabs file for an example.
 */

// Import the tab content file here.
import { TabItem } from 'shared/components/TabsContainer';
import CallDestination, { CallDestinationLabel } from './callSettings/callDestination/CallDestination';
import OutboundCallSetting, { OutboundCallSettingLabel } from './callSettings/outboundCallSetting/OutboundCallSetting';
import IncomingCall, { IncomingCallLabel } from './callSettings/IncomingCall';
import CGI, { CGILabel } from './integrations/CGI';
import DisplayMode, { DisplayModeLabel } from './integrations/DisplayMode';
import Email, { EmailLabel } from './integrations/Email';
import NetworkCameraIntegration, { NetworkCameraIntegrationLabel } from './integrations/NetworkCameraIntegration';
import Recording, { RecordingLabel } from './integrations/Recording';
import SIF, { SIFLabel } from './integrations/SIF';
import Audio, { AudioLabel } from './networkInfo/Audio';
import IPAddress, { IPAddressLabel } from './networkInfo/IPAddress';
import SIPSettings, { SIPSettingsLabel } from './networkInfo/SIPSettings';
import SyslogSettings, { SyslogSettingsLabel } from './networkInfo/SyslogSettings';
import Time, { TimeLabel } from './networkInfo/Time';
import Video, { VideoLabel } from './networkInfo/Video';
import AllPage, { AllPageLabel } from './paging/AllPage';
import BuildingPage, { BuildingPageLabel } from './paging/BuildingPage';
import OptionInputPage, { OptionInputPageLabel } from './paging/OptionInputPage';
import CSR, { CSRLabel } from './securitySettings/CSR';
import IDPassword, { IDPasswordLabel } from './securitySettings/IDPassword';
import IEEE8021X, { IEEE8021XLabel } from './securitySettings/IEEE8021X';
import SSLCertificate, { SSLCertificateLabel } from './securitySettings/SSLCertificate';
import Communication, { CommunicationLabel } from './soundSettings/Communication';
import CustomSoundRegistry, { CustomSoundRegistryLabel } from './soundSettings/CustomSoundRegistry';
import Volume, { VolumeLabel } from './soundSettings/Volume';
import StationIdentification, { StationIdentificationLabel } from './stationInfo/StationIdentification';
import UnitsStations, { UnitsStationsLabel } from './stationInfo/UnitsStations';
import AbsentTransfer, { AbsentTransferLabel } from './transfer/absentTransfer/AbsentTransfer';
import DelayTransfer, { DelayTransferLabel } from './transfer/delayTransfer/DelayTransfer';
import ScheduleTransfer, { ScheduleTransferLabel } from './transfer/ScheduleTransfer';
import IXGSupportTool, { IXGSupportToolLabel } from './liftControl/IXGSupportTool';
import RelayOutputLift, { RelayOutputLiftLabel } from './liftControl/RelayOutputLift';
import EntranceDisplay, { EntranceDisplayLabel } from './entrance/entranceDisplay/EntranceDisplay.tsx';
import PhoneSettings, { PhoneSettingsLabel } from './gateway/Phone/PhoneSettings.tsx';
// import VLAN, { VLANLabel } from './networkInfo/VLAN.tsx';
//import WelcomeScreen, { WelcomeScreenLabel } from './entrance/welcomeScreen/WelcomeScreen.tsx';

// Add the enum here, currently in alphabetical order.
// Please maintain the name format of <SettingPageName>_<TabName>
export enum DeviceTabType {
  CallSettings_CallDestination,
  CallSettings_OutboundCallSetting,
  CallSettings_IncomingCall,
  Entrance_Display,
  InputOutput_AccessCode,
  InputOutput_ContactInput,
  InputOutput_DoorRelease,
  InputOutput_OptionInput,
  InputOutput_RelayOutput,
  Integrations_CGI,
  Integrations_DisplayMode,
  Integrations_Email,
  Integrations_NetworkCameraIntegration,
  Integrations_Recording,
  Integrations_SIF,
  LiftControl_IXGSupportTool,
  LiftControl_RelayOutput,
  Maintenance_Tools,
  NetworkInfo_Audio,
  NetworkInfo_IPV4Address,
  NetworkInfo_SIPSettings,
  NetworkInfo_SyslogSettings,
  NetworkInfo_Time,
  NetworkInfo_Video,
  //Hiding VLAN, non-mvp: NetworkInfo_VLAN,
  Paging_AllPage,
  Paging_BuildingPage,
  Paging_OptionInputPage,
  Phone_PhoneSettings,
  SecuritySettings_CSR,
  SecuritySettings_IDPassword,
  SecuritySettings_IEEE8021X,
  SecuritySettings_SSLCertificate,
  SoundSettings_Communication,
  SoundSettings_CustomSoundRegistry,
  SoundSettings_Volume,
  StationInfo_StationIdentification,
  StationInfo_UnitsStations,
  Transfer_AbsentTransfer,
  Transfer_DelayTransfer,
  Transfer_ScheduleTransfer
}

// Add the new tab to the tabsContainer map in this class.
export class DeviceTabs {
  private tabsContainer: Map<DeviceTabType, TabItem> = new Map<DeviceTabType, TabItem>([
    [DeviceTabType.CallSettings_CallDestination, new TabItem(<CallDestinationLabel />, <CallDestination />)],
    [
      DeviceTabType.CallSettings_OutboundCallSetting,
      new TabItem(<OutboundCallSettingLabel />, <OutboundCallSetting />)
    ],
    [DeviceTabType.CallSettings_IncomingCall, new TabItem(<IncomingCallLabel />, <IncomingCall />)],
    [DeviceTabType.Entrance_Display, new TabItem(<EntranceDisplayLabel />, <EntranceDisplay />)],
    [DeviceTabType.Integrations_CGI, new TabItem(<CGILabel />, <CGI />)],
    [DeviceTabType.Integrations_DisplayMode, new TabItem(<DisplayModeLabel />, <DisplayMode />)],
    [DeviceTabType.Integrations_Email, new TabItem(<EmailLabel />, <Email />)],
    [
      DeviceTabType.Integrations_NetworkCameraIntegration,
      new TabItem(<NetworkCameraIntegrationLabel />, <NetworkCameraIntegration />)
    ],
    [DeviceTabType.Integrations_Recording, new TabItem(<RecordingLabel />, <Recording />)],
    [DeviceTabType.Integrations_SIF, new TabItem(<SIFLabel />, <SIF />)],
    [DeviceTabType.LiftControl_IXGSupportTool, new TabItem(<IXGSupportToolLabel />, <IXGSupportTool />)],
    [DeviceTabType.LiftControl_RelayOutput, new TabItem(<RelayOutputLiftLabel />, <RelayOutputLift />)],

    [DeviceTabType.NetworkInfo_Audio, new TabItem(<AudioLabel />, <Audio />)],
    [DeviceTabType.NetworkInfo_IPV4Address, new TabItem(<IPAddressLabel />, <IPAddress />)],
    [DeviceTabType.NetworkInfo_SIPSettings, new TabItem(<SIPSettingsLabel />, <SIPSettings />)],
    [DeviceTabType.NetworkInfo_SyslogSettings, new TabItem(<SyslogSettingsLabel />, <SyslogSettings />)],
    [DeviceTabType.NetworkInfo_Time, new TabItem(<TimeLabel />, <Time />)],
    [DeviceTabType.NetworkInfo_Video, new TabItem(<VideoLabel />, <Video />)],
    //Hiding VLAN, non-mvp: [DeviceTabType.NetworkInfo_VLAN, new TabItem(<VLANLabel />, <VLAN />)],
    [DeviceTabType.Paging_AllPage, new TabItem(<AllPageLabel />, <AllPage />)],
    [DeviceTabType.Paging_BuildingPage, new TabItem(<BuildingPageLabel />, <BuildingPage />)],
    [DeviceTabType.Paging_OptionInputPage, new TabItem(<OptionInputPageLabel />, <OptionInputPage />)],
    [DeviceTabType.Phone_PhoneSettings, new TabItem(<PhoneSettingsLabel />, <PhoneSettings />)],
    [DeviceTabType.SecuritySettings_CSR, new TabItem(<CSRLabel />, <CSR />)],
    [DeviceTabType.SecuritySettings_IDPassword, new TabItem(<IDPasswordLabel />, <IDPassword />)],
    [DeviceTabType.SecuritySettings_IEEE8021X, new TabItem(<IEEE8021XLabel />, <IEEE8021X />)],
    [DeviceTabType.SecuritySettings_SSLCertificate, new TabItem(<SSLCertificateLabel />, <SSLCertificate />)],
    [DeviceTabType.SoundSettings_Communication, new TabItem(<CommunicationLabel />, <Communication />)],
    [
      DeviceTabType.SoundSettings_CustomSoundRegistry,
      new TabItem(<CustomSoundRegistryLabel />, <CustomSoundRegistry />)
    ],
    [DeviceTabType.SoundSettings_Volume, new TabItem(<VolumeLabel />, <Volume />)],
    [
      DeviceTabType.StationInfo_StationIdentification,
      new TabItem(<StationIdentificationLabel />, <StationIdentification />)
    ],
    [DeviceTabType.StationInfo_UnitsStations, new TabItem(<UnitsStationsLabel />, <UnitsStations />)],
    [DeviceTabType.Transfer_DelayTransfer, new TabItem(<DelayTransferLabel />, <DelayTransfer />)],
    [DeviceTabType.Transfer_AbsentTransfer, new TabItem(<AbsentTransferLabel />, <AbsentTransfer />)],
    [DeviceTabType.Transfer_ScheduleTransfer, new TabItem(<ScheduleTransferLabel />, <ScheduleTransfer />)]
  ]);

  private getTab(tabName: DeviceTabType): TabItem {
    const targetTab: any = this.tabsContainer.get(tabName);

    if (targetTab === undefined) {
      throw new Error(DeviceTabType[tabName] + ' tab is not implemented!');
    } else {
      return targetTab as TabItem;
    }
  }

  public getTabs(tabNames: DeviceTabType[]): TabItem[] {
    const ret: TabItem[] = [];
    tabNames.forEach((val) => {
      ret.push(this.getTab(val));
    });

    return ret;
  }
}
