import { Checkbox, Grid } from '@mui/material';
import containerStyle from '../../../../../../shared/styles/advancedSettingContainerStyle';
import { Field, useField, useFormikContext } from 'formik';
import React, { FC, useState } from 'react';
import { t } from 'i18next';

const volumeConfig = {
  volumeType: 'volumeOperation',
  volumeTitle: 'AdvancedSettings_ButtonFeedback_Title',
  volumeDesc: 'AdvancedSettings_ButtonFeedback_Desc'
};

export const VolumeOperation: FC<{ defaultValue: number | boolean | undefined }> = ({ defaultValue }) => {
  const [value, setValue] = useState(defaultValue);
  const { setFieldValue } = useFormikContext();
  const { volumeType, volumeTitle, volumeDesc } = volumeConfig;

  /*Validation*/
  const [, volumeOperationMeta] = useField(volumeType);

  /*Static data*/
  const buttonFeedbackTitle = t(volumeTitle);
  const buttonFeedbackDesc = t(volumeDesc);

  /*Handlers*/
  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const value = event.target.checked;
    setValue(value);
    await setFieldValue(volumeType, value);
  };

  /*If the device does not support this feature, return null*/
  if (defaultValue === undefined) {
    return null;
  }

  return (
    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
      <Grid item xs={6} lg={7}>
        <div style={containerStyle.itemTitle}>{buttonFeedbackTitle}</div>
        <div style={containerStyle.itemDesc}>{buttonFeedbackDesc}</div>
      </Grid>
      <Grid item xs={6} lg={4} justifyContent="flex-end">
        <div style={containerStyle.fieldContainer}>
          <Field
            type="checkbox"
            component={Checkbox}
            name={volumeType}
            label={buttonFeedbackTitle}
            style={containerStyle.toggleField}
            helperText={volumeOperationMeta.touched && volumeOperationMeta.error}
            onChange={handleChange}
            checked={value}
          />
        </div>
      </Grid>
    </Grid>
  );
};
