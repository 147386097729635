import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ReDirectedPage = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/');
  };
  return (
    <Box sx={styles.mainContainer}>
      <p>You are being redirected, please wait..</p>
      <p>If you haven't been redirected in 30 seconds, Please click this button</p>

      <Button variant="outlined" onClick={handleRedirect}>
        Continue
      </Button>
    </Box>
  );
};
/** @type {import('@mui/material'.SxProps)} */
const styles = {
  mainContainer: {
    margin: 'auto',
    textAlign: 'center'
  }
};
export default ReDirectedPage;
