import { useCallback, useState } from 'react';
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import NewSiteDialog from './NewSiteDialog';
import Dialog from '@mui/material/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import LoginPanel from '../SiteDashboard/Billing/components/MobileService/LoginPanel';
import CreatePanel from '../SiteDashboard/Billing/components/MobileService/CreatePanel';
import ActivationPanel from '../SiteDashboard/Billing/components/MobileService/ActivationPanel';
import { RootState } from 'store';

const strings = {
  content: {
    remoteManagementDescription: 'Use Remote Management to manage IX | IXG Intercom Systems.',
    ixgPortal: 'Please log in / register to IXG portal to create a new site.',
    createSite: 'Ready to create a new site?'
  },
  title: 'New Site Creation',
  createSiteButtonText: 'Create Site'
};

const CreateSite = () => {
  const [isNewSiteDialogOpen, setIsNewSiteDialogOpen] = useState(false);
  const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);
  const [isRegisterDialogOpen, setIsRegisterDialogOpen] = useState(false);
  const [isActivationDialogOpen, setIsActivationDialogOpen] = useState(false);
  const usersCurrentBranch = useSelector((state: RootState) => state.branches.currentBranch);

  const dispatch = useDispatch();
  const aclToken = localStorage.getItem('acltoken');

  const handleClose = () => {
    setIsLoginDialogOpen(false);
    setIsRegisterDialogOpen(false);
    setIsActivationDialogOpen(false);
  };
  const setUsernameAndToken = useCallback(
    async (userName: string, receivedToken: string) => {
      localStorage.setItem('acltoken', receivedToken);
      localStorage.setItem('aclUserName', userName);
      setTimeout(() => {
        //close the dialog after 3 second from login
        setIsLoginDialogOpen(false);
      }, 3000);
    },
    [dispatch]
  );

  return (
    <Card>
      <CardContent>
        <Box sx={styles.featuredAppContainer}>
          <Typography variant="cardTitle" sx={styles.titleContainer}>
            {strings.title}
          </Typography>
          <Box sx={styles.content}>
            {aclToken ? (
              <Typography variant="body2">{strings.content.createSite}</Typography>
            ) : (
              <>
                <Typography variant="body2">{strings.content.remoteManagementDescription}</Typography>
                <Typography variant="body2">{strings.content.ixgPortal}</Typography>
              </>
            )}
          </Box>
          {aclToken ? (
            <Grid container spacing={2}>
              <Box sx={styles.buttonContainer}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setIsNewSiteDialogOpen(true);
                  }}
                  disabled={!usersCurrentBranch}
                >
                  {strings.createSiteButtonText}
                </Button>
              </Box>
            </Grid>
          ) : (
            <Box sx={styles.buttonContainer}>
              <Button variant="contained" onClick={() => setIsLoginDialogOpen(true)}>
                Log in
              </Button>
              <Button variant="contained" onClick={() => setIsRegisterDialogOpen(true)}>
                Register
              </Button>
            </Box>
          )}
          {isLoginDialogOpen && (
            <Dialog open={isLoginDialogOpen} onClose={handleClose} fullWidth maxWidth="sm">
              <LoginPanel
                handleClose={handleClose}
                setUsernameAndToken={setUsernameAndToken}
                setIsActivationDialogOpen={setIsActivationDialogOpen}
                setIsLoginDialogOpen={setIsLoginDialogOpen}
              />
            </Dialog>
          )}
          {isRegisterDialogOpen && (
            <Dialog open={isRegisterDialogOpen} onClose={handleClose} fullWidth maxWidth="sm">
              <CreatePanel
                setIsActivationDialogOpen={setIsActivationDialogOpen}
                setIsRegisterDialogOpen={setIsRegisterDialogOpen}
              />
            </Dialog>
          )}
          {isActivationDialogOpen && (
            <Dialog open={isActivationDialogOpen} onClose={handleClose} fullWidth maxWidth="sm">
              <ActivationPanel onClose={handleClose} />
            </Dialog>
          )}
          <NewSiteDialog isOpen={isNewSiteDialogOpen} setIsOpen={setIsNewSiteDialogOpen} />
        </Box>
      </CardContent>
    </Card>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  featuredAppContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'relative',
    padding: '5px'
  },
  titleContainer: {
    fontWeight: 600,
    width: '100%',
    textAlign: 'center',
    backgroundColor: '#E8E8E8',
    borderRadius: '5px',
    color: '#606060'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    borderRadius: '5px',
    padding: '10px',
    margin: '10px',
    mt: 1,
    mb: 1
  },
  buttonContainer: {
    marginTop: 2,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%'
  }
};

export default CreateSite;
