import { UUID } from 'crypto';
import { ISound } from 'features/RemoteManagement/DeviceDashboard/addressBook/types';
import { EnumList, fetchLocalEnumList } from 'shared/utils/EnumUtils';
import {
  DeviceList,
  IContactGroupEntry,
  IDevice,
  IRingtoneSettings,
  IUnlockOperationGroupList
} from 'store/slices/devicesSlice';
import { IUnitList } from 'store/slices/unitsSlice';

interface IAddressBookEntry {
  targetDevicePublicId: string;
  unlockSetting?: {
    devicePublicId?: string;
    deviceTarget?: number;
  };
  ringtoneSetting?: IRingtoneSettings;
}

/**
 * Helper class for updating devices.
 */
export class DeviceUpdateHelper {
  public deviceList: DeviceList;
  public unitList: IUnitList;
  public systemType: number;
  public siteId: string;
  public unitTypes: EnumList['unitType'];
  public deviceTypes: EnumList['deviceType'];
  private deviceEntries: [string, IDevice][] = [];
  private payload: any = [];

  /**
   * Constructs a new DeviceUpdateHelper instance.
   * @param deviceList The list of devices.
   * @param unitList The list of units.
   */
  constructor(deviceList: DeviceList, unitList: IUnitList, systemType: number, siteId: string) {
    this.deviceList = deviceList;
    this.unitList = unitList;
    this.systemType = systemType;
    this.siteId = siteId;
    const enumList: EnumList = fetchLocalEnumList();
    this.unitTypes = enumList.unitType;
    this.deviceTypes = enumList.deviceType;
    this.deviceEntries = Object.entries(this.deviceList);
  }
  private getDefaultAddressBookEntry(devicePublicId: string, sounds: ISound[]): IAddressBookEntry {
    // Default ringtone settings
    const defaultUUID = '00000000-0000-0000-0000-000000000000';
    const defaultRingtoneSetting = {
      callButtonNormalSound: sounds[1]?.public_id || defaultUUID,
      callButtonPrioritySound: sounds[2]?.public_id || defaultUUID,
      callButtonUrgentSound: sounds[3]?.public_id || defaultUUID,
      optionInputNormalSound: sounds[1]?.public_id || defaultUUID,
      optionInputPrioritySound: sounds[2]?.public_id || defaultUUID,
      optionInputUrgentSound: sounds[3]?.public_id || defaultUUID
    };

    const defaultUnlockSetting = {
      deviceTarget: 2
    };

    return {
      targetDevicePublicId: devicePublicId,
      unlockSetting: defaultUnlockSetting,
      ringtoneSetting: defaultRingtoneSetting
    };
  }

  /**
   * Adds address book entries for a device.
   * @param devicePublicId Public ID of the device to add address book entries for.
   * @param unitType Unit type of the device to add address book entries for (1-6).
   * @returns Array of address book entries.
   */
  private addAddressBookEntries(
    devicePublicId: string,
    unitType: number,
    sounds: ISound[]
  ): IAddressBookEntry[] | null {
    const availableDevices = [4, 5, 8, 9, 10, 11, 13, 14, 15, 16, 18, 19, 20];
    const addressBookEntries: IAddressBookEntry[] = [];
    const unitPublicId = this.deviceList[devicePublicId].unitPublicId;

    // If the device is not associated with a unit, return an empty array
    if (!unitPublicId) {
      return addressBookEntries;
    }

    // Get the list of devices associated with the unit
    const unitDevices = this.unitList[unitPublicId].devicePublicIds;

    // Iterate through the device list and add address book entries for devices that meet the criteria
    Object.entries(this.deviceList).forEach(([publicId, device]) => {
      const targetDeviceUnitPublicId = this.deviceList[publicId].unitPublicId;

      // If the device is not an available device type, is the same device, or is not associated with a unit, skip it
      if (!availableDevices.includes(device.basicInfo.deviceType) || devicePublicId === publicId) {
        return;
      }

      if (!targetDeviceUnitPublicId) {
        return;
      }

      // Add every device to the address book if the device is in a guard unit or entrance unit
      if (unitType === 1 || unitType === 2) {
        if (device.basicInfo.deviceType !== 18) {
          addressBookEntries.push(this.getDefaultAddressBookEntry(publicId, sounds));
        } else {
          return;
        }
      }

      // Residential unit type
      if (unitType === 4) {
        if (unitDevices.includes(publicId)) {
          addressBookEntries.push(this.getDefaultAddressBookEntry(publicId, sounds));
        }

        //Add all devices from entrance units and guard units
        if (
          this.unitList[targetDeviceUnitPublicId].unitType === 1 ||
          this.unitList[targetDeviceUnitPublicId].unitType === 2
        ) {
          addressBookEntries.push(this.getDefaultAddressBookEntry(publicId, sounds));
        }
      }

      // Commercial unit type
      if (unitType === 5) {
        if (this.systemType === 2) {
          if (unitDevices.includes(publicId)) {
            addressBookEntries.push(this.getDefaultAddressBookEntry(publicId, sounds));
          }

          //Add all devices from entrance units and guard units
          if (
            this.unitList[targetDeviceUnitPublicId].unitType === 1 ||
            this.unitList[targetDeviceUnitPublicId].unitType === 2
          ) {
            addressBookEntries.push(this.getDefaultAddressBookEntry(publicId, sounds));
          }
        } else {
          addressBookEntries.push(this.getDefaultAddressBookEntry(publicId, sounds));
        }
      }
    });
    if (addressBookEntries.length > 0) {
      return addressBookEntries;
    } else {
      return null;
    }
  }

  /**
   * Adds call settings for a device.
   * @param devicePublicId Public ID of the device to add call settings for.
   * @param unitType Unit type of the device to add call settings for (1-6).
   * @returns Array of call settings.
   */
  private addCallSettings(devicePublicId: string, unitType: number) {
    const devicesThatReceiveCalls = [4, 5, 14, 16];
    const unitPublicId = this.deviceList[devicePublicId].unitPublicId;
    const devicesToAddToCallGroup1: IContactGroupEntry[] = [];

    if (!this.deviceList[devicePublicId].callSettings?.contactGroupList) {
      return;
    }

    const callSettings = {
      contactGroupList: this.deviceList[devicePublicId].callSettings?.contactGroupList,
      deviceContactGroupPublicId: this.deviceList[devicePublicId].callSettings?.contactGroupList['1'].publicId,
      deviceContactGroupPriority: 1
    };

    Object.entries(this.deviceList).forEach(([publicId, device]) => {
      const targetDeviceUnitPublicId = this.deviceList[publicId].unitPublicId;

      // If the device is not associated with a unit, is the same device, or is not a device that receives calls, skip it
      if (!devicesThatReceiveCalls.includes(device.basicInfo.deviceType) || devicePublicId === publicId) {
        return;
      }

      if (unitType === 1 || unitType === 4 || unitType === 5) {
        if (targetDeviceUnitPublicId === unitPublicId) {
          devicesToAddToCallGroup1.push({
            targetDevicePublicId: publicId,
            protocol: 1
          });
        }
      }

      if (unitType === 6) {
        devicesToAddToCallGroup1.push({
          targetDevicePublicId: publicId,
          protocol: 1
        });
      }
    });

    // Assuming callSettings.contactGroupList['1'] is immutable, create a new object for modifications
    const updatedCallSettings = {
      ...callSettings,
      contactGroupList: {
        ...callSettings.contactGroupList,
        '1': {
          ...callSettings.contactGroupList['1'],
          targetList: devicesToAddToCallGroup1 // Assign the new array here
        }
      }
    };

    return updatedCallSettings;
  }

  /**
   * Configures the address book for devices.
   */
  public async configureAddressBook(sounds: ISound[]) {
    const deviceTypesWithAddressBook = [4, 14, 15, 16, 18];

    this.deviceEntries.forEach(([devicePublicId, device]) => {
      if (!device.unitPublicId || !deviceTypesWithAddressBook.includes(device.basicInfo.deviceType)) {
        return;
      }

      const devicePayload = {
        publicId: devicePublicId,
        sitePublicId: this.siteId,
        systemInfo: {
          addressBook: this.addAddressBookEntries(devicePublicId, this.unitList[device.unitPublicId].unitType, sounds)
        }
      };

      if (devicePayload.systemInfo.addressBook !== null) {
        this.payload.push(devicePayload);
      }
    });
  }

  /**
   * Adds devices to IX-RS for a door release.
   * @param devicePublicId Public ID of the device to add for.
   * @param unitType Unit type of the device to add contact Group for (6 - 10).
   * @returns updated contact group and device settings for unlock functionality.
   */
  private addDoorRelease(devicePublicId: UUID, unitType: number) {
    const ACCEPTABLE_DEVICE_TYPES = [4, 5, 8, 9, 10, 11, 14, 15, 16, 20];
    const MAX_DEVICES_PER_GROUP = 20;
    const GROUP_IDS = ['6', '7', '8', '9', '10'];
    const DEFAULT_UUID = '00000000-0000-0000-0000-000000000000';

    const unitPublicId = this.deviceList[devicePublicId].unitPublicId;

    if (!unitPublicId || !this.deviceList[devicePublicId].callSettings?.contactGroupList) {
      return;
    }

    // Get the list of devices associated with the unit
    const unitDevices = this.unitList[unitPublicId].devicePublicIds;

    const devicesToAddToContactGroups: { [key: string]: IContactGroupEntry[] } = GROUP_IDS.reduce((acc, id) => {
      acc[id] = [];
      return acc;
    }, {} as { [key: string]: IContactGroupEntry[] });

    const devicesToAddToUnlockGroups: IUnlockOperationGroupList = GROUP_IDS.reduce((acc, id) => {
      acc[id] = {
        publicId:
          this.deviceList[devicePublicId].deviceSettings?.unlockOperationGroupList?.[id]?.publicId || DEFAULT_UUID,
        deviceList: []
      };
      return acc;
    }, {} as IUnlockOperationGroupList);

    const callSettings = {
      contactGroupList: this.deviceList[devicePublicId].callSettings?.contactGroupList,
      deviceContactGroupPublicId: this.deviceList[devicePublicId].callSettings?.contactGroupList['1'].publicId,
      deviceContactGroupPriority: 1
    };

    const unlockSettings = {
      unlockOperationGroupList: this.deviceList[devicePublicId].deviceSettings?.unlockOperationGroupList || {}
    };

    let currentGroupIdIndex = 0;

    const addDeviceToGroups = (publicId: string) => {
      while (currentGroupIdIndex < GROUP_IDS.length) {
        const groupId = GROUP_IDS[currentGroupIdIndex];
        if (devicesToAddToContactGroups[groupId].length < MAX_DEVICES_PER_GROUP) {
          devicesToAddToContactGroups[groupId].push({ targetDevicePublicId: publicId, protocol: 1 });
          devicesToAddToUnlockGroups[groupId].deviceList.push({ devicePublicId: publicId as UUID, deviceTarget: 2 });
          break;
        } else {
          currentGroupIdIndex++;
        }
      }
    };

    Object.entries(this.deviceList).forEach(([publicId, device]) => {
      const targetDeviceUnitPublicId = device.unitPublicId;

      if (
        !targetDeviceUnitPublicId ||
        !ACCEPTABLE_DEVICE_TYPES.includes(device.basicInfo.deviceType) ||
        devicePublicId === publicId
      ) {
        return;
      }

      // Add every device to the contact group 6 - 10 for Door Release if the device is in a guard unit or entrance unit
      if (unitType === 1 || unitType === 2) {
        if (device.basicInfo.deviceType !== 18) {
          addDeviceToGroups(publicId);
        } else {
          return;
        }
      }

      // Residential unit type
      if (unitType === 4) {
        if (unitDevices.includes(publicId)) {
          addDeviceToGroups(publicId);
        }

        //Add all devices from entrance units and guard units
        if (
          this.unitList[targetDeviceUnitPublicId].unitType === 1 ||
          this.unitList[targetDeviceUnitPublicId].unitType === 2
        ) {
          addDeviceToGroups(publicId);
        }
      }

      // Commercial unit type
      if (unitType === 5) {
        if (this.systemType === 2) {
          if (unitDevices.includes(publicId)) {
            addDeviceToGroups(publicId);
          }

          //Add all devices from entrance units and guard units
          if (
            this.unitList[targetDeviceUnitPublicId].unitType === 1 ||
            this.unitList[targetDeviceUnitPublicId].unitType === 2
          ) {
            addDeviceToGroups(publicId);
          }
        } else {
          addDeviceToGroups(publicId);
        }
      }
    });

    const updatedCallSettings = {
      ...callSettings,
      contactGroupList: GROUP_IDS.reduce(
        (acc, id) => {
          acc[id] = {
            ...callSettings.contactGroupList[id],
            targetList: devicesToAddToContactGroups[id]
          };
          return acc;
        },
        { ...callSettings.contactGroupList }
      )
    };

    const updatedUnlockSettings = {
      ...unlockSettings,
      unlockOperationGroupList: GROUP_IDS.reduce(
        (acc, id) => {
          acc[id] = devicesToAddToUnlockGroups[id];
          return acc;
        },
        { ...unlockSettings.unlockOperationGroupList }
      )
    };

    return { updatedCallSettings, updatedUnlockSettings };
  }

  /**
   * Configures the call settings for devices.
   */
  public async configureCallSettings() {
    const deviceTypesWithCallSettings = [5, 8, 9, 10, 11, 12, 20];

    this.deviceEntries.forEach(([devicePublicId, device]) => {
      if (!device.unitPublicId || !deviceTypesWithCallSettings.includes(device.basicInfo.deviceType)) {
        return;
      }

      const devicePayload = {
        publicId: devicePublicId,
        sitePublicId: this.siteId,
        callSettings: this.addCallSettings(devicePublicId, this.unitList[device.unitPublicId].unitType)
      };

      this.payload.push(devicePayload);
    });
  }

  /**
   * Configures the Door Release settings for devices.
   * @returns The payload for device updates.
   */
  public async configureDoorRelease() {
    const deviceTypesWithDoorRelease = [5];

    // Only devices of type 5 (IX-RS) that belong to a unit will have door release settings configured
    this.deviceEntries.forEach(([devicePublicId, device]) => {
      if (!device.unitPublicId || !deviceTypesWithDoorRelease.includes(device.basicInfo.deviceType)) {
        return;
      }

      const doorReleaseResult = this.addDoorRelease(
        devicePublicId as UUID,
        this.unitList[device.unitPublicId].unitType
      );

      if (doorReleaseResult) {
        const { updatedCallSettings, updatedUnlockSettings } = doorReleaseResult;
        const devicePayload = {
          publicId: devicePublicId,
          sitePublicId: this.siteId,
          callSettings: updatedCallSettings,
          deviceSettings: {
            unlockOperationGroupList: updatedUnlockSettings.unlockOperationGroupList
          }
        };
        this.payload.push(devicePayload);
      }
    });
  }

  /**
   * Gets the payload for device updates.
   * @returns The payload for device updates.
   */
  public getPayload() {
    return { devices: this.payload };
  }
}
