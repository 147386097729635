import { DeviceCategories, DeviceType } from 'features/RemoteManagement/Types';
import { EnumList, fetchLocalEnumList, IDeviceModelValue } from './EnumUtils';
import { DeviceList, IDevice } from 'store/slices/devicesSlice';
import { IUnitList, IUnitState, IUnitTypes } from 'store/slices/unitsSlice';
import { IDevicesToAdd } from 'features/RemoteManagement/NewSiteWizard/steps/AddDevices/AddDevices';
import { MasterStations, DoorStations } from 'features/RemoteManagement/Types';
import { IRingtone } from 'features/RemoteManagement/DeviceDashboard/callSettings/outboundCallSetting/settings/RingBackTone';

export const getMaxStationNumbersMap = (devices: any): { [key: string]: number } => {
  const maxStations: { [key: string]: number } = {};
  if (devices) {
    devices.forEach((device: any) => {
      const firstDigit = device.StationNumber.toString()[0];
      if (!maxStations[firstDigit]) {
        maxStations[firstDigit] = device.StationNumber;
      } else {
        maxStations[firstDigit] = Math.max(maxStations[firstDigit], device.StationNumber);
      }
    });
  }
  return maxStations;
};

interface Device {
  DeviceType: string;
}

export const findDeviceTypeForDevice = (device: Device | string | null): DeviceType | null => {
  if (!device) {
    return null;
  }
  for (const category of DeviceCategories) {
    if (typeof device === 'string') {
      if (category.deviceList.includes(device)) {
        return category;
      }
    } else if (category.deviceList.includes(device.DeviceType)) {
      return category;
    }
  }
  return null;
};

export const getFirmwareModelNameFromDeviceType = (deviceType: number): string | null => {
  const enumList: EnumList = fetchLocalEnumList();

  return enumList.deviceType[deviceType]?.value || null;
};

export const getDeviceCategoryFromDeviceTypeModel = (deviceType: number, deviceModel: number): string | null => {
  const deviceModelCategoryMap: { [key: number]: { [key: number]: string } } = {
    4: {
      1: 'Master Station',
      2: 'Master Station',
      3: 'Master Station',
      4: 'Master Station',
      5: 'Master Station',
      6: 'Master Station'
    },
    5: {
      1: 'Sub Master Station',
      2: 'Sub Master Station'
    },
    6: {
      1: 'IO Adaptor',
      2: 'IO Adaptor',
      5: 'IO Adaptor'
    },
    8: {
      1: 'Video Door Station',
      2: 'Video Door Station',
      3: 'Video Door Station',
      4: 'Video Door Station',
      5: 'Video Door Station',
      6: 'Video Door Station',
      7: 'Video Door Station',
      8: 'Emergency Station',
      9: 'Emergency Station',
      10: 'Video Door Station'
    },
    9: {
      1: 'Audio Door Station',
      2: 'Emergency Station',
      3: 'Emergency Station'
    },
    10: {
      1: 'Audio Door Station',
      2: 'Audio Door Station'
    },
    11: {
      1: 'Video Door Station'
    },
    12: {
      1: 'Video Door Station'
    },
    14: {
      1: 'Tenant Station',
      2: 'Tenant Station'
    },
    15: {
      1: 'Entrance Station'
    },
    16: {
      1: 'Guard Station'
    },
    17: {
      1: 'Lift Control',
      2: 'Lift Control'
    },
    18: {
      1: 'Gateway Adaptor',
      2: 'Gateway Adaptor',
      14: 'Gateway Adaptor'
    },
    20: {
      1: 'Video Door Station'
    }
  };

  return deviceModelCategoryMap[deviceType]?.[deviceModel] || null;
};

export const getDeviceTypeIntFromSearch = (deviceType: string): number | null => {
  const enumList: EnumList = fetchLocalEnumList();
  return (
    parseInt(
      Object.keys(enumList.deviceType).find(
        (key) => (enumList.deviceType[key] as IDeviceModelValue).value === deviceType
      ) || ''
    ) || null
  );
};

export const getDeviceTypeStringFromInt = (deviceType: number): string | null => {
  const enumList: EnumList = fetchLocalEnumList();

  return enumList.deviceType[deviceType]?.value || null;
};

export const generateDefaultCreateDevicePayload = (devicesToAdd: IDevicesToAdd[]): any => {
  const payload: IDevicesToAdd[] = [];

  let masterStationCount = 0;
  let subMasterStationCount = 0;
  let tenantStationCount = 0;
  let videoDoorStationCount = 0;
  let audioDoorStationCount = 0;
  let entranceStationCount = 0;
  let guardStationCount = 0;
  let liftControlCount = 0;
  let gatewayAdaptorCount = 0;
  let ioAdaptorCount = 0;

  const masterStationStartingIndex = 1000;
  const subMasterStationStartingIndex = 2000;
  const tenantStationStartingIndex = 3000;
  const videoDoorStationStartingIndex = 4000;
  const audioDoorStationStartingIndex = 5000;
  const entranceStationStartingIndex = 6000;
  const guardStationStartingIndex = 7000;
  const liftControlStartingIndex = 8000;
  const gatewayAdaptorStartingIndex = 9000;
  const ioAdaptorStartingIndex = 10000;

  devicesToAdd.forEach((device) => {
    switch (device.deviceType) {
      case 4:
        payload.push({
          ...device,
          stationNumber: (device.stationNumber
            ? device.stationNumber
            : masterStationStartingIndex + masterStationCount
          ).toString(),
          stationName: device.stationName + ' ' + (masterStationStartingIndex + masterStationCount)
        });
        masterStationCount++;
        break;
      case 5:
        payload.push({
          ...device,
          stationNumber: (device.stationNumber
            ? device.stationNumber
            : subMasterStationStartingIndex + subMasterStationCount
          ).toString(),
          stationName: device.stationName + ' ' + (subMasterStationStartingIndex + subMasterStationCount)
        });
        subMasterStationCount++;
        break;
      case 14:
        payload.push({
          ...device,
          stationNumber: (device.stationNumber
            ? device.stationNumber
            : tenantStationStartingIndex + tenantStationCount
          ).toString(),
          stationName: device.stationName + ' ' + (tenantStationStartingIndex + tenantStationCount)
        });
        tenantStationCount++;
        break;
      case 8:
      case 11:
      case 12:
      case 20:
        payload.push({
          ...device,
          stationNumber: (device.stationNumber
            ? device.stationNumber
            : videoDoorStationStartingIndex + videoDoorStationCount
          ).toString(),
          stationName: device.stationName + ' ' + (videoDoorStationStartingIndex + videoDoorStationCount)
        });
        videoDoorStationCount++;
        break;
      case 9:
      case 10:
        payload.push({
          ...device,
          stationNumber: (device.stationNumber
            ? device.stationNumber
            : audioDoorStationStartingIndex + audioDoorStationCount
          ).toString(),
          stationName: device.stationName + ' ' + (audioDoorStationStartingIndex + audioDoorStationCount)
        });
        audioDoorStationCount++;
        break;
      case 15:
        payload.push({
          ...device,
          stationNumber: (device.stationNumber
            ? device.stationNumber
            : entranceStationStartingIndex + entranceStationCount
          ).toString(),
          stationName: device.stationName + ' ' + (entranceStationStartingIndex + entranceStationCount)
        });
        entranceStationCount++;
        break;
      case 16:
        payload.push({
          ...device,
          stationNumber: (device.stationNumber
            ? device.stationNumber
            : guardStationStartingIndex + guardStationCount
          ).toString(),
          stationName: device.stationName + ' ' + (guardStationStartingIndex + guardStationCount)
        });
        guardStationCount++;
        break;
      case 17:
        payload.push({
          ...device,
          stationNumber: (device.stationNumber
            ? device.stationNumber
            : liftControlStartingIndex + liftControlCount
          ).toString(),
          stationName: device.stationName + ' ' + (liftControlStartingIndex + liftControlCount)
        });
        liftControlCount++;
        break;
      case 18:
        payload.push({
          ...device,
          stationNumber: (device.stationNumber
            ? device.stationNumber
            : gatewayAdaptorStartingIndex + gatewayAdaptorCount
          ).toString(),
          stationName: device.stationName + ' ' + (gatewayAdaptorStartingIndex + gatewayAdaptorCount)
        });
        gatewayAdaptorCount++;
        break;
      case 6:
        payload.push({
          ...device,
          stationNumber: (device.stationNumber
            ? device.stationNumber
            : ioAdaptorStartingIndex + ioAdaptorCount
          ).toString(),
          stationName: device.stationName + ' ' + (ioAdaptorStartingIndex + ioAdaptorCount)
        });
        ioAdaptorCount++;
        break;
      default:
        break;
    }
  });

  return payload;
};

export const getStationName = (deviceType: string, stationName: string): string => {
  const enumList: EnumList = fetchLocalEnumList();

  if (stationName) {
    return stationName;
  }

  const deviceTypeToStationNameMap: Record<string, string> = {
    [enumList.deviceType['4']?.value]: 'Master Station',
    [enumList.deviceType['5']?.value]: 'Sub Master Station',
    [enumList.deviceType['6']?.value]: 'IO Adaptor',
    [enumList.deviceType['8']?.value]: 'Video Door Station',
    [enumList.deviceType['9']?.value]: 'Audio Door Station',
    [enumList.deviceType['10']?.value]: 'Audio Door Station',
    [enumList.deviceType['11']?.value]: 'Video Door Station',
    [enumList.deviceType['12']?.value]: 'Video Door Station',
    [enumList.deviceType['14']?.value]: 'Tenant Station',
    [enumList.deviceType['15']?.value]: 'Entrance Station',
    [enumList.deviceType['16']?.value]: 'Guard Station',
    [enumList.deviceType['17']?.value]: 'Lift Control',
    [enumList.deviceType['18']?.value]: 'Gateway Adaptor',
    [enumList.deviceType['20']?.value]: 'Video Door Station'
  };

  return deviceTypeToStationNameMap[deviceType] || '';
};

export const getDeviceModelFromString = (deviceModel: string): number | null => {
  const enumList: EnumList = fetchLocalEnumList();

  if (!deviceModel) {
    return null;
  }
  if (deviceModel === 'IXG-DM7-HID(A)') {
    return 1;
  }
  const modelKey =
    Object.keys(enumList.deviceModel).find(
      (key) => (enumList.deviceModel[key] as IDeviceModelValue).value === deviceModel
    ) || '';

  return (enumList.deviceModel[modelKey] as IDeviceModelValue)?.modelNumber || null;
};

/**
 * Returns the device model number based on the device model and device type.
 *
 * @param deviceModel Enum value of the device model.
 * @param deviceType Enum value of the device type. [See Device Types](https://github.com/AiphoneCorporation/remoteProgramming-json/blob/main/README.md)
 * @returns String representation of the device model number.
 *
 */
export const getDeviceModelNumberFromModelType = (deviceModel: number, deviceType: number): string | null => {
  const enumList: EnumList = fetchLocalEnumList();

  const modelKey =
    Object.keys(enumList.deviceModel).find(
      (key) =>
        (enumList.deviceModel[key] as IDeviceModelValue).modelNumber === deviceModel &&
        (enumList.deviceModel[key] as IDeviceModelValue).deviceType === deviceType
    ) || '';

  return enumList.deviceModel[modelKey]?.value || null;
};

export const addSpaceBeforeCapitalLetters = (str: string) => {
  return str.replace(/([A-Z])/g, ' $1').trim();
};

export const getNextIpAddress = (ipAddress: string) => {
  const ipAddressParts = ipAddress.split('.');
  if (ipAddressParts?.length === 4) {
    ipAddressParts[3] = (parseInt(ipAddressParts[3]) + 1).toString();
    return ipAddressParts.join('.');
  }
  return '';
};

// Helper function to format a value as an IP address
export const formatAsIPAddress = (value: any): string => {
  const stringValue = String(value);
  // We need to format like an IP Address
  const octets = stringValue.split('.').map((octet) => parseInt(octet, 10));
  if (octets.length !== 4) return 'Invalid IP Address';
  return octets.map((octet) => (octet > 255 ? 255 : octet < 0 ? 0 : octet)).join('.');
};
/**
 * @function areInputsEmpty
 * @description Check if any of the input fields in Formik forms are empty
 * @param values - object containing the values of the input fields
 * @returns boolean
 */
export const areInputsEmpty = (values: { [key: string]: string }): boolean => {
  return Object.values(values).some((value) => value.trim() === '');
};

/**
 * @function generateUserFriendlyErrorMessageForUsernameOrAlias
 * @description Generate a user-friendly error message for the username or alias field
 * @param error
 * @returns string
 */
export const generateUserFriendlyErrorMessageForUsernameOrAlias = (error: string): string => {
  if (error.includes('userAlias') || error.includes('userName')) {
    // Define a user-friendly error message for the username field
    return 'Please enter a valid username. The username must contain only letters, numbers, and special characters. It cannot contain spaces.';
  }

  return 'Error: Invalid parameters detected.';
};

// check if the outside area unit exist
export const isOutsideUnitExist = (UnitListByType: IUnitTypes) => {
  if (!UnitListByType) {
    return false;
  }
  return UnitListByType['OutsideArea']?.length > 0;
};

// search if the selected door station belongs to the outside unit
export const isPartOfOutsideUnit = (units: IUnitState, unitPublicId: string | undefined) => {
  if (!unitPublicId || !units) {
    return false;
  }

  const unitListByType = units.UnitListByType;
  return unitListByType['OutsideArea']?.includes(unitPublicId ?? '');
};

export const isDeviceWithAddressBook = (device: IDevice) => {
  const deviceTypesWithAddressBook = [4, 14, 16];
  return deviceTypesWithAddressBook.includes(device.basicInfo.deviceType);
};

// filter only the master and subMaster stations type [4, 5, 14, 16]
export const filterMasterAndSubMaster = (deviceList: DeviceList, sortedDeviceList: Array<string>) => {
  if (!sortedDeviceList || !deviceList || Array.isArray(sortedDeviceList) === false) {
    return [];
  }

  return sortedDeviceList?.filter((publicId: string) => {
    if (
      deviceList[publicId]?.basicInfo.deviceType === 4 ||
      deviceList[publicId]?.basicInfo.deviceType === 5 ||
      deviceList[publicId]?.basicInfo.deviceType === 14 ||
      deviceList[publicId]?.basicInfo.deviceType === 16
    ) {
      return publicId;
    }
  });
};

/**
 *
 * @param selectedDevice
 * @param deviceList
 * @param units
 * @returns [answeringStationIds: {}]
 */
export const filterDeviceList = (selectedDevice: IDevice, deviceList: DeviceList, units: IUnitState) => {
  if (!selectedDevice || !units) {
    return [];
  }

  const unitList: IUnitList = units?.UnitList;
  const unitListByType = units?.UnitListByType;
  const unitPublicId = selectedDevice?.unitPublicId || '';

  // if the device belongs to the outside unit, return all the devices in the system
  // else return the devices within the unit

  if (isOutsideUnitExist(unitListByType) && isPartOfOutsideUnit(units, unitPublicId)) {
    return filterMasterAndSubMaster(deviceList, Object.keys(deviceList));
  } else {
    return filterMasterAndSubMaster(deviceList, unitList[unitPublicId]?.devicePublicIds);
  }
};

/**
 *
 * @param soundFiles
 * @param deviceType (example: IX-EA)
 * @returns filtered sound files based on the device type
 */
export const filterSoundsByDeviceType = (soundFiles: IRingtone[], deviceType: string) => {
  if (!Array.isArray(soundFiles) || !deviceType) {
    return [];
  }

  const isMasterStation = MasterStations.includes(deviceType);
  const isDoorStation = DoorStations.includes(deviceType);

  /** No custom sound (#12) for 2C7 by default */
  /** For MVP,  custom sound  NOT provided (#12) where user can upload the file */
  /** The number in the array reference to the sound id that is fetch from DB and also reference by the gateway */
  const defaultSoundFile = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 14, 15, 16, 17, 18];
  const defaultSoundFilesForEA = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 14, 15, 16, 17, 18, 19];
  const customSoundFileForDoorStation = [
    27, 30, 31, 32, 33, 34, 40, 41, 43, 44, 45, 48, 49, 50, 51, 52, 53, 54, 57, 58, 59, 61, 62, 63, 64
  ];
  const customSoundFileForMasterStation = [21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 47, 48, 49, 53, 54, 57];

  if (deviceType === 'IX-EA') {
    const filteredSoundFiles = [...defaultSoundFilesForEA, ...customSoundFileForDoorStation];
    return soundFiles.filter((soundFile: IRingtone) => filteredSoundFiles.includes(soundFile.id));
  }

  if (isMasterStation) {
    const filteredSoundFiles = [...defaultSoundFile, ...customSoundFileForMasterStation];
    return soundFiles.filter((soundFile: IRingtone) => filteredSoundFiles.includes(soundFile.id));
  }

  if (isDoorStation) {
    const filteredSoundFiles = [...defaultSoundFile, ...customSoundFileForDoorStation];
    return soundFiles.filter((soundFile: IRingtone) => filteredSoundFiles.includes(soundFile.id));
  }
};

export const getCallDestinationConfig = (deviceType: string | null) => {
  const defaultCallGroupList = [
    { value: '1', label: 'Call Group 1' },
    { value: '2', label: 'Call Group 2' },
    { value: '3', label: 'Call Group 3' },
    { value: '4', label: 'Call Group 4' },
    { value: '5', label: 'Call Group 5' },
    { value: '6', label: 'Call Group 6' },
    { value: '7', label: 'Call Group 7' },
    { value: '8', label: 'Call Group 8' },
    { value: '9', label: 'Call Group 9' },
    { value: '10', label: 'Call Group 10' }
  ];
  const defaultCallPriorityList = [
    { value: 1, label: 'Normal' },
    { value: 2, label: 'Priority' },
    { value: 3, label: 'Urgent' }
  ];

  switch (deviceType) {
    case 'IX-EA':
    case 'IX-SS-2G':
    case 'IX-DVM':
    case 'IX-RS-B':
    case 'IX-RS-W':
    case 'IX-RS': {
      return {
        contactGroupList: [
          { value: '0', label: 'Call Button' },
          { value: '1', label: 'Contact Input 1' }
        ],
        callGroupList: defaultCallGroupList,
        callPriorityList: defaultCallPriorityList
      };
    }
    case 'IX-DV':
    case 'IX-DVF':
    case 'IX-DVF-HW':
    case 'IX-DVF-L':
    case 'IX-DVF-PR':
    case 'IX-DVF-10KP':
    case 'IX-SSA': {
      return {
        contactGroupList: [
          { value: '0', label: 'Call Button' },
          { value: '1', label: 'Contact Input 1' },
          { value: '2', label: 'Contact Input 2' },
          { value: '3', label: 'Contact Input 3' },
          { value: '4', label: 'Contact Input 4' },
          { value: '5', label: 'Contact Input 5' },
          { value: '6', label: 'Contact Input 6' }
        ],
        callGroupList: defaultCallGroupList,
        callPriorityList: defaultCallPriorityList
      };
    }
    case 'IX-DVF-4A': {
      return {
        contactGroupList: [
          { value: '1', label: 'Contact Input 1' },
          { value: '2', label: 'Contact Input 2' },
          { value: '3', label: 'Contact Input 3' },
          { value: '4', label: 'Contact Input 4' }
        ],
        callGroupList: defaultCallGroupList,
        callPriorityList: defaultCallPriorityList
      };
    }
    case 'IX-DVF-6': {
      return {
        contactGroupList: [
          { value: '1', label: 'Contact Input 1' },
          { value: '2', label: 'Contact Input 2' },
          { value: '3', label: 'Contact Input 3' },
          { value: '4', label: 'Contact Input 4' },
          { value: '5', label: 'Contact Input 5' },
          { value: '6', label: 'Contact Input 6' }
        ],
        callGroupList: defaultCallGroupList,
        callPriorityList: defaultCallPriorityList
      };
    }
    case 'IX-DVF-RA':
    case 'IX-DVF-2RA':
    case 'IX-SSA-RA':
    case 'IX-SSA-2RA': {
      return {
        contactGroupList: [
          { value: '0', label: 'Call Button' },
          { value: '1', label: 'Contact Input 1' },
          { value: '2', label: 'Contact Input 2' },
          { value: '3', label: 'Contact Input 3' },
          { value: '4', label: 'Contact Input 4' },
          { value: '5', label: 'Contact Input 5' },
          { value: '6', label: 'Contact Input 6' }
        ],
        callGroupList: defaultCallGroupList,
        callPriorityList: [
          { value: 3, label: 'Urgent' },
          { value: 1, label: 'Normal' },
          { value: 2, label: 'Priority' }
        ]
      };
    }
    default: {
      return {
        contactGroupList: [{ value: '0', label: 'Call Button' }],
        callGroupList: defaultCallGroupList,
        callPriorityList: defaultCallPriorityList
      };
    }
  }
};

// Debouncing helpers and timing-related functions
/**
 * @function debounce
 *
 * @description Creates a debounced function that delays invoking the provided function until after the specified wait
 * time has elapsed since the last time the debounced function was invoked.
 *
 * @param {Function} func - The function to debounce.
 * @param {number} wait - The number of milliseconds to delay.
 */
export const debounce = <T extends unknown[]>(func: (...args: T) => void, wait: number) => {
  // Removed "any" type
  let timeout: NodeJS.Timeout;
  return (...args: T) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

/**
 * @function asyncDebounce
 *
 * @description Debounces an asynchronous function.
 *
 * @param func Function to debounce
 * @param wait Time to delay in milliseconds
 */
export const asyncDebounce = <Args extends unknown[], T = unknown>(
  func: (...args: Args) => Promise<T>,
  wait: number
): ((...args: Args) => Promise<T>) => {
  let timeout: NodeJS.Timeout;
  return (...args: Args): Promise<T> => {
    clearTimeout(timeout);
    return new Promise((resolve, reject) => {
      timeout = setTimeout(() => {
        func(...args)
          .then(resolve)
          .catch(reject);
      }, wait);
    });
  };
};
/**
 * @function asyncDelayedPromise
 *
 * @description Returns a promise that resolves after a specified delay, executing a callback function.
 *
 * @param {Function} callback The callback function to execute after the delay.
 * @param {number} delay The duration to wait in milliseconds before executing the callback.
 * @returns {Promise} A promise that resolves with the result of the callback function.
 */
export const asyncDelayedPromise = <T,>(callback: () => T, delay: number): Promise<T> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(callback());
    }, delay);
  });
};

/**
 * @function delay
 *
 * @description Delays the execution for a specified duration.
 *
 * @param {number} duration The duration to wait in milliseconds.
 * @returns {Promise<void>} A promise that resolves after the specified duration.
 */
export const delay = (duration: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, duration));
};

/**
 * @function getSerialNumber
 * @description Generate a serial number based on the MAC address
 * @param macAddress The MAC address to generate the serial number from
 * @returns The generated serial number
 */
export const getSerialNumber = (macAddress: string): string => {
  const FNV_PRIME_64 = BigInt('0x100000001b3');
  let hash = BigInt('14695981039346656037');

  for (let i = 0; i < macAddress.length; i++) {
    hash ^= BigInt(macAddress.charCodeAt(i));
    hash = (hash * FNV_PRIME_64) % BigInt('0x10000000000000000'); // Ensure 32-bit overflow
  }

  return hash.toString(16); // Return hash as hexadecimal string
};

/**
 * @function isValidMacAddress
 * @description Validate the MAC address, returning null if there is not an error
 * @param macAddress
 * @param restrictedMACAddresses
 * @param alreadyRegistered
 */
export const isValidMacAddress = (
  macAddress: string,
  restrictedMACAddresses?: string[],
  alreadyRegistered?: { mac_addr: string; isNew?: boolean }[]
): string | null => {
  if (macAddress.length === 0) {
    return 'MAC Address cannot be empty';
  } else if (macAddress.length < 17 || macAddress.length > 17) {
    return 'MAC Address must be 17 characters long';
  }
  const macRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
  if (!macRegex.test(macAddress)) {
    return 'MAC Address must be in the format XX:XX:XX:XX:XX:XX';
  }

  if (restrictedMACAddresses?.includes(macAddress)) {
    return 'MAC Address already registered';
  }
  if (alreadyRegistered && alreadyRegistered.length > 0) {
    const isMacAddressDuplicate = alreadyRegistered.some(
      (restrictedMACAddress) => restrictedMACAddress.mac_addr === macAddress
    );
    if (
      isMacAddressDuplicate &&
      !alreadyRegistered.find((restrictedMACAddress) => restrictedMACAddress.mac_addr === macAddress)?.isNew
    ) {
      return 'MAC Address already registered';
    }
  }
  return null;
};

export const getModelNumberOptions = (deviceType: string) => {
  switch (deviceType) {
    case 'IX-MV7':
      return ['IX-MV7-HB-L', 'IX-MV7-B', 'IX-MV7-W', 'IX-MV7-HB', 'IX-MV7-HW-JP'];
    case 'IX-RS':
      return ['IX-RS-B', 'IX-RS-W'];
    case 'IX-SSA':
      return ['IX-NVP', 'IX-SS-2G', 'IX-SSA', 'IX-SSA-2RA', 'IX-SSA-RA'];
    case 'IX-DV':
      return [
        'IX-DV',
        'IX-DVF',
        'IX-DVF-4A',
        'IX-DVF-6',
        'IX-DVF-HW',
        'IX-DVF-L',
        'IX-DVF-PR',
        'IX-DVF-2RA',
        'IX-DVF-RA',
        'IX-DVF-10KP',
        'IX-DVM',
        'IX-EA'
      ];
    case 'IXG-2C7':
      return ['IXG-2C7', 'IXG-2C7-L'];
    case 'IXG-DM7':
      return ['IXG-DM7-HID(A)'];
    case 'IXG-MK':
      return ['IXG-MK'];
    case 'IXGW-LC':
      return ['IXGW-LC'];
    case 'IXGW-GW':
      return ['IXGW-GW', 'IXGW-TGW'];
    case 'IXW-MA':
      return ['IXW-MA', 'IXW-MAA'];
    default:
      return [];
  }
};

export const isValidIPAddress = (ipAddress: string): string | null => {
  const octets = ipAddress.split('.').map((octet) => parseInt(octet, 10));
  if (octets.length !== 4) return 'Invalid IP Address';
  for (const octet of octets) {
    if (octet < 0 || octet > 255) return 'Invalid IP Address';
  }
  return '';
};
