import { Box, Card, CircularProgress, InputAdornment, MenuItem, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedDevice, updateSelectedDevice } from 'store/slices/devicesSlice';
import * as Yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { Checkbox, Select, TextField } from '@mui/material';
import { Grid } from '@mui/material';
import { useUpdateDeviceMutation } from 'services/aiphoneCloud';
import containerStyle from 'shared/styles/advancedSettingContainerStyle';
import { useEffect, useState } from 'react';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import StringUtils from 'shared/utils/StringUtils';
import { EnumList, IConditionalDefaultValue, fetchEnumList } from 'shared/utils/EnumUtils';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

export const VideoLabel = () => {
  const { t } = useTranslation();
  return (
    <Tooltip title="Video, Multicast, Camera">
      <span>{t('AdvancedSettings_Tab_Video')}</span>
    </Tooltip>
  );
};

const Video = () => {
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [updateDevice, { isLoading: isUpdating }] = useUpdateDeviceMutation();
  const { t } = useTranslation();

  const videoTosValueTitle = t('AdvancedSettings_VideoTosValue_Title');
  const videoTosValueDesc = t('AdvancedSettings_VideoTosValue_Desc');
  const videoEncoder1IPictureIntervalTitle = t('AdvancedSettings_VideoEncoder1IPictureInterval_Title');
  const videoEncoder1IPictureIntervalDesc = t('AdvancedSettings_VideoEncoder1IPictureInterval_Desc');
  const videoEncoder1FramerateTitle = t('AdvancedSettings_VideoEncoder1Framerate_Title');
  const videoEncoder1FramerateDesc = t('AdvancedSettings_VideoEncoder1Framerate_Desc');
  const videoEncoder1BitrateTitle = t('AdvancedSettings_VideoEncoder1Bitrate_Title');
  const videoEncoder1BitrateDesc = t('AdvancedSettings_VideoEncoder1Bitrate_Desc');
  const videoDualStreamTitle = t('AdvancedSettings_VideoDualStream_Title');
  const videoDualStreamDesc = t('AdvancedSettings_VideoDualStream_Desc');
  const videoEncoder2MethodTitle = t('AdvancedSettings_VideoEncoder2Method_Title');
  const videoEncoder2MethodDesc = t('AdvancedSettings_VideoEncoder2Method_Desc');
  const videoEncoder2ResolutionTitle = t('AdvancedSettings_VideoEncoder2Resolution_Title');
  const videoEncoder2ResolutionDesc = t('AdvancedSettings_VideoEncoder2Resolution_Desc');
  const videoEncoder2FramerateTitle = t('AdvancedSettings_VideoEncoder2Framerate_Title');
  const videoEncoder2FramerateDesc = t('AdvancedSettings_VideoEncoder2Framerate_Desc');
  const videoEncoder2H264QualityTitle = t('AdvancedSettings_VideoEncoder2H264Quality_Title');
  const videoEncoder2H264QualityDesc = t('AdvancedSettings_VideoEncoder2H264Quality_Desc');
  const videoEncoder2IPictureIntervalTitle = t('AdvancedSettings_VideoEncoder2IPictureInterval_Title');
  const videoEncoder2IPictureIntervalDesc = t('AdvancedSettings_VideoEncoder2IPictureInterval_Desc');
  const videoEncoder2BitrateTitle = t('AdvancedSettings_VideoEncoder2Bitrate_Title');
  const videoEncoder2BitrateDesc = t('AdvancedSettings_VideoEncoder2Bitrate_Desc');
  const videoEncoder2JpegQualityTitle = t('AdvancedSettings_VideoEncoder2JpegQuality_Title');
  const videoEncoder2JpegQualityDesc = t('AdvancedSettings_VideoEncoder2JpegQuality_Desc');
  const videoEncoder2RtpStartPortTitle = t('AdvancedSettings_VideoEncoder2RtpStartPort_Title');
  const videoEncoder2RtpStartPortDesc = t('AdvancedSettings_VideoEncoder2RtpStartPort_Desc');
  const videoEncoder2RtpEndPortTitle = t('AdvancedSettings_VideoEncoder2RtpEndPort_Title');
  const videoEncoder2RtpEndPortDesc = t('AdvancedSettings_VideoEncoder2RtpEndPort_Desc');

  const masterStationVideoFramerateTitle = t('AdvancedSettings_MasterStationVideoFramerate_Title');
  const masterStationVideoFramerateDesc = t('AdvancedSettings_MasterStationVideoFramerate_Desc');
  const masterStationVideoIPictureIntervalTitle = t('AdvancedSettings_MasterStationVideoIPictureInterval_Title');
  const masterStationVideoIPictureIntervalDesc = t('AdvancedSettings_MasterStationVideoIPictureInterval_Desc');
  const masterStationVideoBitrateTitle = t('AdvancedSettings_MasterStationVideoBitrate_Title');
  const masterStationVideoBitrateDesc = t('AdvancedSettings_MasterStationVideoBitrate_Desc');

  const fieldErrorMin = t('Field_Error_Min');
  const fieldErrorMax = t('Field_Error_Max');
  const fieldErrorRequired = t('Field_Error_Required');
  const fieldErrorNotValid = t('Field_Error_NotValid');

  const buttonSaveChanges = t('Button_SaveChanges');
  const buttonReset = t('Button_Reset');

  const errorUpdateDevice = t('AdvancedSettings_Error_UpdateDevice');
  const unauthorizedUser = t('AdvancedSettings_Unauthorized_User');
  const successUpdateDevice = t('AdvancedSettings_Success_UpdateDevice');

  const tosRegexValidate = /^[0-9a-fA-F]{2}$/;
  const tosRegexOnChange = /^[0-9a-fA-F]{0,2}$/;

  const selectedDevice = useSelector(getSelectedDevice);
  const [enumList, setEnumList] = useState<EnumList>({ country: {}, state: {} });
  const [fetchingEnums, setFetchingEnums] = useState(true);
  const [formikSchema, setFormikSchema] = useState<Yup.ObjectSchema<any> | null>(null);
  const formDevice = {
    networkSettings: {
      videoTosValue: selectedDevice.networkSettings?.videoTosValue.replace('0x', ''),
      videoEncoder1Framerate: selectedDevice.networkSettings?.videoEncoder1Framerate,
      videoEncoder1IPictureInterval: selectedDevice.networkSettings?.videoEncoder1IPictureInterval,
      videoEncoder1Bitrate: selectedDevice.networkSettings?.videoEncoder1Bitrate,
      videoDualStream: selectedDevice.networkSettings?.videoDualStream,
      videoEncoder2Method: selectedDevice.networkSettings?.videoEncoder2Method,
      videoEncoder2Resolution: selectedDevice.networkSettings?.videoEncoder2Resolution,
      videoEncoder2Framerate: selectedDevice.networkSettings?.videoEncoder2Framerate,
      videoEncoder2H264Quality: selectedDevice.networkSettings?.videoEncoder2H264Quality,
      videoEncoder2IPictureInterval: selectedDevice.networkSettings?.videoEncoder2IPictureInterval,
      videoEncoder2Bitrate: selectedDevice.networkSettings?.videoEncoder2Bitrate,
      videoEncoder2JpegQuality: selectedDevice.networkSettings?.videoEncoder2JpegQuality,
      videoEncoder2RtpStartPort: selectedDevice.networkSettings?.videoEncoder2RtpStartPort,
      videoEncoder2RtpEndPort: selectedDevice.networkSettings?.videoEncoder2RtpEndPort,
      masterStationVideoFramerate: selectedDevice.networkSettings?.masterStationVideoFramerate,
      masterStationVideoIPictureInterval: selectedDevice.networkSettings?.masterStationVideoIPictureInterval,
      masterStationVideoBitrate: selectedDevice.networkSettings?.masterStationVideoBitrate
    }
  };

  useEffect(() => {
    fetchEnumList().then((data) => {
      setEnumList(data);
      setFetchingEnums(false);
    });
  }, []);

  const getValidationSchema = (currValues: any) => {
    const networkSchema: any = {};
    let formattedFieldRequired: string;

    if (formDevice.networkSettings.videoTosValue !== null && formDevice.networkSettings.videoTosValue !== undefined) {
      formattedFieldRequired = StringUtils.format(fieldErrorRequired, videoTosValueTitle);
      networkSchema.videoTosValue = Yup.string()
        .required(formattedFieldRequired)
        .matches(tosRegexValidate, fieldErrorNotValid);
    }

    if (
      formDevice.networkSettings.videoEncoder1Framerate !== null &&
      formDevice.networkSettings.videoEncoder1Framerate !== undefined
    ) {
      formattedFieldRequired = StringUtils.format(fieldErrorRequired, videoEncoder1FramerateTitle);
      networkSchema.videoEncoder1Framerate = Yup.string()
        .required(formattedFieldRequired)
        .test('isValidFramerate', fieldErrorNotValid, (value: string) => {
          return Object.keys(enumList.videoEncoderFramerate).includes(value);
        });
    }

    if (
      formDevice.networkSettings.videoEncoder1IPictureInterval !== null &&
      formDevice.networkSettings.videoEncoder1IPictureInterval !== undefined
    ) {
      const bitrateMin = 1;
      const bitrateMax = 100;
      formattedFieldRequired = StringUtils.format(fieldErrorRequired, videoEncoder1IPictureIntervalTitle);
      networkSchema.videoEncoder1IPictureInterval = Yup.number()
        .required(formattedFieldRequired)
        .min(bitrateMin, StringUtils.format(fieldErrorMin, videoEncoder1IPictureIntervalTitle, bitrateMin))
        .max(bitrateMax, StringUtils.format(fieldErrorMax, videoEncoder1IPictureIntervalTitle, bitrateMax));
    }

    if (
      formDevice.networkSettings.videoEncoder1Bitrate !== null &&
      formDevice.networkSettings.videoEncoder1Bitrate !== undefined
    ) {
      formattedFieldRequired = StringUtils.format(fieldErrorRequired, videoEncoder1BitrateTitle);
      networkSchema.videoEncoder1Bitrate = Yup.string()
        .required(formattedFieldRequired)
        .test('isValidBitrate', fieldErrorNotValid, (value: string) => {
          return Object.keys(enumList.videoBitrate).includes(value);
        });
    }

    if (
      formDevice.networkSettings.videoDualStream !== null &&
      formDevice.networkSettings.videoDualStream !== undefined
    ) {
      formattedFieldRequired = StringUtils.format(fieldErrorRequired, videoDualStreamTitle);
      networkSchema.videoDualStream = Yup.boolean().required(formattedFieldRequired);
    }

    if (currValues.networkSettings.videoDualStream) {
      if (
        formDevice.networkSettings.videoEncoder2Method !== null &&
        formDevice.networkSettings.videoEncoder2Method !== undefined
      ) {
        formattedFieldRequired = StringUtils.format(fieldErrorRequired, videoEncoder2MethodTitle);
        networkSchema.videoEncoder2Method = Yup.string()
          .required(formattedFieldRequired)
          .test('isValidMethod', fieldErrorNotValid, (value: string) => {
            return Object.keys(enumList.videoEncoderMethod).includes(value);
          });
      }

      if (
        formDevice.networkSettings.videoEncoder2Resolution !== null &&
        formDevice.networkSettings.videoEncoder2Resolution !== undefined
      ) {
        formattedFieldRequired = StringUtils.format(fieldErrorRequired, videoEncoder2ResolutionTitle);
        networkSchema.videoEncoder2Resolution = Yup.string()
          .required(formattedFieldRequired)
          .test('isValidResolution', fieldErrorNotValid, (value: string) => {
            return Object.keys(enumList.videoEncoderResolution).includes(value);
          });
      }

      if (
        formDevice.networkSettings.videoEncoder2Framerate !== null &&
        formDevice.networkSettings.videoEncoder2Framerate !== undefined
      ) {
        formattedFieldRequired = StringUtils.format(fieldErrorRequired, videoEncoder2FramerateTitle);
        networkSchema.videoEncoder2Framerate = Yup.string()
          .required(formattedFieldRequired)
          .test('isValidFramerate', fieldErrorNotValid, (value: string) => {
            return Object.keys(enumList.videoEncoderFramerate).includes(value);
          });
      }

      if (
        formDevice.networkSettings.videoEncoder2H264Quality !== null &&
        formDevice.networkSettings.videoEncoder2H264Quality !== undefined
      ) {
        formattedFieldRequired = StringUtils.format(fieldErrorRequired, videoEncoder2H264QualityTitle);
        networkSchema.videoEncoder2H264Quality = Yup.string()
          .required(formattedFieldRequired)
          .test('isValidQuality', fieldErrorNotValid, (value: string) => {
            return Object.keys(enumList.videoEncoderQuality).includes(value);
          });
      }

      if (
        formDevice.networkSettings.videoEncoder2IPictureInterval !== null &&
        formDevice.networkSettings.videoEncoder2IPictureInterval !== undefined
      ) {
        const bitrateMin = 1;
        const bitrateMax = 100;
        formattedFieldRequired = StringUtils.format(fieldErrorRequired, videoEncoder2IPictureIntervalTitle);
        networkSchema.videoEncoder2IPictureInterval = Yup.number()
          .required(formattedFieldRequired)
          .min(bitrateMin, StringUtils.format(fieldErrorMin, videoEncoder2IPictureIntervalTitle, bitrateMin))
          .max(bitrateMax, StringUtils.format(fieldErrorMax, videoEncoder2IPictureIntervalTitle, bitrateMax));
      }

      if (
        formDevice.networkSettings.videoEncoder2Bitrate !== null &&
        formDevice.networkSettings.videoEncoder2Bitrate !== undefined
      ) {
        formattedFieldRequired = StringUtils.format(fieldErrorRequired, videoEncoder2BitrateTitle);
        networkSchema.videoEncoder2Bitrate = Yup.string()
          .required(formattedFieldRequired)
          .test('isValidBitrate', fieldErrorNotValid, (value: string) => {
            return Object.keys(enumList.videoBitrate).includes(value);
          });
      }

      if (
        formDevice.networkSettings.videoEncoder2JpegQuality !== null &&
        formDevice.networkSettings.videoEncoder2JpegQuality !== undefined
      ) {
        formattedFieldRequired = StringUtils.format(fieldErrorRequired, videoEncoder2JpegQualityTitle);
        networkSchema.videoEncoder2JpegQuality = Yup.string()
          .required(formattedFieldRequired)
          .test('isValidQuality', fieldErrorNotValid, (value: string) => {
            return Object.keys(enumList.videoEncoderQuality).includes(value);
          });
      }

      if (
        formDevice.networkSettings.videoEncoder2RtpStartPort !== null &&
        formDevice.networkSettings.videoEncoder2RtpStartPort !== undefined
      ) {
        const bitrateMin = 1;
        const bitrateMax = 65535;
        formattedFieldRequired = StringUtils.format(fieldErrorRequired, videoEncoder2RtpStartPortTitle);
        networkSchema.videoEncoder2RtpStartPort = Yup.number()
          .required(formattedFieldRequired)
          .min(bitrateMin, StringUtils.format(fieldErrorMin, videoEncoder2RtpStartPortTitle, bitrateMin))
          .max(bitrateMax, StringUtils.format(fieldErrorMax, videoEncoder2RtpStartPortTitle, bitrateMax));
      }

      if (
        formDevice.networkSettings.videoEncoder2RtpEndPort !== null &&
        formDevice.networkSettings.videoEncoder2RtpEndPort !== undefined
      ) {
        const bitrateMin = 1;
        const bitrateMax = 65535;
        formattedFieldRequired = StringUtils.format(fieldErrorRequired, videoEncoder2RtpEndPortTitle);
        networkSchema.videoEncoder2RtpEndPort = Yup.number()
          .required(formattedFieldRequired)
          .min(bitrateMin, StringUtils.format(fieldErrorMin, videoEncoder2RtpEndPortTitle, bitrateMin))
          .max(bitrateMax, StringUtils.format(fieldErrorMax, videoEncoder2RtpEndPortTitle, bitrateMax));
      }
    }

    if (
      formDevice.networkSettings.masterStationVideoFramerate !== null &&
      formDevice.networkSettings.masterStationVideoFramerate !== undefined
    ) {
      formattedFieldRequired = StringUtils.format(fieldErrorRequired, masterStationVideoFramerateTitle);
      networkSchema.masterStationVideoFramerate = Yup.string()
        .required(formattedFieldRequired)
        .test('isValidFramerate', fieldErrorNotValid, (value: string) => {
          return Object.keys(enumList.videoEncoderFramerate).includes(value);
        });
    }

    if (
      formDevice.networkSettings.masterStationVideoIPictureInterval !== null &&
      formDevice.networkSettings.masterStationVideoIPictureInterval !== undefined
    ) {
      const bitrateMin = 1;
      const bitrateMax = 100;
      formattedFieldRequired = StringUtils.format(fieldErrorRequired, masterStationVideoIPictureIntervalTitle);
      networkSchema.masterStationVideoIPictureInterval = Yup.number()
        .required(formattedFieldRequired)
        .min(bitrateMin, StringUtils.format(fieldErrorMin, masterStationVideoIPictureIntervalTitle, bitrateMin))
        .max(bitrateMax, StringUtils.format(fieldErrorMax, masterStationVideoIPictureIntervalTitle, bitrateMax));
    }

    if (
      formDevice.networkSettings.masterStationVideoBitrate !== null &&
      formDevice.networkSettings.masterStationVideoBitrate !== undefined
    ) {
      formattedFieldRequired = StringUtils.format(fieldErrorRequired, masterStationVideoBitrateTitle);
      networkSchema.masterStationVideoBitrate = Yup.string()
        .required(formattedFieldRequired)
        .test('isValidBitrate', fieldErrorNotValid, (value: string) => {
          return Object.keys(enumList.videoBitrate).includes(value);
        });
    }

    return Yup.object({
      networkSettings: Yup.object().shape(networkSchema)
    });
  };

  const onSubmit = async (values: any, actions: any) => {
    const newVals = JSON.parse(JSON.stringify(values));
    newVals.networkSettings.videoTosValue = `0x${values.networkSettings.videoTosValue.toUpperCase()}`;
    const params = {
      device: {
        publicId: selectedDevice.publicId,
        networkSettings: {
          videoTosValue: newVals.networkSettings.videoTosValue,
          videoEncoder1Framerate: newVals.networkSettings.videoEncoder1Framerate,
          videoEncoder1IPictureInterval: newVals.networkSettings.videoEncoder1IPictureInterval,
          videoEncoder1Bitrate: newVals.networkSettings.videoEncoder1Bitrate,
          videoDualStream: newVals.networkSettings.videoDualStream,
          videoEncoder2Method: newVals.networkSettings.videoDualStream
            ? newVals.networkSettings.videoEncoder2Method
            : null,
          videoEncoder2Resolution: newVals.networkSettings.videoDualStream
            ? newVals.networkSettings.videoEncoder2Resolution
            : null,
          videoEncoder2Framerate: newVals.networkSettings.videoDualStream
            ? newVals.networkSettings.videoEncoder2Framerate
            : null,
          videoEncoder2H264Quality: newVals.networkSettings.videoDualStream
            ? newVals.networkSettings.videoEncoder2H264Quality
            : null,
          videoEncoder2IPictureInterval: newVals.networkSettings.videoDualStream
            ? newVals.networkSettings.videoEncoder2IPictureInterval
            : null,
          videoEncoder2Bitrate: newVals.networkSettings.videoDualStream
            ? newVals.networkSettings.videoEncoder2Bitrate
            : null,
          videoEncoder2JpegQuality: newVals.networkSettings.videoDualStream
            ? newVals.networkSettings.videoEncoder2JpegQuality
            : null,
          videoEncoder2RtpStartPort: newVals.networkSettings.videoDualStream
            ? newVals.networkSettings.videoEncoder2RtpStartPort
            : null,
          videoEncoder2RtpEndPort: newVals.networkSettings.videoDualStream
            ? newVals.networkSettings.videoEncoder2RtpEndPort
            : null,
          masterStationVideoFramerate: newVals.networkSettings.masterStationVideoFramerate,
          masterStationVideoIPictureInterval: newVals.networkSettings.masterStationVideoIPictureInterval,
          masterStationVideoBitrate: newVals.networkSettings.masterStationVideoBitrate
        }
      }
    };

    const newDevice = JSON.parse(JSON.stringify(selectedDevice));

    newDevice.networkSettings = {
      ...newDevice.networkSettings,
      ...newVals.networkSettings
    };

    updateDevice(params)
      .then((response) => {
        if ('error' in response) {
          throw response.error;
        }

        dispatch(updateSelectedDevice({ device: newDevice }));
        setShowAlert(true);
        actions.resetForm({
          values: values
        });
      })
      .catch((error: any) => {
        const err = JSON.parse(error.data);
        if (err.errorDetails.includes('Unauthorized user Id')) {
          setErrorMessage(unauthorizedUser);
        } else {
          setErrorMessage(errorUpdateDevice);
        }
      });
  };

  if (!fetchingEnums && !formikSchema) {
    setFormikSchema(getValidationSchema(formDevice));
  }

  return (
    <>
      <Box sx={containerStyle.mainWrapper}>
        <SnackbarAlert
          type="error"
          time={10000}
          text={`${errorMessage}`}
          isOpen={!!errorMessage}
          onClose={() => setErrorMessage(null)}
        />
        <SnackbarAlert
          type="success"
          time={3000}
          text={successUpdateDevice}
          isOpen={showAlert}
          onClose={() => setShowAlert(false)}
        />
        {fetchingEnums ? (
          <CircularProgress />
        ) : (
          <Formik initialValues={formDevice} onSubmit={onSubmit} validationSchema={formikSchema}>
            {({ values, dirty, touched, errors, isSubmitting, handleChange }) => (
              <Form style={containerStyle.form}>
                {' '}
                <Box sx={containerStyle.controlPanelWrapper}>
                  <LoadingButton variant="outlined" type="reset" disabled={!dirty || isSubmitting || isUpdating}>
                    {buttonReset}
                  </LoadingButton>
                  <LoadingButton
                    variant="outlined"
                    loading={isSubmitting}
                    type="submit"
                    disabled={!dirty || isSubmitting || isUpdating}
                  >
                    {buttonSaveChanges}
                  </LoadingButton>
                </Box>
                <Card sx={containerStyle.settingsWrapper}>
                  <Box sx={containerStyle.gridContainer}>
                    {formDevice.networkSettings.videoTosValue !== null &&
                    formDevice.networkSettings.videoTosValue !== undefined ? (
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        style={containerStyle.itemContainer}
                      >
                        <Grid item xs={5} lg={7}>
                          <Box sx={containerStyle.itemTitle}>{videoTosValueTitle}</Box>
                          <Box>{videoTosValueDesc}</Box>
                        </Grid>
                        <Grid item xs={5} lg={3}>
                          <Box sx={containerStyle.fieldContainer}>
                            <Field
                              as={TextField}
                              type="text"
                              label={videoTosValueTitle}
                              name="networkSettings.videoTosValue"
                              style={containerStyle.textField}
                              helperText={
                                touched.networkSettings?.videoTosValue && errors.networkSettings?.videoTosValue
                              }
                              error={touched.networkSettings?.videoTosValue && errors.networkSettings?.videoTosValue}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start" sx={containerStyle.tosAdornment}>
                                    0x
                                  </InputAdornment>
                                )
                              }}
                              onChange={(e: any) => {
                                // Only allow valid values
                                if (tosRegexOnChange.test(e.target.value)) {
                                  handleChange(e);
                                }
                              }}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    ) : null}
                    {formDevice.networkSettings.videoEncoder1Framerate !== null &&
                    formDevice.networkSettings.videoEncoder1Framerate !== undefined ? (
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        style={containerStyle.itemContainer}
                      >
                        <Grid item xs={5} lg={7}>
                          <Box sx={containerStyle.itemTitle}>{videoEncoder1FramerateTitle}</Box>
                          <Box>{videoEncoder1FramerateDesc}</Box>
                        </Grid>
                        <Grid item xs={5} lg={3}>
                          <Box sx={containerStyle.fieldContainer}>
                            <Field
                              as={Select}
                              label={videoEncoder1FramerateTitle}
                              name="networkSettings.videoEncoder1Framerate"
                              style={containerStyle.selectField}
                              helperText={
                                touched.networkSettings?.videoEncoder1Framerate &&
                                errors.networkSettings?.videoEncoder1Framerate
                              }
                              error={
                                touched.networkSettings?.videoEncoder1Framerate &&
                                errors.networkSettings?.videoEncoder1Framerate
                              }
                            >
                              {Object.keys(enumList.videoEncoderFramerate).map((key) => {
                                return !(enumList.videoEncoderFramerate[key] as IConditionalDefaultValue)
                                  .supportedDeviceTypeList ||
                                  (
                                    enumList.videoEncoderFramerate[key] as IConditionalDefaultValue
                                  ).supportedDeviceTypeList.includes(selectedDevice.basicInfo.deviceType) ? (
                                  <MenuItem key={key} value={key}>
                                    {enumList.videoEncoderFramerate[key].value}
                                  </MenuItem>
                                ) : null;
                              })}
                            </Field>
                          </Box>
                        </Grid>
                      </Grid>
                    ) : null}
                    {formDevice.networkSettings.videoEncoder1IPictureInterval !== null &&
                    formDevice.networkSettings.videoEncoder1IPictureInterval !== undefined ? (
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        style={containerStyle.itemContainer}
                      >
                        <Grid item xs={5} lg={7}>
                          <Box sx={containerStyle.itemTitle}>{videoEncoder1IPictureIntervalTitle}</Box>
                          <Box>{videoEncoder1IPictureIntervalDesc}</Box>
                        </Grid>
                        <Grid item xs={5} lg={3}>
                          <Box sx={containerStyle.fieldContainer}>
                            <Field
                              as={TextField}
                              type="number"
                              label={videoEncoder1IPictureIntervalTitle}
                              name="networkSettings.videoEncoder1IPictureInterval"
                              style={containerStyle.textField}
                              helperText={
                                touched.networkSettings?.videoEncoder1IPictureInterval &&
                                errors.networkSettings?.videoEncoder1IPictureInterval
                              }
                              error={
                                touched.networkSettings?.videoEncoder1IPictureInterval &&
                                errors.networkSettings?.videoEncoder1IPictureInterval
                              }
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    ) : null}
                    {formDevice.networkSettings.videoEncoder1Bitrate !== null &&
                    formDevice.networkSettings.videoEncoder1Bitrate !== undefined ? (
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        style={containerStyle.itemContainer}
                      >
                        <Grid item xs={5} lg={7}>
                          <Box sx={containerStyle.itemTitle}>{videoEncoder1BitrateTitle}</Box>
                          <Box>{videoEncoder1BitrateDesc}</Box>
                        </Grid>
                        <Grid item xs={5} lg={3}>
                          <Box sx={containerStyle.fieldContainer}>
                            <Field
                              as={Select}
                              label={videoEncoder1BitrateTitle}
                              name="networkSettings.videoEncoder1Bitrate"
                              style={containerStyle.textField}
                              helperText={
                                touched.networkSettings?.videoEncoder1Bitrate &&
                                errors.networkSettings?.videoEncoder1Bitrate
                              }
                              error={
                                touched.networkSettings?.videoEncoder1Bitrate &&
                                errors.networkSettings?.videoEncoder1Bitrate
                              }
                            >
                              {Object.keys(enumList.videoBitrate).map((key) => {
                                return (
                                  <MenuItem key={key} value={key}>
                                    {enumList.videoBitrate[key].value}
                                  </MenuItem>
                                );
                              })}
                            </Field>
                          </Box>
                        </Grid>
                      </Grid>
                    ) : null}
                    {formDevice.networkSettings.videoDualStream !== null &&
                    formDevice.networkSettings.videoDualStream !== undefined ? (
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        style={containerStyle.itemContainer}
                      >
                        <Grid item xs={5} lg={7}>
                          <Box sx={containerStyle.itemTitle}>{videoDualStreamTitle}</Box>
                          <Box>{videoDualStreamDesc}</Box>
                        </Grid>
                        <Grid item xs={5} lg={3}>
                          <Box sx={containerStyle.fieldContainer}>
                            <Field
                              as={Checkbox}
                              label={videoDualStreamTitle}
                              name="networkSettings.videoDualStream"
                              style={containerStyle.textField}
                              helperText={
                                touched.networkSettings?.videoDualStream && errors.networkSettings?.videoDualStream
                              }
                              error={
                                touched.networkSettings?.videoDualStream && errors.networkSettings?.videoDualStream
                              }
                              onChange={(e: any) => {
                                const newVals = { ...values };
                                newVals.networkSettings.videoDualStream = e.target.checked;
                                handleChange(e);
                                setFormikSchema(getValidationSchema(newVals));
                              }}
                              checked={values.networkSettings.videoDualStream}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    ) : null}
                    {values.networkSettings.videoDualStream ? (
                      <>
                        {formDevice.networkSettings.videoEncoder2Method !== null &&
                        formDevice.networkSettings.videoEncoder2Method !== undefined ? (
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-evenly"
                            style={containerStyle.itemContainer}
                          >
                            <Grid item xs={5} lg={7}>
                              <Box sx={containerStyle.itemTitle}>{videoEncoder2MethodTitle}</Box>
                              <Box>{videoEncoder2MethodDesc}</Box>
                            </Grid>
                            <Grid item xs={5} lg={3}>
                              <Box sx={containerStyle.fieldContainer}>
                                <Field
                                  as={Select}
                                  label={videoEncoder2MethodTitle}
                                  name="networkSettings.videoEncoder2Method"
                                  style={containerStyle.textField}
                                  helperText={
                                    touched.networkSettings?.videoEncoder2Method &&
                                    errors.networkSettings?.videoEncoder2Method
                                  }
                                  error={
                                    touched.networkSettings?.videoEncoder2Method &&
                                    errors.networkSettings?.videoEncoder2Method
                                  }
                                >
                                  {Object.keys(enumList.videoEncoderMethod).map((key) => {
                                    return (
                                      <MenuItem key={key} value={key}>
                                        {enumList.videoEncoderMethod[key].value}
                                      </MenuItem>
                                    );
                                  })}
                                </Field>
                              </Box>
                            </Grid>
                          </Grid>
                        ) : null}
                        {formDevice.networkSettings.videoEncoder2Resolution !== null &&
                        formDevice.networkSettings.videoEncoder2Resolution !== undefined ? (
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-evenly"
                            style={containerStyle.itemContainer}
                          >
                            <Grid item xs={5} lg={7}>
                              <Box sx={containerStyle.itemTitle}>{videoEncoder2ResolutionTitle}</Box>
                              <Box>{videoEncoder2ResolutionDesc}</Box>
                            </Grid>
                            <Grid item xs={5} lg={3}>
                              <Box sx={containerStyle.fieldContainer}>
                                <Field
                                  as={Select}
                                  label={videoEncoder2ResolutionTitle}
                                  name="networkSettings.videoEncoder2Resolution"
                                  style={containerStyle.textField}
                                  helperText={
                                    touched.networkSettings?.videoEncoder2Resolution &&
                                    errors.networkSettings?.videoEncoder2Resolution
                                  }
                                  error={
                                    touched.networkSettings?.videoEncoder2Resolution &&
                                    errors.networkSettings?.videoEncoder2Resolution
                                  }
                                >
                                  {Object.keys(enumList.videoEncoderResolution).map((key) => {
                                    return (
                                      <MenuItem key={key} value={key}>
                                        {enumList.videoEncoderResolution[key].value}
                                      </MenuItem>
                                    );
                                  })}
                                </Field>
                              </Box>
                            </Grid>
                          </Grid>
                        ) : null}
                        {formDevice.networkSettings.videoEncoder2Framerate !== null &&
                        formDevice.networkSettings.videoEncoder2Framerate !== undefined ? (
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-evenly"
                            style={containerStyle.itemContainer}
                          >
                            <Grid item xs={5} lg={7}>
                              <Box sx={containerStyle.itemTitle}>{videoEncoder2FramerateTitle}</Box>
                              <Box>{videoEncoder2FramerateDesc}</Box>
                            </Grid>
                            <Grid item xs={5} lg={3}>
                              <Box sx={containerStyle.fieldContainer}>
                                <Field
                                  as={Select}
                                  label={videoEncoder2FramerateTitle}
                                  name="networkSettings.videoEncoder2Framerate"
                                  style={containerStyle.textField}
                                  helperText={
                                    touched.networkSettings?.videoEncoder2Framerate &&
                                    errors.networkSettings?.videoEncoder2Framerate
                                  }
                                  error={
                                    touched.networkSettings?.videoEncoder2Framerate &&
                                    errors.networkSettings?.videoEncoder2Framerate
                                  }
                                >
                                  {Object.keys(enumList.videoEncoderFramerate).map((key) => {
                                    return !(enumList.videoEncoderFramerate[key] as IConditionalDefaultValue)
                                      .supportedDeviceTypeList ||
                                      (
                                        enumList.videoEncoderFramerate[key] as IConditionalDefaultValue
                                      ).supportedDeviceTypeList.includes(selectedDevice.basicInfo.deviceType) ? (
                                      <MenuItem key={key} value={key}>
                                        {enumList.videoEncoderFramerate[key].value}
                                      </MenuItem>
                                    ) : null;
                                  })}
                                </Field>
                              </Box>
                            </Grid>
                          </Grid>
                        ) : null}
                        {formDevice.networkSettings.videoEncoder2H264Quality !== null &&
                        formDevice.networkSettings.videoEncoder2H264Quality !== undefined ? (
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-evenly"
                            style={containerStyle.itemContainer}
                          >
                            <Grid item xs={5} lg={7}>
                              <Box sx={containerStyle.itemTitle}>{videoEncoder2H264QualityTitle}</Box>
                              <Box>{videoEncoder2H264QualityDesc}</Box>
                            </Grid>
                            <Grid item xs={5} lg={3}>
                              <Box sx={containerStyle.fieldContainer}>
                                <Field
                                  as={Select}
                                  label={videoEncoder2H264QualityTitle}
                                  name="networkSettings.videoEncoder2H264Quality"
                                  style={containerStyle.textField}
                                  helperText={
                                    touched.networkSettings?.videoEncoder2H264Quality &&
                                    errors.networkSettings?.videoEncoder2H264Quality
                                  }
                                  error={
                                    touched.networkSettings?.videoEncoder2H264Quality &&
                                    errors.networkSettings?.videoEncoder2H264Quality
                                  }
                                >
                                  {Object.keys(enumList.videoEncoderQuality).map((key) => {
                                    return (
                                      <MenuItem key={key} value={key}>
                                        {enumList.videoEncoderQuality[key].value}
                                      </MenuItem>
                                    );
                                  })}
                                </Field>
                              </Box>
                            </Grid>
                          </Grid>
                        ) : null}
                        {formDevice.networkSettings.videoEncoder2IPictureInterval !== null &&
                        formDevice.networkSettings.videoEncoder2IPictureInterval !== undefined ? (
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-evenly"
                            style={containerStyle.itemContainer}
                          >
                            <Grid item xs={5} lg={7}>
                              <Box sx={containerStyle.itemTitle}>{videoEncoder2IPictureIntervalTitle}</Box>
                              <Box>{videoEncoder2IPictureIntervalDesc}</Box>
                            </Grid>
                            <Grid item xs={5} lg={3}>
                              <Box sx={containerStyle.fieldContainer}>
                                <Field
                                  as={TextField}
                                  type="number"
                                  label={videoEncoder2IPictureIntervalTitle}
                                  name="networkSettings.videoEncoder2IPictureInterval"
                                  style={containerStyle.textField}
                                  helperText={
                                    touched.networkSettings?.videoEncoder2IPictureInterval &&
                                    errors.networkSettings?.videoEncoder2IPictureInterval
                                  }
                                  error={
                                    touched.networkSettings?.videoEncoder2IPictureInterval &&
                                    errors.networkSettings?.videoEncoder2IPictureInterval
                                  }
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        ) : null}
                        {formDevice.networkSettings.videoEncoder2Bitrate !== null &&
                        formDevice.networkSettings.videoEncoder2Bitrate !== undefined ? (
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-evenly"
                            style={containerStyle.itemContainer}
                          >
                            <Grid item xs={5} lg={7}>
                              <Box sx={containerStyle.itemTitle}>{videoEncoder2BitrateTitle}</Box>
                              <Box>{videoEncoder2BitrateDesc}</Box>
                            </Grid>
                            <Grid item xs={5} lg={3}>
                              <Box sx={containerStyle.fieldContainer}>
                                <Field
                                  as={Select}
                                  label={videoEncoder2BitrateTitle}
                                  name="networkSettings.videoEncoder2Bitrate"
                                  style={containerStyle.textField}
                                  helperText={
                                    touched.networkSettings?.videoEncoder2Bitrate &&
                                    errors.networkSettings?.videoEncoder2Bitrate
                                  }
                                  error={
                                    touched.networkSettings?.videoEncoder2Bitrate &&
                                    errors.networkSettings?.videoEncoder2Bitrate
                                  }
                                >
                                  {Object.keys(enumList.videoBitrate).map((key) => {
                                    return (
                                      <MenuItem key={key} value={key}>
                                        {enumList.videoBitrate[key].value}
                                      </MenuItem>
                                    );
                                  })}
                                </Field>
                              </Box>
                            </Grid>
                          </Grid>
                        ) : null}
                        {formDevice.networkSettings.videoEncoder2JpegQuality !== null &&
                        formDevice.networkSettings.videoEncoder2JpegQuality !== undefined ? (
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-evenly"
                            style={containerStyle.itemContainer}
                          >
                            <Grid item xs={5} lg={7}>
                              <Box sx={containerStyle.itemTitle}>{videoEncoder2JpegQualityTitle}</Box>
                              <Box>{videoEncoder2JpegQualityDesc}</Box>
                            </Grid>
                            <Grid item xs={5} lg={3}>
                              <Box sx={containerStyle.fieldContainer}>
                                <Field
                                  as={Select}
                                  label={videoEncoder2JpegQualityTitle}
                                  name="networkSettings.videoEncoder2JpegQuality"
                                  style={containerStyle.textField}
                                  helperText={
                                    touched.networkSettings?.videoEncoder2JpegQuality &&
                                    errors.networkSettings?.videoEncoder2JpegQuality
                                  }
                                  error={
                                    touched.networkSettings?.videoEncoder2JpegQuality &&
                                    errors.networkSettings?.videoEncoder2JpegQuality
                                  }
                                >
                                  {Object.keys(enumList.videoEncoderQuality).map((key) => {
                                    return (
                                      <MenuItem key={key} value={key}>
                                        {enumList.videoEncoderQuality[key].value}
                                      </MenuItem>
                                    );
                                  })}
                                </Field>
                              </Box>
                            </Grid>
                          </Grid>
                        ) : null}
                        {formDevice.networkSettings.videoEncoder2RtpStartPort !== null &&
                        formDevice.networkSettings.videoEncoder2RtpStartPort !== undefined ? (
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-evenly"
                            style={containerStyle.itemContainer}
                          >
                            <Grid item xs={5} lg={7}>
                              <Box sx={containerStyle.itemTitle}>{videoEncoder2RtpStartPortTitle}</Box>
                              <Box>{videoEncoder2RtpStartPortDesc}</Box>
                            </Grid>
                            <Grid item xs={5} lg={3}>
                              <Box sx={containerStyle.fieldContainer}>
                                <Field
                                  as={TextField}
                                  type="number"
                                  label={videoEncoder2RtpStartPortTitle}
                                  name="networkSettings.videoEncoder2RtpStartPort"
                                  style={containerStyle.textField}
                                  helperText={
                                    touched.networkSettings?.videoEncoder2RtpStartPort &&
                                    errors.networkSettings?.videoEncoder2RtpStartPort
                                  }
                                  error={
                                    touched.networkSettings?.videoEncoder2RtpStartPort &&
                                    errors.networkSettings?.videoEncoder2RtpStartPort
                                  }
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        ) : null}
                        {formDevice.networkSettings.videoEncoder2RtpEndPort !== null &&
                        formDevice.networkSettings.videoEncoder2RtpEndPort !== undefined ? (
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-evenly"
                            style={containerStyle.itemContainer}
                          >
                            <Grid item xs={5} lg={7}>
                              <Box sx={containerStyle.itemTitle}>{videoEncoder2RtpEndPortTitle}</Box>
                              <Box>{videoEncoder2RtpEndPortDesc}</Box>
                            </Grid>
                            <Grid item xs={5} lg={3}>
                              <Box sx={containerStyle.fieldContainer}>
                                <Field
                                  as={TextField}
                                  type="number"
                                  label={videoEncoder2RtpEndPortTitle}
                                  name="networkSettings.videoEncoder2RtpEndPort"
                                  style={containerStyle.textField}
                                  helperText={
                                    touched.networkSettings?.videoEncoder2RtpEndPort &&
                                    errors.networkSettings?.videoEncoder2RtpEndPort
                                  }
                                  error={
                                    touched.networkSettings?.videoEncoder2RtpEndPort &&
                                    errors.networkSettings?.videoEncoder2RtpEndPort
                                  }
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        ) : null}
                      </>
                    ) : null}
                    {formDevice.networkSettings.masterStationVideoFramerate !== null &&
                    formDevice.networkSettings.masterStationVideoFramerate !== undefined ? (
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        style={containerStyle.itemContainer}
                      >
                        <Grid item xs={5} lg={7}>
                          <Box sx={containerStyle.itemTitle}>{masterStationVideoFramerateTitle}</Box>
                          <Box>{masterStationVideoFramerateDesc}</Box>
                        </Grid>
                        <Grid item xs={5} lg={3}>
                          <Box sx={containerStyle.fieldContainer}>
                            <Field
                              as={Select}
                              label={masterStationVideoFramerateTitle}
                              name="networkSettings.masterStationVideoFramerate"
                              style={containerStyle.textField}
                              helperText={
                                touched.networkSettings?.masterStationVideoFramerate &&
                                errors.networkSettings?.masterStationVideoFramerate
                              }
                              error={
                                touched.networkSettings?.masterStationVideoFramerate &&
                                errors.networkSettings?.masterStationVideoFramerate
                              }
                            >
                              {Object.keys(enumList.videoEncoderFramerate).map((key) => {
                                return !(enumList.videoEncoderFramerate[key] as IConditionalDefaultValue)
                                  .supportedDeviceTypeList ||
                                  (
                                    enumList.videoEncoderFramerate[key] as IConditionalDefaultValue
                                  ).supportedDeviceTypeList.includes(selectedDevice.basicInfo.deviceType) ? (
                                  <MenuItem key={key} value={key}>
                                    {enumList.videoEncoderFramerate[key].value}
                                  </MenuItem>
                                ) : null;
                              })}
                            </Field>
                          </Box>
                        </Grid>
                      </Grid>
                    ) : null}
                    {formDevice.networkSettings.masterStationVideoIPictureInterval !== null &&
                    formDevice.networkSettings.masterStationVideoIPictureInterval !== undefined ? (
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        style={containerStyle.itemContainer}
                      >
                        <Grid item xs={5} lg={7}>
                          <Box sx={containerStyle.itemTitle}>{masterStationVideoIPictureIntervalTitle}</Box>
                          <Box>{masterStationVideoIPictureIntervalDesc}</Box>
                        </Grid>
                        <Grid item xs={5} lg={3}>
                          <Box sx={containerStyle.fieldContainer}>
                            <Field
                              as={TextField}
                              type="number"
                              label={masterStationVideoIPictureIntervalTitle}
                              name="networkSettings.masterStationVideoIPictureInterval"
                              style={containerStyle.textField}
                              helperText={
                                touched.networkSettings?.masterStationVideoIPictureInterval &&
                                errors.networkSettings?.masterStationVideoIPictureInterval
                              }
                              error={
                                touched.networkSettings?.masterStationVideoIPictureInterval &&
                                errors.networkSettings?.masterStationVideoIPictureInterval
                              }
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    ) : null}
                    {formDevice.networkSettings.masterStationVideoBitrate !== null &&
                    formDevice.networkSettings.masterStationVideoBitrate !== undefined ? (
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        style={containerStyle.itemContainer}
                      >
                        <Grid item xs={5} lg={7}>
                          <Box sx={containerStyle.itemTitle}>{masterStationVideoBitrateTitle}</Box>
                          <Box>{masterStationVideoBitrateDesc}</Box>
                        </Grid>
                        <Grid item xs={5} lg={3}>
                          <Box sx={containerStyle.fieldContainer}>
                            <Field
                              as={Select}
                              label={masterStationVideoBitrateTitle}
                              name="networkSettings.masterStationVideoBitrate"
                              style={containerStyle.textField}
                              helperText={
                                touched.networkSettings?.masterStationVideoBitrate &&
                                errors.networkSettings?.masterStationVideoBitrate
                              }
                              error={
                                touched.networkSettings?.masterStationVideoBitrate &&
                                errors.networkSettings?.masterStationVideoBitrate
                              }
                            >
                              {Object.keys(enumList.videoBitrate).map((key) => {
                                return (
                                  <MenuItem key={key} value={key}>
                                    {enumList.videoBitrate[key].value}
                                  </MenuItem>
                                );
                              })}
                            </Field>
                          </Box>
                        </Grid>
                      </Grid>
                    ) : null}
                  </Box>
                </Card>
              </Form>
            )}
          </Formik>
        )}
      </Box>
    </>
  );
};

export default Video;
