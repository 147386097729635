import { FC } from 'react';
import containerStyle from '../../../../../../shared/styles/advancedSettingContainerStyle';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useField, useFormikContext } from 'formik';
import { CustomSlider } from '../CustomSlider';
import { IVolumeProps } from '../../Volume';

export const VolumeIncomingSpeaker: FC<IVolumeProps> = ({ defaultValue, isDevice2C7 }) => {
  const { t } = useTranslation(); // Initialize the translation hook
  const { setFieldValue } = useFormikContext();

  const volumeType = 'volumeIncomingSpeaker';

  const receiveVolumeSpeakerTitle = t(
    isDevice2C7
      ? 'Advanced_Settings_Receive_Volume_Speaker_2C7_Title'
      : 'Advanced_Settings_Receive_Volume_Speaker_Title'
  );
  const receiveVolumeSpeakerDesc = t(
    isDevice2C7 ? 'Advanced_Settings_Receive_Volume_Speaker_2C7_Desc' : 'Advanced_Settings_Receive_Volume_Speaker_Desc'
  );

  /* Validation */
  const [, volumeIncomingSpeakerMeta] = useField(volumeType);

  /* Handlers */
  const handleChange = async (value: number): Promise<void> => {
    await setFieldValue(volumeType, value);
  };

  /* If the device does not support this feature, return null */
  if (defaultValue === undefined) {
    return null;
  }

  return (
    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
      <Grid item xs={6} lg={7}>
        <div style={containerStyle.itemTitle}>{receiveVolumeSpeakerTitle}</div>
        <div style={containerStyle.itemDesc}>{receiveVolumeSpeakerDesc}</div>
      </Grid>
      <Grid item xs={6} lg={4} justifyContent="flex-end">
        <CustomSlider
          label={receiveVolumeSpeakerTitle}
          name={volumeType}
          min={1}
          max={10}
          step={1}
          onChange={handleChange}
          defaultValue={defaultValue}
          helperText={!!(volumeIncomingSpeakerMeta.touched && volumeIncomingSpeakerMeta.error)}
          styles={containerStyle.sliderBar}
        />
      </Grid>
    </Grid>
  );
};
