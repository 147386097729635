import { getString } from 'shared/utils/LocalizationUtils';

/* Relay Output Lift tab stub */
export const RelayOutputLiftLabel = () => {
  const relayOutput = getString('Relay_Output');
  return <span>{relayOutput}</span>;
};

const RelayOutputLift = RelayOutputLiftLabel;

export default RelayOutputLift;
