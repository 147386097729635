import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { Field, Form, Formik, FormikErrors, FormikProps, FormikTouched } from 'formik';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  useCreateUnitMutation,
  useLazyGetBuildingsQuery,
  useLazyGetUnitListWithSitePublicIdQuery
} from 'services/aiphoneCloud';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import { RootState } from 'store';
import * as yup from 'yup';

interface IAddUnitDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}
interface IUnitOption {
  unit: {
    name: string;
    type: number;
    description: string;
  };
}

interface IUnitDetailsProps {
  formikProps: FormikProps<any>;
}

const UnitOption = ({ unit }: IUnitOption) => {
  const { name, description } = unit;

  return (
    <>
      <Box>
        <Typography variant="body1">{name}</Typography>
        <Box sx={styles.unitDescriptionWrapper}>
          <Typography variant="body2">{description}</Typography>
        </Box>
      </Box>
    </>
  );
};

const AddUnitDialog = ({ isOpen, setIsOpen }: IAddUnitDialogProps) => {
  const [selectedUnit, setSelectedUnit] = useState(2);
  const [currentStep, setCurrentStep] = useState(1);
  const [successMessage] = useState('Unit added successfully');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccessAlertOpen, setIsSuccessAlertOpen] = useState(false);
  const [isErrorAlertOpen, setIsErrorAlertOpen] = useState(false);
  const [isBatchAddUnit, setIsBatchAddUnit] = useState(false);
  const buildingList = useSelector((state: RootState) => state.buildings.BuildingList);
  const buildingOptions = Object.entries(buildingList)
    .map(([key, building]) => {
      return { key, value: building.buildingNumber };
    })
    .sort((a, b) => a.value.localeCompare(b.value));
  const sitePublicId = useParams().id;
  const unitList = useSelector((state: RootState) => state.units.UnitList);
  const [createUnit, { isLoading }] = useCreateUnitMutation();
  const [fetchUnits] = useLazyGetUnitListWithSitePublicIdQuery();
  const [fetchBuildings] = useLazyGetBuildingsQuery();

  const initialValues = {
    buildingNumber: buildingOptions.length > 0 ? buildingOptions[0].key : '',
    unitNumber: '',
    unitName: '',
    unitStartingNumber: '',
    unitEndingNumber: ''
  };

  const unitNumberValidation = yup
    .string()
    .matches(/^\d+$/, 'Unit number can only contain numbers')
    .required('Unit number is required')
    .test('max', 'Unit number cannot exceed 10 numbers', (value) => value.length <= 10)
    .test('min', 'Unit number must be at least 3 numbers', (value) => value.length >= 3);

  const generateValidation = (isBatchAddUnit: boolean) => {
    if (isBatchAddUnit) {
      return yup.object().shape({
        buildingNumber: yup.string().required('Building number is required'),
        unitStartingNumber: unitNumberValidation
          .required('Starting unit number is required')
          .test('range-unique', 'One or more unit numbers in the range already exist', (value, context) => {
            const startNum = parseInt(value.toString(), 10);
            const endNum = parseInt(context.parent.unitEndingNumber, 10);
            const buildingNumber = context.parent.buildingNumber;
            const buildingUnits = Object.values(unitList).filter((unit) => unit.buildingPublicId === buildingNumber);
            const reservedUnitNumbers = buildingUnits.map((unit) => unit.unitNumber);
            for (let num = startNum; num <= endNum; num++) {
              if (reservedUnitNumbers.includes(num.toString())) {
                return false;
              }
            }
            return true;
          })
          .test('range', 'Starting unit number must be less than ending unit number', (value, context) => {
            const startNum = parseInt(value.toString(), 10);
            const endNum = parseInt(context.parent.unitEndingNumber, 10);
            return startNum < endNum;
          }),
        unitEndingNumber: unitNumberValidation.required('Ending unit number is required')
      });
    } else {
      return yup.object().shape({
        unitName: yup.string().required('Unit name is required'),
        buildingNumber: yup.string().required('Building number is required'),
        unitNumber: unitNumberValidation.test('unique', 'Unit number already exists', (value, context) => {
          const buildingNumber = context.parent.buildingNumber;
          const buildingUnits = Object.values(unitList).filter((unit) => unit.buildingPublicId === buildingNumber);
          const reservedUnitNumbers = buildingUnits.map((unit) => unit.unitNumber);
          return !reservedUnitNumbers.includes(value.toString());
        })
      });
    }
  };

  const validationSchema = generateValidation(isBatchAddUnit);

  const handleCloseDialog = () => {
    setIsOpen(false);
    resetValues();
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedUnit(Number(event.target.value));
  };

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async (values: any) => {
    if (isBatchAddUnit) {
      for (let i = Number(values.unitStartingNumber); i <= Number(values.unitEndingNumber); i++) {
        await createUnit({
          unitData: {
            unitNumber: i.toString(),
            unitName: `Unit ${i}`,
            unitType: selectedUnit,
            buildingPublicId: values.buildingNumber
          }
        })
          .unwrap()
          .then(() => {
            setIsSuccessAlertOpen(true);
            fetchUnits({
              sitePublicId: sitePublicId ?? '',
              qty: 500,
              page: 0
            });
            fetchBuildings(sitePublicId ?? '');
            handleCloseDialog();
          })
          .catch(() => {
            setErrorMessage('Failed to add unit');
            setIsErrorAlertOpen(true);
          });
      }
    } else {
      await createUnit({
        unitData: {
          unitNumber: values.unitNumber,
          unitName: values.unitName,
          unitType: selectedUnit,
          buildingPublicId: values.buildingNumber
        }
      })
        .unwrap()
        .then(() => {
          setIsSuccessAlertOpen(true);
          fetchUnits({
            sitePublicId: sitePublicId ?? '',
            qty: 500,
            page: 0
          });
          fetchBuildings(sitePublicId ?? '');
          handleCloseDialog();
        })
        .catch(() => {
          setErrorMessage('Failed to add unit');
          setIsErrorAlertOpen(true);
        });
      setIsOpen(false);
    }
  };

  const handleSwitchAddType = () => {
    setIsBatchAddUnit((prev) => !prev);
  };

  const resetValues = () => {
    setSelectedUnit(2);
    setCurrentStep(1);
    setIsBatchAddUnit(false);
  };

  interface IAddSingleUnit {
    errors: FormikErrors<any>;
    touched: FormikTouched<any>;
  }

  const AddSingleUnit = ({ errors, touched }: IAddSingleUnit) => {
    return (
      <Box
        sx={{
          float: 'right',
          width: '100%'
        }}
      >
        <Field
          as={TextField}
          label="Unit Number"
          name="unitNumber"
          variant="outlined"
          sx={styles.unitNameField}
          error={errors.unitNumber && touched.unitNumber}
          helperText={errors.unitNumber && touched.unitNumber ? errors.unitNumber : null}
        />
      </Box>
    );
  };

  const BatchAddUnits = ({ errors, touched }: IAddSingleUnit) => {
    return (
      <Box
        sx={{
          float: 'right',
          width: '100%'
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Field
              as={TextField}
              label="Starting Unit Number"
              name="unitStartingNumber"
              variant="outlined"
              sx={styles.rangeTextField}
              error={errors.unitStartingNumber && touched.unitStartingNumber}
              helperText={errors.unitStartingNumber && touched.unitStartingNumber ? errors.unitStartingNumber : null}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              as={TextField}
              label="Ending Unit Number"
              name="unitEndingNumber"
              variant="outlined"
              sx={styles.rangeTextField}
              error={errors.unitEndingNumber && touched.unitEndingNumber}
              helperText={errors.unitEndingNumber && touched.unitEndingNumber ? errors.unitEndingNumber : null}
            />
          </Grid>
        </Grid>
      </Box>
    );
  };

  const SelectUnitType = () => {
    return (
      <Box>
        <RadioGroup name="unitType" defaultValue={2} value={selectedUnit} onChange={handleRadioChange}>
          <Box sx={styles.unitCategory}>
            <Card>
              <Typography variant="h5" sx={{ padding: '15px' }}>
                Common Areas
              </Typography>
              <List>
                {[
                  {
                    name: 'Entrance',
                    type: 2,
                    description:
                      'Entrance units consist of up to ten IXG-DM7-* entrance stations. Entrance stations can call guard, commercial, residential, and inside area units, as well as grant entry to residents by keypad or card reader.'
                  },
                  {
                    name: 'Guard',
                    type: 1,
                    description:
                      'Guard units consist of up to eight IXG-MK guard stations, IX-RS-* handset sub stations, one VoIP extension, or a combination of these, along with up to eight IXG Apps and a telephone number. Guard stations provide advanced functions including internal paging, call transfer, speed dial buttons, and more. The two private door stations will only call to stations within their unit.'
                  },
                  {
                    name: 'Outside Area',
                    type: 6,
                    description:
                      'Outside area units consist of up to ten door or emergency stations that can call residential, guard, commercial, and inside area units.'
                  }
                ].map((unit, index) => (
                  <ListItem key={index}>
                    <Card sx={selectedUnit === unit.type ? styles.selectedUnitType : { width: '100%' }}>
                      <CardActionArea
                        sx={styles.unitOptionCard}
                        disableRipple
                        onClick={() => setSelectedUnit(unit.type)}
                      >
                        <UnitOption unit={unit} />
                        <Radio
                          checked={selectedUnit === unit.type}
                          value={unit.type}
                          color="primary"
                          style={{ padding: 0 }}
                          disableTouchRipple
                        />
                      </CardActionArea>
                    </Card>
                  </ListItem>
                ))}
              </List>
            </Card>
          </Box>
          <Box sx={styles.unitCategory}>
            <Card>
              <Typography variant="h5" sx={{ padding: '15px' }}>
                Private Areas
              </Typography>
              <List>
                {[
                  {
                    name: 'Residential',
                    type: 4,
                    description:
                      'Residential units may consist of up to eight IXG-2C7 tenant stations, IX-RS-* handset sub stations, or a combination of these, along with up to eight IXG mobie apps and a telephone number. Tenant stations can communicate internally within the unit, receive incoming calls from entrance and guard units, and monitor entrance stations. The two private door stations will only call to stations within their unit.'
                  },
                  {
                    name: 'Commercial',
                    type: 5,
                    description:
                      'Commercial Units consist of up to eight IX-MV7-* master stations, IX-SOFT PC master stations, IX-RS-* handset sub stations, one VoIP extension, or a combination of these, along with eight IXG mobile apps and a telephone number. Master stations and PC master stations provide advanced functions including internal paging, call transfer, speed dial buttons, and more.'
                  }
                ].map((unit, index) => (
                  <ListItem key={index}>
                    <Card sx={selectedUnit === unit.type ? styles.selectedUnitType : { width: '100%' }}>
                      <CardActionArea
                        sx={styles.unitOptionCard}
                        disableRipple
                        onClick={() => setSelectedUnit(unit.type)}
                      >
                        <UnitOption unit={unit} />
                        <Radio
                          checked={selectedUnit === unit.type}
                          value={unit.type}
                          color="primary"
                          style={{ padding: 0 }}
                          disableTouchRipple
                        />
                      </CardActionArea>
                    </Card>
                  </ListItem>
                ))}
              </List>
            </Card>
          </Box>
        </RadioGroup>
      </Box>
    );
  };

  const UnitDetails = ({ formikProps }: IUnitDetailsProps) => {
    const { handleChange, errors, touched } = formikProps;
    return (
      <>
        <Box sx={styles.settingsWrapper}>
          <Box sx={styles.descriptionWrapper}>
            <Box sx={styles.title}>Select Building</Box>
            <Box sx={styles.description}></Box>
          </Box>
          <Box sx={styles.selectWrapper}>
            <FormControl sx={styles.selectField}>
              <InputLabel id="building-number-label">Select Building Number</InputLabel>
              <Field as={Select} name="buildingNumber" label="Select Building Number" onChange={handleChange}>
                {buildingOptions.map((option) => (
                  <MenuItem key={option.key} value={option.key}>
                    {option.value}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
          </Box>
        </Box>
        <Box sx={styles.settingsWrapper}>
          <Box sx={styles.descriptionWrapper}>
            <Box sx={styles.title}>Unit Number</Box>
            <Box sx={styles.description}>{'Units numbers must be unique in a given building.'}</Box>
          </Box>
          <Box sx={styles.inputWrapper}>
            <Grid container>
              <Grid item xs={12}>
                {isBatchAddUnit ? (
                  <BatchAddUnits errors={errors} touched={touched} />
                ) : (
                  <AddSingleUnit errors={errors} touched={touched} />
                )}
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'end'
                  }}
                >
                  <Button onClick={handleSwitchAddType}>
                    {isBatchAddUnit ? 'Switch to single add' : 'Switch to batch add'}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box sx={styles.settingsWrapper}>
          <Box sx={styles.descriptionWrapper}>
            <Box sx={styles.title}>Unit Name</Box>
            <Box sx={styles.description}>
              {'The Unit name will appear in the IXG-DM7-* Entrance Panel directory (when applicable).'}
            </Box>
          </Box>
          <Box sx={styles.inputWrapper}>
            <Grid container>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  label="Unit Name"
                  name="unitName"
                  variant="outlined"
                  sx={styles.unitNameField}
                  disabled={isBatchAddUnit}
                  error={errors.unitName && touched.unitName}
                  helperText={errors.unitName && touched.unitName ? errors.unitName : null}
                />
              </Grid>
              <Grid item xs={12}>
                {isBatchAddUnit && (
                  <FormHelperText sx={{ float: 'right' }}>
                    Note: Unit name will be automatically generated based on the unit number
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </>
    );
  };

  const renderStepContent = (step: number, formikProps: FormikProps<any>) => {
    switch (step) {
      case 1:
        return <SelectUnitType />;
      case 2:
        return <UnitDetails formikProps={formikProps} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Dialog open={isOpen} onClose={handleCloseDialog} maxWidth="lg" fullWidth>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ ...props }) => (
            <Form>
              <DialogTitle>Add a Unit</DialogTitle>
              <DialogContent>{renderStepContent(currentStep, props)}</DialogContent>
              <DialogActions>
                {currentStep === 2 && (
                  <Button variant="contained" onClick={handlePreviousStep}>
                    Back
                  </Button>
                )}
                {currentStep === 1 && (
                  <Button variant="contained" onClick={handleNextStep}>
                    Next
                  </Button>
                )}
                {currentStep === 2 && (
                  <LoadingButton variant="contained" type="submit" loading={isLoading}>
                    Add Unit
                  </LoadingButton>
                )}
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
      <SnackbarAlert
        type="success"
        time={3000}
        text={successMessage}
        isOpen={isSuccessAlertOpen}
        onClose={() => setIsSuccessAlertOpen(false)}
      />
      <SnackbarAlert
        type="error"
        time={3000}
        text={errorMessage}
        isOpen={isErrorAlertOpen}
        onClose={() => setIsErrorAlertOpen(false)}
      />
    </>
  );
};

const styles = {
  unitDescriptionWrapper: {
    padding: '15px'
  },
  unitOptionCard: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 16px',
    border: '1px solid #e0e0e0',
    width: '100%'
  },
  settingsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px'
  },
  descriptionWrapper: {
    width: '50%'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  description: {},
  selectWrapper: {
    display: 'flex',
    justifyContent: 'end',
    width: '50%'
  },
  selectField: {
    width: '60%'
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'end',
    width: '50%'
  },
  textField: {
    width: '60%'
  },
  unitNameField: {
    width: '60%',
    float: 'right'
  },
  rangeTextField: {
    width: '100%'
  },
  unitCategory: {
    marginBottom: '20px'
  },
  selectedUnitType: {
    width: '100%',
    border: '1px solid #0071ce',
    backgroundColor: '#f0f8ff'
  }
};

export default AddUnitDialog;
