import { createSlice } from '@reduxjs/toolkit';
import { aiphoneCloudApi } from 'services/aiphoneCloud';

interface Company {
  name: string;
  state: string;
  publicId: string;
  postalCode: string;
}

interface Branch {
  publicId: string;
  address: string;
  address2: string;
  address3: string;
  city: string;
  branchName: string;
  c2CompanyId: string;
  aiphoneCloudAccountNumber: string;
  phoneNumber: string;
  state: string;
  stateId: number;
  postalCode: string;
  country: string;
  isDutyFree: boolean;
  sitePublicIds: string[];
  company: Company;
  createdBy: string;
  createdOn: string;
  lastUpdatedBy: string;
  lastUpdatedOn: string;
}

interface BranchState {
  Branches: string[];
  selectedBranch: Branch | null;
  currentBranch: Branch | null;
  Loading: boolean;
}

const initialState: BranchState = {
  Branches: [],
  selectedBranch: null,
  currentBranch: null,
  Loading: false
};

const branchSlice = createSlice({
  name: 'branches',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(aiphoneCloudApi.endpoints.getBranchWithPublicId.matchFulfilled, (state, action) => {
        state.currentBranch = action.payload;
        state.Loading = false;
      })
      .addMatcher(aiphoneCloudApi.endpoints.getUserWithPublicId.matchFulfilled, (state, action) => {
        if (action.payload.permissions.branch) {
          const branches = Object.keys(action.payload.permissions.branch);
          state.Branches = branches;
        }
      });
  }
});

export const branchReducer = branchSlice.reducer;
