import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

const useValidationSchemas = () => {
  const { t } = useTranslation(); // Initialize the translation hook

  // Login Validation Schema
  const loginValidation = yup.object().shape({
    email: yup.string().email(t('Validations.Enter_Valid_Email')).required(t('Validations.Email_Required')),
    password: yup.string().min(8, t('Validations.Password_Min_Length')).required(t('Validations.Password_Required'))
  });

  // Registration Validation Schema
  const registerValidation = yup.object().shape({
    firstName: yup.string().required(t('Validations.Required')),
    lastName: yup.string().required(t('Validations.Required')),
    email: yup
      .string()
      .email(t('Validations.Email_Not_Valid'))
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        t('Validations.Email_Valid')
      )
      .required(t('Validations.Required')),
    phoneNumber: yup
      .string()
      .matches(/((^\d{3})|(^\(\d{3}\))){1}((\d{7})|([\s.-]{1}\d{3}[\s.-]{1}\d{4}))$/, t('Validations.Phone_Valid'))
      .required(t('Validations.Required')),
    companyName: yup.string(),
    countryId: yup.number().required(t('Validations.Required')),
    address: yup.string().required(t('Validations.Required')),
    address2: yup.string(),
    city: yup.string().required(t('Validations.Required')),
    stateId: yup.number().required(t('Validations.Required')),
    zipCode: yup.string().required(t('Validations.Required')),
    password: yup.string().required(t('Validations.Required')),
    passwordConfirm: yup
      .string()
      .required(t('Validations.Required'))
      .oneOf([yup.ref('password')], t('Validations.Password_Match'))
  });

  // Confirm Email Validation Schema
  const confirmEmailValidation = yup.object().shape({
    verificationCode: yup.string().required(t('Validations.Required'))
  });

  // Create Password Validation Schema
  const createPWDValidation = yup.object().shape({
    password: yup.string().required(t('Validations.Required')),
    passwordConfirm: yup
      .string()
      .required(t('Validations.Required'))
      .oneOf([yup.ref('password')], t('Validations.Password_Match'))
  });

  // Reset Password Validation Schema
  const resetPWDValidation = yup.object().shape({
    email: yup.string().email(t('Validations.Enter_Valid_Email')).required(t('Validations.Email_Required'))
  });

  return {
    loginValidation,
    registerValidation,
    confirmEmailValidation,
    createPWDValidation,
    resetPWDValidation
  };
};

export default useValidationSchemas;
