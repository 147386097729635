import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { MAX_APPS } from 'features/RemoteManagement/Types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useBatchCreateAppsMutation, useLazyGetAppWithSiteIdQuery } from 'services/aiphoneCloud';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import { RootState } from 'store';
import { IUnitList } from 'store/slices/unitsSlice';
import { useTranslation } from 'react-i18next';

interface IAddAppToUnitDialogProps {
  selectedUnit: string | null;
  open: boolean;
  setIsOpen: (value: boolean) => void;
}

const AddAppToUnitDialog = ({ selectedUnit, open, setIsOpen }: IAddAppToUnitDialogProps) => {
  const [numberOfApps, setNumberOfApps] = React.useState(0);
  const [batchCreateApps, { isLoading }] = useBatchCreateAppsMutation();
  const [error, setError] = React.useState<string>('');
  const [success, setSuccess] = React.useState<string>('');
  const unitList = useSelector((state: RootState) => state.units.UnitList);
  const existingAppCount = unitList[selectedUnit ?? '']?.appPublicIds?.length || 0;
  const siteId = useParams().id ?? '';
  const [getAppList] = useLazyGetAppWithSiteIdQuery();

  const { t } = useTranslation();

  const handleClose = () => {
    setNumberOfApps(0);
    setIsOpen(false);
  };

  const handleAddApps = () => {
    if (!selectedUnit) {
      return;
    }

    const appList = generateAppList(selectedUnit, numberOfApps, unitList);

    const params = {
      apps: appList
    };

    batchCreateApps(params).unwrap().then(handleSuccess).catch(handleError);
  };

  const generateAppList = React.useCallback(
    (selectedUnit: string, numberOfApps: number, unitList: IUnitList) => {
      const appList = [];
      const unit = unitList[selectedUnit];
      const baseUnitNumber = unit.unitNumber;
      const unitHasApps = existingAppCount > 0;

      for (let i = 0; i < numberOfApps; i++) {
        const appIndex = unitHasApps ? existingAppCount + i : i;
        const newApp = {
          appData: {
            stationNumber: `${baseUnitNumber}${appIndex}`,
            stationName: `${t('Mobile_App')}${unitHasApps ? baseUnitNumber : ''}${appIndex}`,
            unitPublicId: selectedUnit
          }
        };

        appList.push(newApp);
      }

      return appList;
    },
    [t, existingAppCount]
  );

  const handleSuccess = React.useCallback(() => {
    setSuccess(t('Apps_added_successfully'));
    setIsOpen(false);
    setNumberOfApps(0);
    getAppList({
      sitePublicId: siteId || '',
      page: 0,
      qty: 500
    });
  }, [t]);

  const handleError = React.useCallback(
    (error: Error) => {
      console.log(error);
      setError(t('Failed_To_Add_Apps'));
      setIsOpen(false);
    },
    [t]
  );

  return (
    <>
      <Dialog maxWidth="lg" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>{t('Add_Mobile_Apps_to_Unit')}</DialogTitle>
        <DialogContent>
          <Box sx={styles.settingsWrapper}>
            <Box sx={styles.descriptionWrapper}>
              <Box sx={styles.title}>{t('Number_of_Apps')}</Box>
              <Box sx={styles.description}>
                {' '}
                {`${t('Enter_up_to_')}${MAX_APPS - existingAppCount}${t('Apps_to_add_to_this_unit')}`}
              </Box>
            </Box>
            <Box sx={styles.inputWrapper}>
              <TextField
                label={t('Number_of_Apps')}
                type="number"
                value={numberOfApps}
                onChange={(event) => {
                  setNumberOfApps(parseInt(event.target.value));
                  if (parseInt(event.target.value) > MAX_APPS - existingAppCount) {
                    setNumberOfApps(MAX_APPS - existingAppCount);
                  }
                }}
                fullWidth
                InputProps={{
                  inputProps: {
                    min: 0,
                    max: MAX_APPS - existingAppCount
                  }
                }}
                helperText={`
                ${t('This_unit_currently_has_')}${existingAppCount}${t('Apps_An_Additional')}${numberOfApps ?? 0} ${
                  numberOfApps === 1 ? 'app' : 'apps'
                }${t('Will_be_added')}`}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            {t('Cancel')}
          </Button>
          <LoadingButton variant="contained" onClick={handleAddApps} loading={isLoading}>
            {t('Add_Apps')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <SnackbarAlert
        type={'error'}
        time={5000}
        text={error}
        isOpen={!!error}
        onClose={() => {
          setError('');
        }}
      />
      <SnackbarAlert
        type={'success'}
        time={5000}
        text={success}
        isOpen={!!success}
        onClose={() => {
          setSuccess('');
        }}
      />
    </>
  );
};

const styles = {
  settingsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px'
  },
  descriptionWrapper: {
    width: '50%'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  description: {},
  inputWrapper: {
    display: 'flex',
    justifyContent: 'end',
    width: '50%'
  },
  textField: {
    width: '60%'
  }
};

export default AddAppToUnitDialog;
