import { Box, Card, CardActions, CardContent, Grid, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { getDeviceModelNumberFromModelType } from 'shared/utils/helperFunctions';
import { getUnitsById } from 'features/RemoteManagement/selectors/unit.selectors';
import { IUnit } from 'store/slices/unitsSlice';

interface IDeviceCard {
  devicePublicId: string;
  showRemoveIcon?: boolean;
  onRemove?: (devicePublicId: string) => void;
}

const DeviceCard = ({ devicePublicId, showRemoveIcon = false, onRemove }: IDeviceCard) => {
  const device = useSelector((state: RootState) => state.devices.DeviceList[devicePublicId]);

  if (!device) {
    return (
      <Typography gutterBottom variant="h6">
        Device Not Found
      </Typography>
    );
  }

  const imageUrl = `https://aiphone-images.s3.us-west-2.amazonaws.com/icons/${getDeviceModelNumberFromModelType(
    device.basicInfo.deviceModel,
    device.basicInfo.deviceType
  )}.png`;

  const unitPublicId = device?.unitPublicId || null;
  const unit: IUnit | null = getUnitsById(unitPublicId);

  return (
    <Card sx={styles.card}>
      <CardContent sx={styles.cardContent}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography gutterBottom variant="h5" component="div" sx={styles.deviceTitle}>
              {device.basicInfo?.stationName || 'No Name'}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <strong>Model Number: </strong>
              {getDeviceModelNumberFromModelType(device.basicInfo.deviceModel, device.basicInfo.deviceType)}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <strong>Unit Number: </strong>
              {unit?.unitNumber || 'N/A'}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <strong>Station Number: </strong>
              {device.basicInfo?.stationNumber || 'N/A'}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={styles.imageContainer}>
            <Box component="img" src={imageUrl} sx={styles.image} />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={styles.cardActions}>
        {showRemoveIcon && (
          <IconButton onClick={() => onRemove && onRemove(devicePublicId)} aria-label="remove">
            <DeleteIcon />
          </IconButton>
        )}
      </CardActions>
    </Card>
  );
};

const styles = {
  card: {
    width: '390',
    height: '200px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: '16px',
    boxSizing: 'border-box'
  },
  cardContent: {
    flexGrow: 1
  },
  deviceTitle: {
    fontWeight: 400
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    width: '80px',
    height: 'auto'
  },
  cardActions: {
    justifyContent: 'flex-end'
  }
};

export default DeviceCard;
