import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, TextField, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useUpdateUnitMutation } from 'services/aiphoneCloud';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import { RootState } from 'store';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const ZERO_PAD = false;

const UnitInfoCard = () => {
  const [error, setError] = React.useState<string | null>(null);
  const [success, setSuccess] = React.useState<string | null>(null);
  const [updateUnit] = useUpdateUnitMutation();
  const unitList = useSelector((state: RootState) => state.units.UnitList);
  const unitPublicId = useParams().unitid ?? '';
  const selectedUnit = unitList[unitPublicId];
  const { t } = useTranslation();

  const validationSchema = React.useMemo(
    () =>
      yup.object({
        unitNumber: yup
          .string()
          .matches(/^\d+$/, t('Unit_Number_must_be_only_digits'))
          .min(3, t('Unit_Number_must_be_at_least_3_digits'))
          .max(10, t('Unit_Number_must_be_at_most_10_digits'))
          .required(t('Unit_Number_is_required')),
        unitName: yup
          .string()
          .min(1, t('Unit_Name_must_be_at_least_1_character'))
          .max(24, t('Unit_Name_must_be_at_most_24_characters'))
          .matches(/^[a-zA-Z0-9\s"!$%&()\-./:;?@_+]*$/, t('Unit_Name_must_not_contain_invalid_characters'))
          .required(t('Unit_Name_is_required'))
      }),
    [t]
  );

  const saveUnitDetails = React.useCallback(
    (values: any, actions: any) => {
      const params = {
        unitData: {
          ...selectedUnit,
          unitNumber: values.unitNumber,
          unitName: values.unitName
        },
        unitPublicId: selectedUnit.publicId
      };

      updateUnit(params)
        .unwrap()
        .then(() => {
          setSuccess(t('Unit_Details_Saved_Successfully'));
          actions.setSubmitting(false);
        })
        .catch((_error) => {
          setError(t('Failed_to_save_unit_details'));
          actions.setSubmitting(false);
        });
    },
    [t, selectedUnit, updateUnit]
  );

  if (!selectedUnit) {
    return (
      <Card sx={styles.unitCard}>
        <Typography variant="sectionHeader">{t('Loading_Unit')}</Typography>
        <Box></Box>
      </Card>
    );
  }

  const initialValues = {
    unitNumber: selectedUnit?.unitNumber || '',
    unitName: selectedUnit?.unitName || ''
  };

  return (
    <>
      <Card sx={styles.unitCard}>
        <Typography variant="sectionHeader">
          {t('Unit')} {selectedUnit.unitNumber || ''}
        </Typography>
        <Box>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={saveUnitDetails}
          >
            {({ errors, touched, isSubmitting, isValid, setFieldValue }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Field name="unitNumber">
                      {({ field }: any) => (
                        <TextField
                          {...field}
                          label={t('Unit_Number')}
                          fullWidth
                          error={touched.unitNumber && Boolean(errors.unitNumber)}
                          helperText={touched.unitNumber && errors.unitNumber}
                          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 10 }}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            let unitNumber = event.target.value.replace(/\D/g, '');
                            if (unitNumber.length < 3 && ZERO_PAD) {
                              unitNumber = unitNumber.padStart(3, '0');
                            }
                            setFieldValue('unitNumber', unitNumber);
                          }}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field name="unitName">
                      {({ field }: any) => (
                        <TextField
                          {...field}
                          label={t('Unit_Name')}
                          fullWidth
                          inputProps={{ maxLength: 24 }}
                          error={touched.unitName && Boolean(errors.unitName)}
                          helperText={touched.unitName && errors.unitName}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      sx={styles.loadingButton}
                      loading={isSubmitting}
                      disabled={!isValid || isSubmitting}
                    >
                      {t('Save_Unit_Details')}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Card>
      <SnackbarAlert
        type={error ? 'error' : 'success'}
        time={6000}
        text={error ?? success ?? ''}
        isOpen={!!error || !!success}
        onClose={() => {
          setError(null);
          setSuccess(null);
        }}
      />
    </>
  );
};

const styles = {
  unitCard: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: '20px'
  },
  unitInputsWrapper: {
    display: 'flex',
    width: '100%',
    marginTop: '10px'
  },
  unitDetails: {
    marginTop: '20px'
  },
  loadingButton: {
    float: 'right'
  }
};

export default UnitInfoCard;
