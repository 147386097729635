import { Dialog, DialogTitle } from '@mui/material';
import { Formik } from 'formik';
import { useState } from 'react';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import AddTenantForm from './AddTenantForm';
import { useCreateTenantMutation, useLazyGetTenantListWithSiteIdQuery } from 'services/aiphoneCloud';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

interface IAddTenantDialogProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  unitPublicId?: string;
}

const AddTenantDialog = ({ isOpen, setIsOpen, unitPublicId }: IAddTenantDialogProps) => {
  const [successMessage, setSuccessMessage] = useState('Unit added successfully');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccessAlertOpen, setIsSuccessAlertOpen] = useState(false);
  const [isErrorAlertOpen, setIsErrorAlertOpen] = useState(false);
  const [createTenant] = useCreateTenantMutation();
  const [fetchTenants] = useLazyGetTenantListWithSiteIdQuery();
  const siteId = useParams().id;

  const tenantList = useSelector((state: RootState) => state.tenants.TenantList);

  const initialValues = {
    buildingNumber: '',
    unitNumber: unitPublicId ?? '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    primaryTenant: false
  };

  const validationSchema = yup.object().shape({
    email: yup.string().email('Invalid email')
  });

  const handleAddTenant = async (values: any) => {
    const isOnlyTenant =
      Object.values(tenantList).filter((tenant) => tenant.unitPublicId === values.unitNumber).length === 0;

    const params = {
      tenantData: {
        firstName: values.firstName,
        firstNameOtherLanguage: null,
        lastName: values.lastName,
        lastNameOtherLanguage: null,
        email: values.email,
        phone: values.phoneNumber,
        isPrimaryTenant: isOnlyTenant ? true : values.primaryTenant,
        languageId: 2,
        unitPublicId: values.unitNumber
      }
    };

    await createTenant(params)
      .unwrap()
      .then(() => {
        setSuccessMessage('Tenant added successfully');
        setIsSuccessAlertOpen(true);
        setIsOpen(false);
        fetchTenants({ sitePublicId: siteId, qty: 500, page: 0 });
      })
      .catch((error) => {
        setErrorMessage(error.data.message);
        setIsErrorAlertOpen(true);
      });
  };

  return (
    <>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} maxWidth="lg" fullWidth>
        <DialogTitle>Add Tenant</DialogTitle>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleAddTenant}>
          {({ ...props }) => <AddTenantForm formikProps={props} setIsOpen={setIsOpen} unitPublicId={unitPublicId} />}
        </Formik>
      </Dialog>
      <SnackbarAlert
        type="success"
        time={3000}
        text={successMessage}
        isOpen={isSuccessAlertOpen}
        onClose={() => setIsSuccessAlertOpen(false)}
      />
      <SnackbarAlert
        type="error"
        time={3000}
        text={errorMessage}
        isOpen={isErrorAlertOpen}
        onClose={() => setIsErrorAlertOpen(false)}
      />
    </>
  );
};

export default AddTenantDialog;
