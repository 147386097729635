import { FC } from 'react';
import { IVolumeProps } from '../../Volume';
import containerStyle from '../../../../../../shared/styles/advancedSettingContainerStyle';
import { Grid } from '@mui/material';
import { CustomSlider } from '../CustomSlider';
import { useField, useFormikContext } from 'formik';
import { t } from 'i18next';

export const VolumeIncomingPage: FC<IVolumeProps> = ({ defaultValue }) => {
  const volumeConfig = {
    volumeType: 'volumeIncomingPage',
    volumeTitle: 'AdvancedSettings_VolumeIncomingPage_Title',
    volumeDesc: 'AdvancedSettings_VolumeIncomingPage_Desc'
  };

  const { setFieldValue } = useFormikContext();

  const { volumeType, volumeTitle, volumeDesc } = volumeConfig;

  /*Static data*/
  const volumeIncomingPageTitle = t(volumeTitle);
  const volumeIncomingPageDesc = t(volumeDesc);

  /*Validation*/
  const [, volumeIncomingPageMeta] = useField(volumeType);

  /*Handlers*/
  const handleChange = async (value: number): Promise<void> => {
    await setFieldValue(volumeType, value);
  };

  /*If the device does not support this feature, return null*/
  if (defaultValue === undefined) {
    return null;
  }
  return (
    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
      <Grid item xs={6} lg={7}>
        <div style={containerStyle.itemTitle}>{volumeIncomingPageTitle}</div>
        <div style={containerStyle.itemDesc}>{volumeIncomingPageDesc}</div>
      </Grid>
      <Grid item xs={6} lg={4} justifyContent="flex-end">
        <CustomSlider
          label={volumeIncomingPageTitle}
          name={volumeType}
          min={1}
          max={10}
          step={1}
          onChange={handleChange}
          defaultValue={defaultValue}
          helperText={!!(volumeIncomingPageMeta.touched && volumeIncomingPageMeta.error)}
          styles={containerStyle.sliderBar}
        />
      </Grid>
    </Grid>
  );
};
