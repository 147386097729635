import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Box, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { confirmRegistration, resendEmailVerification } from '../../../shared/api/Aws/authApi';
import { AuthContext } from '../../../context/AuthContext';
import * as yup from 'yup';
import { isApiError } from '../../../shared/api/ApiError';
import { getCognitoErrorMessage } from '../utils/cognitoOperations';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next'; // Use translation hook
import useValidationSchemas from '../validationSchemas';

const validationSchema = yup.object().shape({
  verificationCode: yup.string().required('required')
});

type FormValues = yup.InferType<typeof validationSchema>;

const ConfirmAccount: React.FC = () => {
  const { t } = useTranslation(); // Initialize translation
  const navigate = useNavigate();
  const { username, setPassword } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [sendingCode, setSendingCode] = useState(false);

  // Translation strings
  const emailVerification = t('Title_EmailVerification');
  const emailVerificationMessage = t('EmailVerification_Body');
  const didNotReceiveCode = t('DidNot_Receive_Code');
  const resendButton = t('Button_Resend');
  const verifyAccount = t('Button_VerifyAccount');
  const { confirmEmailValidation } = useValidationSchemas();

  const handleAccountConfirmation = async (values: FormValues) => {
    try {
      const confirmEmailPayload = {
        userData: {
          email: username,
          verificationCode: values.verificationCode
        }
      };

      await confirmRegistration(confirmEmailPayload);

      setPassword('');
      navigate('/auth/profile-successfully-verified');
    } catch (error: unknown) {
      if (isApiError(error)) {
        const errorMessages = getCognitoErrorMessage(error.code);
        setErrorMessage(errorMessages);
        if ('UserNotConfirmedException' === error.code) {
          navigate('/auth/confirm-account');
        }
      } else {
        setErrorMessage(t('AccountConfirmationError'));
      }
    }
  };

  const handleResendVerificationCode = async () => {
    setSendingCode(true);
    try {
      const res = await resendEmailVerification({ userData: { email: username } });
      if (res && res.status === 200) {
        setSuccessMessage(t('VerificationCodeSent'));
      } else {
        setErrorMessage(t('ResendCodeError'));
      }
    } catch (error: unknown) {
      if (isApiError(error)) {
        const errorMessages = getCognitoErrorMessage(error.code);
        setErrorMessage(errorMessages);
      } else {
        setErrorMessage(t('ResendCodeError'));
      }
    } finally {
      setSendingCode(false);
    }
  };

  return (
    <>
      <Typography variant="h5" color="primary">
        {emailVerification}
      </Typography>
      <Typography variant="body1" color="primary">
        {emailVerificationMessage}
      </Typography>
      <Formik
        initialValues={{ verificationCode: '' }}
        validationSchema={confirmEmailValidation}
        onSubmit={handleAccountConfirmation}
      >
        {({ isSubmitting, touched, errors, handleChange, setFieldValue }) => (
          <Form>
            <Box sx={{ mt: 2, mb: 2 }}>
              <Field
                name="verificationCode"
                as={TextField}
                sx={styles.inputField}
                label={t('VerificationCode')} // Translated label
                onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(e);
                  await setFieldValue('verificationCode', e.target.value.trim());
                }}
                helperText={touched.verificationCode && errors.verificationCode}
                error={Boolean(touched.verificationCode && errors.verificationCode)}
              />
              <Typography variant="body1" color="primary">
                {didNotReceiveCode}{' '}
                <Button onClick={handleResendVerificationCode} sx={styles.resendButton} disabled={sendingCode}>
                  {resendButton}
                </Button>
              </Typography>
            </Box>
            {errorMessage && (
              <Grid item xs={12}>
                <Alert severity="error">{errorMessage}</Alert>
              </Grid>
            )}
            {successMessage && (
              <Grid item xs={12}>
                <Alert severity="success">{successMessage}</Alert>
              </Grid>
            )}
            <Box sx={{ mt: 2, mb: 2 }}>
              <LoadingButton
                type="submit"
                variant="aiphoneOrange"
                loading={isSubmitting || sendingCode}
                disabled={isSubmitting || sendingCode}
                sx={{ float: 'right' }}
                loadingIndicator={<CircularProgress size="20px" color="white" />}
              >
                {verifyAccount}
              </LoadingButton>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  inputField: {
    marginBottom: 1,
    width: '100%',
    '& .MuiInputBase-input': {
      backgroundColor: '#ffffff'
    },
    '.MuiFormHelperText-root': {
      color: 'red'
    }
  },
  resendButton: {
    color: '#0071CE',
    fontFamily: "'Roboto Condensed', sans-serif",
    fontWeight: 'bold'
  }
};

export default ConfirmAccount;
