import React, { useState } from 'react';
import { Button, Popover, createTheme, ThemeProvider, List, ListItem, ListItemText } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

//TODO: Do we want to move this to the theme file?
const theme = createTheme({
  palette: {
    primary: {
      main: '#0044CC',
      dark: '#003366'
    },
    tertiary: {
      main: '#cccccc' // tmp
    },
    neutral: {
      main: '#cccccc' // tmp
    },
    green: {
      main: '#cccccc' // tmp
    },
    red: {
      main: '#cccccc' // tmp
    }
  },
  typography: {
    button: {
      fontWeight: 600
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
          textTransform: 'none',
          boxShadow:
            '0px 3px 1px -2px rgba(0,0,0,0.1),0px 2px 2px 0px rgba(0,0,0,0.08),0px 1px 5px 0px rgba(0,0,0,0.06)',
          padding: '6px 16px'
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          maxWidth: '300px',
          border: '1px solid #0044CC',
          backgroundColor: '#e7f0f7',
          borderRadius: '4px',
          overflow: 'hidden'
        }
      }
    }
  }
});

interface ProductPopoverProps {
  description: string[];
}

const ProductPopover = ({ description }: ProductPopoverProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'product-popover' : undefined;

  return (
    <ThemeProvider theme={theme}>
      <Button
        aria-describedby={id}
        variant="contained"
        color="primary"
        onClick={handleClick}
        endIcon={<InfoOutlinedIcon />}
        sx={styles.button}
      >
        View Detail
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        sx={styles.popover}
      >
        <List>
          {description.map((desc: string, index: number) => (
            <ListItem key={index} sx={{ py: 0 }}>
              <ListItemText primary={'\u2022 ' + desc} />
            </ListItem>
          ))}
        </List>
      </Popover>
    </ThemeProvider>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  button: {
    marginTop: '5px',
    '&:hover': {
      backgroundColor: 'primary.dark'
    }
  },
  popover: {
    marginTop: '5px',
    '& .MuiTypography-root': {
      fontSize: '0.85rem',
      color: '#333333'
    }
  }
};

export default ProductPopover;
