import { FC } from 'react';
import containerStyle from '../../../../../../shared/styles/advancedSettingContainerStyle';
import { Grid } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { CustomSlider } from '../CustomSlider';
import { IVolumeProps } from '../../Volume';
import { t } from 'i18next';

const volumeConfig = {
  volumeType: 'volumeRingtone',
  volumeTitle: 'AdvancedSettings_RingtoneVolume_Title',
  volumeDesc: 'AdvancedSettings_RingtoneVolume_Desc'
};

export const VolumeRingtone: FC<IVolumeProps> = ({ defaultValue }) => {
  const { setFieldValue } = useFormikContext();
  const { volumeType, volumeTitle, volumeDesc } = volumeConfig;

  /*Static data*/
  const ringtoneVolumeTitle = t(volumeTitle);
  const ringtoneVolumeDesc = t(volumeDesc);

  /*Validation*/
  const [, ringtoneVolumeMeta] = useField(volumeType);

  /*Handlers*/
  const handleChange = async (value: number): Promise<void> => {
    await setFieldValue(volumeType, value);
  };

  /*If the device does not support this feature, return null*/
  if (defaultValue === undefined) {
    return null;
  }

  return (
    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
      <Grid item xs={6} lg={7}>
        <div style={containerStyle.itemTitle}>{ringtoneVolumeTitle}</div>
        <div style={containerStyle.itemDesc}>{ringtoneVolumeDesc}</div>
      </Grid>
      <Grid item xs={6} lg={4} justifyContent="flex-end">
        <CustomSlider
          label={ringtoneVolumeTitle}
          name={volumeType}
          min={0}
          max={10}
          step={1}
          onChange={handleChange}
          helperText={!!(ringtoneVolumeMeta.touched && ringtoneVolumeMeta.error)}
          defaultValue={defaultValue}
          styles={containerStyle.sliderBar}
        />
      </Grid>
    </Grid>
  );
};
