import React, { FC } from 'react';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedDevice, IDeviceDeviceSettings, setDeviceDeviceSettings } from 'store/slices/devicesSlice';
import { Form, Formik } from 'formik';
import { Box, Button } from '@mui/material';
import { getString } from 'shared/utils/LocalizationUtils';
import { useUpdateDeviceMutation } from 'services/aiphoneCloud';
import containerStyle from 'shared/styles/advancedSettingContainerStyle';
import {
  VolumeExternalMicrophone,
  VolumeExternalSpeaker,
  VolumeIncomingHandset,
  VolumeIncomingSpeaker,
  VolumeOperation,
  VolumeOutgoingHandset,
  VolumeOutgoingSpeaker
} from './Components';
import { RootState } from 'store';
import { useTranslation } from 'react-i18next';
import { VolumeSpeaker } from './Components/volume/VolumeSpeaker';
import { VolumeMonitoringMicrophone } from './Components/volume/VolumeMonitoringMicrophone';
import { VolumeIncomingPage } from './Components/volume/VolumeIncomingPage';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import { VolumeRingtone } from './Components/volume/VolumeRingtone';
import { Header } from './Components/volume/VolumeDeviceHeader';

export const VolumeLabel = () => {
  return <span>{getString('AdvancedSettings_Tab_Volume')}</span>;
};

interface CustomError {
  data: string;
}

interface IDeviceDeviceSettings {
  volumeIncomingHandset?: number;
  volumeExternalMicrophone?: number;
  volumeExternalSpeaker?: number;
  volumeIncomingSpeaker?: number;
  volumeNotification?: number;
  volumeRingtone?: number;
  volumeOperation?: boolean;
  volumeOutgoingHandset?: number;
  volumeOutgoingSpeaker?: number;
  volumeSpeaker?: number;
  volumeMonitoringMicrophone?: number;
  volumeIncomingPage?: number;
  stationNumber?: string; // Add this line
}
export interface IVolumeProps {
  defaultValue: number | boolean | undefined;
  isDevice2C7?: boolean;
}
type DeviceVolumeComponentType = {
  [key: number]: Array<{
    component: FC<IVolumeProps>;
    props: IVolumeProps;
  }>;
};
type DeviceComponent = DeviceVolumeComponentType[number][0];

const Volume: FC = () => {
  /*Services*/
  const { t } = useTranslation();
  const site = useSelector((state: RootState) => state.site); // Assuming site is part of the Redux state
  const devices = useSelector((state: RootState) => state.devices); // Assuming devices is part of the Redux state

  const validationSchema = yup.object().shape({
    volumeIncomingHandset: yup.number(),
    volumeExternalMicrophone: yup.number(),
    volumeExternalSpeaker: yup.number(),
    volumeIncomingSpeaker: yup.number(),
    volumeNotification: yup.number(),
    volumeRingtone: yup.number(),
    volumeOperation: yup.boolean(),
    volumeOutgoingHandset: yup.number(),
    volumeOutgoingSpeaker: yup.number(),
    volumeSpeaker: yup.number(),
    volumeMonitoringMicrophone: yup.number(),
    volumeIncomingPage: yup.number()
  });

  /*Local State*/
  const dispatch = useDispatch();
  const [successMessage, setSuccessMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  /*Global State*/
  const [updateDevice, { isLoading: isUpdating }] = useUpdateDeviceMutation();
  const { publicId, unitPublicId, deviceSettings } = useSelector(getSelectedDevice);
  const selectedDeviceType: number = useSelector((state: RootState) => state.devices.DeviceList[publicId]).basicInfo
    .deviceType;

  /*Variables*/
  const errorUpdateDevice = t('AdvancedSettings_Error_UpdateDevice');
  const successUpdateDevice = t('AdvancedSettings_Success_UpdateDevice');
  const unauthorizedUser = t('AdvancedSettings_Unauthorized_User');
  const isDevice2C7: boolean = selectedDeviceType === 14;

  if (!deviceSettings) return null;

  /*
    We don’t show all available volume settings for the device.
    In fact, the device has more volume-related keys than what we show here.
    Below, you can see the complete list of all volume settings for each device type.

  IXDV | device type 8  |  door station
    DS_SND_SOUND_VOLUME | VolumeMonitoringMicrophone : 10
    DS_RCV_SOUND_VOLUME | VolumeSpeaker : 6
    DS_CALL_SOUND_VOLUME | VolumeNotification : 6
    DS_CALL_SOUND_PAGING_VOLUME | VolumeIncomingPage : 6

  IXG-2C7 | device type 14 |  master station
    DS_HS_SND_SOUND_VOLUME |. VolumeOutgoingHandset : 10
    DS_HS_RCV_SOUND_VOLUME |. VolumeIncomingHandset : 6
    DS_HF_SND_SOUND_VOLUME | VolumeOutgoingSpeaker : 10
    DS_HF_RCV_SOUND_VOLUME | VolumeIncomingSpeaker : 6
    DS_PINJACK_SND_SOUND_VOLUME | VolumeExternalMicrophone : 10
    DS_PINJACK_RCV_SOUND_VOLUME | VolumeExternalSpeaker : 6
    DS_CALL_SOUND_VOLUME | VolumeNotification : 6

  IXG-DM7 | device type 15 |  door station
    DS_SND_SOUND_VOLUME | VolumeMonitoringMicrophone : 10
    DS_RCV_SOUND_VOLUME | VolumeSpeaker : 6
    DS_CALL_SOUND_VOLUME | VolumeRingtone : 6
    DS_CALL_SOUND_PAGING_VOLUME | VolumeIncomingPage : 6

  IXG-MK | device type 16 | master station
    DS_HS_SND_SOUND_VOLUME |. VolumeOutgoingHandset : 10
    DS_HS_RCV_SOUND_VOLUME |. VolumeIncomingHandset : 6
    DS_HF_SND_SOUND_VOLUME | VolumeOutgoingSpeaker : 10
    DS_HF_RCV_SOUND_VOLUME | VolumeIncomingSpeaker : 6
    DS_PINJACK_SND_SOUND_VOLUME | VolumeExternalMicrophone : 10
    DS_PINJACK_RCV_SOUND_VOLUME | VolumeExternalSpeaker : 6
    DS_CALL_SOUND_VOLUME | VolumeRingtone : 6

  IXDVM  | device type 20  |  door station
    DS_SND_SOUND_VOLUME | VolumeMonitoringMicrophone : 10
    DS_RCV_SOUND_VOLUME | VolumeSpeaker : 6
    DS_CALL_SOUND_VOLUME | VolumeNotification : 6

  IXEA  |  device type 11 |  door station
    DS_SND_SOUND_VOLUME | VolumeMonitoringMicrophone : 10
    DS_RCV_SOUND_VOLUME | VolumeSpeaker : 6
    DS_CALL_SOUND_VOLUME | VolumeNotification : 6

  IXRS | device type 5 |  master station
    DS_HS_SND_SOUND_VOLUME | VolumeOutgoingHandset : 10
    DS_HS_RCV_SOUND_VOLUME | VolumeIncomingHandset : 6
    DS_HF_SND_SOUND_VOLUME | VolumeOutgoingSpeaker : 10
    DS_HF_RCV_SOUND_VOLUME | VolumeIncomingSpeaker : 10
    DS_EXTERNAL_OUTPUT_SOUND_VOLUME | VolumeExternalSpeaker : 6
    DS_CALL_SOUND_VOLUME | VolumeRingtone : 6

  IXSS2G | device type 10 |  door station
    DS_SND_SOUND_VOLUME | VolumeMonitoringMicrophone : 10
    DS_RCV_SOUND_VOLUME | VolumeSpeaker : 6
    DS_CALL_SOUND_VOLUME | VolumeNotification : 6

  IXSS | device type 9 | door station
    DS_SND_SOUND_VOLUME | VolumeMonitoringMicrophone : 10
    DS_RCV_SOUND_VOLUME | VolumeSpeaker : 6
    DS_CALL_SOUND_VOLUME | VolumeNotification : 6
    DS_CALL_SOUND_PAGING_VOLUME | VolumeIncomingPage : 6


  IX-MV7  | device type  4 | master station
    DS_HS_SND_SOUND_VOLUME |. VolumeOutgoingHandset : 10
    DS_HS_RCV_SOUND_VOLUME |. VolumeIncomingHandset : 6
    DS_HF_SND_SOUND_VOLUME | VolumeOutgoingSpeaker : 10
    DS_HF_RCV_SOUND_VOLUME | VolumeIncomingSpeaker : 6
    DS_PINJACK_SND_SOUND_VOLUME | VolumeExternalMicrophone : 10
    DS_PINJACK_RCV_SOUND_VOLUME | VolumeExternalSpeaker : 6
    DS_CALL_SOUND_VOLUME | VolumeRingtone : 6


  Here is a list of all device types that can have volume settings,
  along with the list of settings for each specific device.
  */
  const DEVICE_VOLUME_COMPONENTS: DeviceVolumeComponentType = {
    /* IX-DV |device type 8 |  door station */
    8: [
      { component: VolumeMonitoringMicrophone, props: { defaultValue: deviceSettings.volumeMonitoringMicrophone } },
      { component: VolumeSpeaker, props: { defaultValue: deviceSettings.volumeSpeaker } },
      { component: VolumeIncomingPage, props: { defaultValue: deviceSettings.volumeIncomingPage } },
      { component: VolumeOperation, props: { defaultValue: deviceSettings.volumeOperation } }
    ],

    /* IXG-2C7 | device type 14 |  master station */
    14: [
      { component: VolumeOutgoingSpeaker, props: { defaultValue: deviceSettings.volumeOutgoingSpeaker, isDevice2C7 } },
      { component: VolumeIncomingSpeaker, props: { defaultValue: deviceSettings.volumeIncomingSpeaker, isDevice2C7 } },
      { component: VolumeRingtone, props: { defaultValue: deviceSettings.volumeRingtone } },
      { component: VolumeOperation, props: { defaultValue: deviceSettings.volumeOperation } }
    ],

    /* IXG-DM7 | device type 15 |  door station */
    15: [
      { component: VolumeMonitoringMicrophone, props: { defaultValue: deviceSettings.volumeMonitoringMicrophone } },
      { component: VolumeSpeaker, props: { defaultValue: deviceSettings.volumeSpeaker } },
      { component: VolumeIncomingPage, props: { defaultValue: deviceSettings.volumeIncomingPage } },
      { component: VolumeOperation, props: { defaultValue: deviceSettings.volumeOperation } }
    ],

    /* IXG-MK | device type 16 | master station */
    16: [
      { component: VolumeOutgoingHandset, props: { defaultValue: deviceSettings.volumeOutgoingHandset } },
      { component: VolumeIncomingHandset, props: { defaultValue: deviceSettings.volumeIncomingHandset } },
      { component: VolumeOutgoingSpeaker, props: { defaultValue: deviceSettings.volumeOutgoingSpeaker } },
      { component: VolumeIncomingSpeaker, props: { defaultValue: deviceSettings.volumeIncomingSpeaker } },
      { component: VolumeExternalMicrophone, props: { defaultValue: deviceSettings.volumeExternalMicrophone } },
      { component: VolumeExternalSpeaker, props: { defaultValue: deviceSettings.volumeExternalSpeaker } },
      { component: VolumeRingtone, props: { defaultValue: deviceSettings.volumeRingtone } },
      { component: VolumeOperation, props: { defaultValue: deviceSettings.volumeOperation } }
    ],

    /* IX-DVM  | device type 20  |  door station */
    20: [
      { component: VolumeMonitoringMicrophone, props: { defaultValue: deviceSettings.volumeMonitoringMicrophone } },
      { component: VolumeSpeaker, props: { defaultValue: deviceSettings.volumeSpeaker } },
      { component: VolumeOperation, props: { defaultValue: deviceSettings.volumeOperation } }
    ],

    /* IX-EA  |  device type 11 |  door station */
    11: [
      { component: VolumeMonitoringMicrophone, props: { defaultValue: deviceSettings.volumeMonitoringMicrophone } },
      { component: VolumeSpeaker, props: { defaultValue: deviceSettings.volumeSpeaker } },
      { component: VolumeOperation, props: { defaultValue: deviceSettings.volumeOperation } }
    ],

    /* IX-RS | device type 5 |  master station */
    5: [
      { component: VolumeOutgoingHandset, props: { defaultValue: deviceSettings.volumeOutgoingHandset } },
      { component: VolumeIncomingHandset, props: { defaultValue: deviceSettings.volumeIncomingHandset } },
      { component: VolumeOutgoingSpeaker, props: { defaultValue: deviceSettings.volumeOutgoingSpeaker } },
      { component: VolumeIncomingSpeaker, props: { defaultValue: deviceSettings.volumeIncomingSpeaker } },
      { component: VolumeExternalSpeaker, props: { defaultValue: deviceSettings.volumeExternalSpeaker } },
      { component: VolumeRingtone, props: { defaultValue: deviceSettings.volumeRingtone } },
      { component: VolumeOperation, props: { defaultValue: deviceSettings.volumeOperation } }
    ],

    /* IX-SS2G | device type 10 |  door station */
    10: [
      { component: VolumeMonitoringMicrophone, props: { defaultValue: deviceSettings.volumeMonitoringMicrophone } },
      { component: VolumeSpeaker, props: { defaultValue: deviceSettings.volumeSpeaker } },
      { component: VolumeOperation, props: { defaultValue: deviceSettings.volumeOperation } }
    ],

    /* IX-SS | device type 9 | door station */
    9: [
      { component: VolumeMonitoringMicrophone, props: { defaultValue: deviceSettings.volumeMonitoringMicrophone } },
      { component: VolumeSpeaker, props: { defaultValue: deviceSettings.volumeSpeaker } },
      { component: VolumeIncomingPage, props: { defaultValue: deviceSettings.volumeIncomingPage } },
      { component: VolumeOperation, props: { defaultValue: deviceSettings.volumeOperation } }
    ],

    /* IX-MV7  | device type  4 | master station */
    4: [
      { component: VolumeOutgoingHandset, props: { defaultValue: deviceSettings.volumeOutgoingHandset } },
      { component: VolumeIncomingHandset, props: { defaultValue: deviceSettings.volumeIncomingHandset } },
      { component: VolumeOutgoingSpeaker, props: { defaultValue: deviceSettings.volumeOutgoingSpeaker } },
      { component: VolumeIncomingSpeaker, props: { defaultValue: deviceSettings.volumeIncomingSpeaker } },
      { component: VolumeExternalMicrophone, props: { defaultValue: deviceSettings.volumeExternalSpeaker } },
      { component: VolumeExternalSpeaker, props: { defaultValue: deviceSettings.volumeExternalSpeaker } },
      { component: VolumeRingtone, props: { defaultValue: deviceSettings.volumeRingtone } },
      { component: VolumeOperation, props: { defaultValue: deviceSettings.volumeOperation } }
    ]
  };

  /* Helpers */
  const isCustomError = (error: unknown): error is CustomError => {
    return typeof error === 'object' && error !== null && 'data' in error;
  };
  const renderVolumeComponents = () => {
    const deviceVolumeComponents = DEVICE_VOLUME_COMPONENTS[selectedDeviceType];
    return deviceVolumeComponents.map((component: DeviceComponent, index: number) => {
      return <component.component key={index} {...component.props} />;
    });
  };

  /* Handlers */
  const onSubmit = async (values: IDeviceDeviceSettings) => {
    const params = {
      device: {
        deviceSettings: values,
        publicId,
        unitPublicId
      }
    };

    try {
      await updateDevice(params).unwrap();
      setSuccessMessage(successUpdateDevice);
      setIsSuccess(true);
      dispatch(setDeviceDeviceSettings(params));
    } catch (error: unknown) {
      if (isCustomError(error)) {
        try {
          const err = JSON.parse(error.data);

          if (err.errorDetails && err.errorDetails.includes('Unauthorized user Id')) {
            setErrorMessage(unauthorizedUser);
          } else {
            setErrorMessage(errorUpdateDevice);
          }
          setIsError(true);
        } catch (parseError) {
          setErrorMessage('Failed to parse error response.');
          setIsError(true);
        }
      } else {
        setErrorMessage('An unexpected error occurred.');
        setIsError(true);
      }
    }
  };

  return (
    <>
      <SnackbarAlert
        type="success"
        text={successMessage}
        time={3000}
        isOpen={isSuccess}
        onClose={() => setIsSuccess(false)}
      />
      <SnackbarAlert type="error" text={errorMessage} time={3000} isOpen={isError} onClose={() => setIsError(false)} />
      <Formik initialValues={deviceSettings} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
        {({ dirty, isSubmitting }) => {
          return (
            <Form style={containerStyle.form}>
              <Box sx={styles.buttonContainer}>
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  disabled={!dirty || isSubmitting || isUpdating}
                  style={containerStyle.submitBarButton}
                >
                  {t('Button_SaveChanges')}
                </Button>
              </Box>

              <div style={containerStyle.gridContainer}>
                <Header
                  siteName={site.siteInfo.siteName ?? ''}
                  awsPropertyId={Number(site.siteInfo.awsPropertyId)}
                  selectedDevice={devices.SelectedDevice ?? ''}
                />
                {renderVolumeComponents()}
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const styles = {
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '20px'
  }
};

export default Volume;
